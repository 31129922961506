import {MarkdownModule} from 'ngx-markdown';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DocsOverviewComponent} from './docs-overview.component';
import {SharedModule} from "../../shared/shared.module";
import {DocsOverviewRoutingModule} from "./docs-overview-routing.module";

@NgModule({
  declarations: [DocsOverviewComponent],
  imports: [
    CommonModule,
    SharedModule,
    DocsOverviewRoutingModule,
    MarkdownModule.forRoot()
  ]
})
export class DocsOverviewModule {}
