import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PasswordresetComponent} from "./passwordreset.component";

const routes: Routes = [
  {
    path: '',
    component: PasswordresetComponent,
    data: {
      title: 'Passwort zuruecksetzen',
      icon: 'icon-layout-sidebar-left',
      caption: 'Passwortreset',
      status: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PasswordresetRoutingModule { }
