import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-docs-overview',
  templateUrl: './docs-overview.component.html',
  styleUrls: ['./docs-overview.component.scss']
})
export class DocsOverviewComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
