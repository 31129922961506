import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {ReactiveFormsModule} from "@angular/forms";
import {RequestpasswordresetComponent} from "./requestpasswordreset.component";
import {RequestpasswordresetRoutingModule} from "./requestpasswordreset-routing.module";

@NgModule({
  declarations: [RequestpasswordresetComponent],
    imports: [
        CommonModule,
        SharedModule,
        RequestpasswordresetRoutingModule,
        ReactiveFormsModule

    ]
})
export class RequestpasswordresetModule {
}
