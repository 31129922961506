import {Component, ContentChild, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {EditModeDirective} from './edit-mode.directive';
import {ViewModeDirective} from './view-mode.directive';
import {fromEvent, Subject} from 'rxjs';
import {filter, switchMapTo, take, takeUntil} from 'rxjs/operators';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {AuthenticationService} from "../../../sccshared/authentication/authentication.service";

@Component({
  selector: 'app-editable-input',
  templateUrl: './editable-input.component.html',
  styleUrls: ['./editable-input.component.scss']
})
export class EditableInputComponent implements OnInit, OnDestroy {

  @Output() update = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective;

  editMode = new Subject();
  stopSignal$ = new Subject();
  editMode$ = this.editMode.asObservable();

  mode: 'view' | 'edit' = 'view';

  constructor(private host: ElementRef, private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.viewModeHandler();
    this.editModeHandler();
  }

  get currentView() {
    if (this.isEditor()) {
      return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
    } else {
      return this.viewModeTpl.tpl;
    }
  }

  private get element() {
    return this.host.nativeElement;
  }

  toViewMode() {
    this.update.next();
    this.mode = 'view';
  }

  toViewModeCanceled() {
    this.stopSignal$.next();
    this.cancel.next();
    this.mode = 'view';
  }
  private viewModeHandler() {
    fromEvent(this.element, 'dblclick').pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      this.mode = 'edit';
      this.editMode.next(true);
    });
  }

  private editModeHandler() {
    const clickOutside$ = fromEvent(document, 'click').pipe(
      filter(({target}) => this.element.contains(target) === false),
      take(1), takeUntil(this.stopSignal$)
    );

    this.editMode$.pipe(
      switchMapTo(clickOutside$),
      untilDestroyed(this)
    ).subscribe(event => this.toViewMode());
  }

  ngOnDestroy() {
  }

  isEditor(): boolean {
    let result = false;
    if (this.authService.hasUserRole('ROLE_EDITOR')) {
      result = true;
    }
    if (this.authService.hasUserRole('ROLE_ADMIN')) {
      result = true;
    }
    return result;
  }
}
