<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <img src="assets/images/scc/logo.png" alt="" class="img-fluid">
        <h1 class="text-white my-4">Willkommen!</h1>
        <h4 class="text-white font-weight-normal">Bitte melden Sie sich mit Ihren Benutzerdaten an.<br/>Viel Spaß bei
          der Recherche.</h4>
      </div>
    </div>
    <div class="auth-side-form">
      <form class="md-float-material" name="form" (keyup.enter)="f.form.valid && login()"
            (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
        <div class=" auth-content">
          <img src="assets/images/auth/auth-logo-dark.png" alt="" class="img-fluid mb-4 d-block d-xl-none d-lg-none">
          <h3 class="mb-4 f-w-400">Anmeldung</h3>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="feather icon-mail"></i></span>
            </div>
            <input type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel"
                   required placeholder="E-Mail-Adresse oder Username"/>

          </div>
          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="feather icon-lock"></i></span>
            </div>

            <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel"
                   required placeholder="Passwort"/>
          </div>
          <div class="form-group text-left mt-2">
            <div class="checkbox checkbox-primary d-inline">
              <input type="checkbox" name="checkbox-p-1" id="checkbox-p-1" checked="">
              <label for="checkbox-p-1" class="cr">Zugangsdaten merken</label>
            </div>
          </div>
          <button class="btn btn-block btn-primary mb-0">Anmelden</button>
          <div class="text-center">
            <div class="saprator my-4"><span>ODER</span></div>
<!--            <button class="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle"><i-->
<!--              class="fab fa-facebook-f"></i></button>-->
<!--            <button class="btn text-white bg-googleplus mb-2 mr-2 wid-40 px-0 hei-40 rounded-circle"><i-->
<!--              class="fab fa-google-plus-g"></i></button>-->
<!--            <button class="btn text-white bg-twitter mb-2  wid-40 px-0 hei-40 rounded-circle"><i-->
<!--              class="fab fa-twitter"></i></button>-->
            <p class="mb-2 text-muted">Passwort vergessen? <a [routerLink]="['/requestpasswordreset']"
                                                              class="f-w-400">Passwort zurücksetzen</a></p>
<!--            <p class="mb-0 text-muted">Noch kein Benutzer? <a [routerLink]="['/signup']" class="f-w-400">Anmeldung-->
<!--              hier</a></p>-->
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<!--<section class="login p-fixed d-flex text-center bg-primary common-img-bg">-->
<!--  &lt;!&ndash; Container-fluid starts &ndash;&gt;-->
<!--  <div class="container">-->
<!--    <div class="row">-->
<!--      <div class="col-sm-12">-->
<!--        <div class="alert alert-danger background-danger" *ngIf="isError">-->
<!--          <strong>Fehler: </strong>Anmeldedaten falsch-->
<!--        </div>-->
<!--        &lt;!&ndash; Authentication card start &ndash;&gt;-->
<!--        <div class="login-card card-block auth-body mr-auto ml-auto">-->
<!--          <form class="md-float-material" name="form" (keyup.enter)="f.form.valid && login()"-->
<!--                (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>-->
<!--            <div class="text-center">-->
<!--              &lt;!&ndash;              <img src="assets/images/logo.png" alt="Gradient Able">&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="auth-box">-->
<!--              <div class="row m-b-20">-->
<!--                <div class="col-md-12">-->
<!--                  <h3 class="text-left txt-primary">Anmeldung SCC Online</h3>-->
<!--                </div>-->
<!--              </div>-->
<!--              <hr/>-->
<!--              <div class="input-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">-->
<!--                <input type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel"-->
<!--                       required/>-->
<!--                <div *ngIf="f.submitted && !username.valid">Username is required</div>-->
<!--                <span class="md-line"></span>-->
<!--              </div>-->
<!--              <div class="input-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">-->
<!--                <input type="password" class="form-control" name="password" [(ngModel)]="model.password"-->
<!--                       #password="ngModel" required/>-->
<!--                <div *ngIf="f.submitted && !password.valid">Password is required</div>-->
<!--                <span class="md-line"></span>-->
<!--              </div>-->
<!--              &lt;!&ndash;              <div class="row m-t-25 text-left">&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="col-12">&ndash;&gt;-->
<!--              &lt;!&ndash;                  <div class="checkbox-fade fade-in-primary d-">&ndash;&gt;-->
<!--              &lt;!&ndash;                    <label>&ndash;&gt;-->
<!--              &lt;!&ndash;                      <input type="checkbox" value="">&ndash;&gt;-->
<!--              &lt;!&ndash;                      <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>&ndash;&gt;-->
<!--              &lt;!&ndash;                      <span class="text-inverse">Remember me</span>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </label>&ndash;&gt;-->
<!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                  <div class="forgot-phone text-right f-right">&ndash;&gt;-->
<!--              &lt;!&ndash;                    <a [routerLink]="['/auth/forgot']" class="text-right f-w-600 text-inverse"> Forgot Password?</a>&ndash;&gt;-->
<!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              <div class="row m-t-30">-->
<!--                <div class="col-md-12">-->
<!--                  <button [disabled]="loading" (click)="login()" type="button"-->
<!--                          class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20">Anmelden-->
<!--                  </button>-->
<!--                  <br/>-->
<!--                  <a routerLink="/requestpasswordreset">Passwort vergessen? Hier klicken</a>-->
<!--                </div>-->
<!--              </div>-->
<!--              <hr/>-->
<!--              <div class="row">-->
<!--                <div class="col-md-10">-->
<!--                  <p class="text-inverse text-left m-b-0">Und immer dran denken: SCC-Online lebt vom Mitmachen.</p>-->
<!--                  <p class="text-inverse text-left"><b>Die Couleuriker</b></p>-->
<!--                </div>-->
<!--                <div class="col-md-2">-->
<!--                  <img src="assets/images/auth/Logo-small-bottom.png" alt="small-logo.png">-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->
<!--          </form>-->
<!--          &lt;!&ndash; end of form &ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash; Authentication card end &ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash; end of col-sm-12 &ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash; end of row &ndash;&gt;-->
<!--  </div>-->
<!--  &lt;!&ndash; end of container-fluid &ndash;&gt;-->
<!--</section>-->
