<div class="page-body">
  <div class="row">
    <div class="col-sm-12">

      <app-card [title]="'Datenbanksuche'">
        <div class="row">
          <div class="col-xl-3 col-md-6 mb-4">
            <div class="switch switch-primary d-inline m-r-10">
              <span style="vertical-align: center">Alle bereits markierten rausfiltern </span>
              <input type="checkbox" name="switchStatusBundEmpty" id="switch-p-1" (click)="swapSwitchBundEmpty()" [ngModel]="switchStatusBundEmpty">
              <label for="switch-p-1" class="cr"></label>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 mb-4">
            <div class="switch switch-primary d-inline m-r-10">
              <span style="vertical-align: center">Verbände rausfiltern </span>
              <input type="checkbox" name="switchStatusVerband" id="switch-p-2" (click)="swapSwitchVerband()" [ngModel]="switchStatusVerband">
              <label for="switch-p-2" class="cr"></label>
            </div>

          </div>
        </div>
        <!--        <p>Bitte entsprechende Filter setzen und dann über Enter suchen</p>-->
        <!--        <form (submit)="search()" method="post">-->
        <!--          <div class="row seacrh-header">-->
        <!--            <div class="col-sm-6">-->
        <!--              <span class="m-b-20">Benutze die Suche & Filter zur Ergebniseingrenzung</span><br>-->
        <!--            </div>-->
        <!--            <div class="col-lg-8 offset-lg-4 offset-sm-12 col-sm-6 offset-sm-1 col-xs-12">-->
        <!--              <div class="input-group input-group-button input-group-primary">-->
        <!--                <label for="verbindungsname">Verbindungsname</label>-->
        <!--                <input type="text" class="form-control" placeholder="Suchen..."-->
        <!--                       [formControl]="suchwort" id="verbindungsname" name="suchwort">-->
        <!--                <label for="farben">Farben</label>-->
        <!--                <input type="text" class="form-control" placeholder="Suchen..."-->
        <!--                       [formControl]="farben" id="farben" name="farben">-->
        <!--                <label for="ORT">Ort</label>-->
        <!--                <input type="text" class="form-control" placeholder="Suchen..."-->
        <!--                       [formControl]="ort" id="ort" name="ort">-->

        <!--                <button class="btn btn-primary input-group-addon" id="basic-addon1">Search</button>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="row">-->
        <!--            <div class="col-sm-12">-->
        <!--              <label for="sccIdSuche">SCCID</label>-->
        <!--              <input type="text" class="form-control" placeholder="00000"-->
        <!--                     [formControl]="sccid" id="sccIdSuche" name="sccid">-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </form>-->


        <table datatable class="table table-striped table-bordered nowrap table-hover" [dtOptions]="dtOptions">
          <thead>
          <tr>
            <th>DatenbankID</th>
            <th>Name d. Verbindung</th>
            <th>SCC-ID</th>
            <th>Seiten im SCC-PDF</th>
            <th>Quelldatei</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let metaDatensatz of metadataliste">
            <td>{{ metaDatensatz.id }}</td>
            <td>
                <span>{{ metaDatensatz.bundName }}</span>
                <span class="badge badge-warning">{{ metaDatensatz.hasNoMatch ? 'k. Zuordnung' : '' }}</span>
                <!--                <span class="badge badge-warning">{{ aktWappen.hasNoMatch?'k. Zuordnung':'' }}</span>-->
            </td>
            <td><a [routerLink]="['/search-page']" [queryParams]="{sccid: sanitizedSccId(metaDatensatz.sccid)}">{{ metaDatensatz.sccid }}</a></td>
            <td>{{ metaDatensatz.pages }}</td>
            <td>{{ metaDatensatz.sourceFileName }}</td>
            <td>

              <button class="btn btn-info" (click)="openModal(metaDatensatz)">Zuordnen</button>
              <!--              <button class="btn btn-info" (click)="markNotFound(aktWappen)">Bund-Findex leer</button>-->
              <!--              <button class="btn btn-warning" (click)="markVerband(aktWappen)">IST VERBAND</button>-->
            </td>
          </tr>
          </tbody>
        </table>

      </app-card>
    </div>
  </div>

  <app-ui-modal #bundmetadatamodal>
    <div class="app-modal-header">
      <h4 class="modal-title">Zuordnungsfenster</h4>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
              (click)="bundmetadatamodal.hide()">
      </button>
      <span aria-hidden="true"
            *ngIf="mdbBund !== null && mdbBund !== undefined">{{mdbBund.sccid}} {{mdbBund.bundName}}</span>
      <div>
        <button type="button" class="btn btn-warning" ngbTooltip="btn-warning" (click)="setMetaDataHasNoMatch()">
          Findex-Name nicht passend
        </button>
        |
        <button type="button" class="btn btn-info" ngbTooltip="btn-warning" (click)="setMetaDataBundEmpty()">Bund-Findex
          leer
        </button>
      </div>
    </div>
    <div class="app-modal-body">
      <h5>Meta von </h5>
      <perfect-scrollbar class="overflow-container" [style.width]="'100%'" [style.height]="'300px'"
                         [config]="config">
        <table class="table">
          <tr *ngFor="let bundEntry of relevantBundListe">
            <td
              [ngClass]="{'table-back-red': bundEntry.bund.aktiv === false || bundEntry.bund.aktiv === null}">
              <a class="cursor-pointer"
                 (click)="updateMetaDataBundLink(bundEntry.bund)">{{bundEntry.bund.sccId}}</a>
            </td>
            <td>{{bundEntry.bund.verbindungsname}} ({{bundEntry.bund.ort}}) {{bundEntry.bund.gruendungsDatum}}
              <span *ngIf="bundEntry.count"><span class="badge badge-info">{{bundEntry.count}}</span>
                </span>
              <!--            <td>{{bundEntry.verbindungsname}} ({{bundEntry.ort}}) ({{checkBundAttachedToMetaDataBund(bundEntry.verbindungId)}}<br/>-->
            </td>
            <td></td>
          </tr>
        </table>
      </perfect-scrollbar>
    </div>
    <div class="app-modal-footer">
      <span class="badge badge-danger" *ngIf="totalModalDatasets !== null && totalModalDatasets > 100">Nicht alle Sätze angezeigt</span>
      <span class="badge badge-success"
            *ngIf="totalModalDatasets !== null && totalModalDatasets < 100">Ingesamt {{totalModalDatasets}}
        Datensätze</span>

      <button type="button" class="btn btn-default waves-effect"
              (click)="bundmetadatamodal.hide()">Close
      </button>
    </div>
  </app-ui-modal>

</div>
