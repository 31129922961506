<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <!--      <app-card [title]="'Datenbanksuche'">-->
      <!--        <p>Bitte entsprechende Filter setzen und dann über "Eingabe" suchen</p>-->
      <!--        <form (submit)="search()" method="post">-->
      <!--          <div class="row seacrh-header">-->
      <!--            <div class="col-sm-6">-->
      <!--              <span class="m-b-20">Benutze die Suche & Filter zur Ergebniseingrenzung</span><br>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="row seacrh-header">-->
      <!--            <div class="col-lg-12 col-sm-12 col-xs-12">-->
      <!--              <div class="input-group input-group-button input-group-primary">-->
      <!--                <div class="col-sm-3 m-b-5">-->
      <!--                  <input type="text" class="form-control" placeholder="Verbindungsname"-->
      <!--                         [formControl]="suchwort" id="verbindungsname" name="suchwort">-->
      <!--                </div>-->
      <!--                <div class="col-sm-3 m-b-5">-->
      <!--                  <input type="text" class="form-control" placeholder="Farben"-->
      <!--                         [formControl]="farben" id="farben" name="farben">-->
      <!--                </div>-->
      <!--                <div class="col-sm-3 m-b-5">-->
      <!--                  <input type="text" class="form-control" placeholder="Ort"-->
      <!--                         [formControl]="ort" id="ort" name="ort">-->
      <!--                </div>-->
      <!--                <div class="col-sm-3 m-b-5">-->
      <!--                  <input type="text" class="form-control" placeholder="Gründungsjahr"-->
      <!--                         [formControl]="gdjahr" id="gdjahr" name="gdjahr">-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="col-lg-12 col-sm-12 col-xs-12">-->
      <!--              <div class="input-group input-group-button input-group-primary">-->
      <!--                <div class="col-sm-3 m-b-5">-->
      <!--                  <input type="text" class="form-control" placeholder="SCC-ID im Format (00000)"-->
      <!--                         [formControl]="sccid" id="sccIdSuche" name="sccid">-->
      <!--                </div>-->
      <!--                <div class="col-lg-9 col-sm-9">-->
      <!--                  <button class="btn btn-primary input-group-addon" id="basic-addon1">Suchen-->
      <!--                  </button>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </form>-->
      <!--      </app-card>-->
      <app-card [cardTitle]="'Zirkelbuchstabe'">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <span *ngFor="let buchstabe of getAlphabet()"><button (click)="filterListe(buchstabe)"
                                                                  type="button"
                                                                  class="btn btn-info">&nbsp;{{buchstabe}}&nbsp;</button>&nbsp;</span>
          </div>
        </div>
      </app-card>
      <app-card [cardTitle]="'Suchergebnis'" [cardCaption]="'Bitte auf ein Vorschaubild klicken, um die Gesamtliste der Verbindungen mit diesem Zirkel zu sehen! (Es wird nur EIN Zufallsbild hier in der Ansicht ausgegeben)'">

        <table datatable class="table-xl table-striped table-bordered table-hover"
               [dtOptions]="dtOptions">
          <thead>
          <tr>
            <th>DatenbankID</th>
            <th>Zirkeltext</th>
            <th>SCC-ID</th>
            <th>Farben</th>
            <th>Zirkelgrafik</th>
            <th>Vorschaubild</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let curZirkel of zirkelliste">
            <tr *ngIf="zirkelChange(curZirkel)">
              <td>{{curZirkel.id}}</td>
              <td>{{curZirkel.textzirkel}}</td>
              <td><a [routerLink]="['/search-page']"
                     [queryParams]="{sccid: curZirkel.sccIdText}">{{curZirkel.sccIdText}}</a></td>
              <td>{{curZirkel.zirkelFarben}}</td>
              <td>{{curZirkel.remarks}}</td>
              <td (click)="zirkelTypeClick(curZirkel.id)" class="hovered-link"><img [src]="getBinaryDataForZirkel(curZirkel.contentId)" width="180px"></td>
              <td>&nbsp;</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </app-card>
    </div>
  </div>
</div>
