import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BundContentOprhanPipe, StartseiteComponent} from './startseite.component';
import {StartseiteRoutingModule} from './startseite-routing.module';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    StartseiteRoutingModule,
    SharedModule,
  ],
  declarations: [StartseiteComponent, BundContentOprhanPipe]
})
export class StartseiteModule { }
