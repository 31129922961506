<div class="pcoded-inner-content">
  <div class="main-body">
    <div class="page-wrapper">


      <div class="card">
        <div *ngIf="aktiverBund">
          <ngb-tabset>
            <ngb-tab title="HOME">
              <ng-template ngbTabContent>
                <div class="table-responsive">
                  <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                      <th colspan="2">
                        <div style="display: flex; justify-content: flex-end">
                          Datensatzinformation
                          <div style="margin-left:auto; margin-right:0;" *ngIf="isEditor()">
                            <h4>
                              <!--                              <i class="feather icon-edit" (click)="updateData()"></i>-->
                              <i class="feather icon-link" (click)="linkBundContent()"></i> <i style="margin-left: 20px;" class="fas fa-database" (click)="showEntryHistory()"></i></h4>
                          </div>
                        </div>
                        <div style="margin-top: -10px;">
                          <span class="badge badge-success" *ngIf="aktiverBund.aktiv === true">Aktiver Bund</span>
                          <span class="badge badge-danger"
                                *ngIf="aktiverBund.aktiv !== true">Bund nicht mehr aktiv</span>

                        </div>
                      </th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th class="text-nowrap" scope="row">Verbindungsname</th>
                      <td>{{aktiverBund.verbindungsname | uppercase}}</td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Ort</th>
                      <td>{{aktiverBund.ort}}</td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Land</th>
                      <td>{{aktiverBund.land}}</td>
                    </tr>

                    <tr>
                      <th class="text-nowrap" scope="row">SCC-ID</th>
                      <td><a [routerLink]="['/search-page']"
                             [queryParams]="{sccid: aktiverBund.sccId}">{{aktiverBund.sccId}}</a></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Gründungsdatum</th>
                      <td>
                        <app-editable-input (update)="updateField( 'gruendungsDatum')"
                                            (cancel)="cancelField('gruendungsDatum')">
                          <ng-template appViewMode>
                            <span
                              *ngIf="(aktiverBund.gruendungsDatum === undefined || aktiverBund.gruendungsDatum === null || aktiverBund.gruendungsDatum === ''); else hasContentBlock">
                              <span class="greyed-out"><i>aktuell nicht gesetzt</i></span>
                            </span>
                            <ng-template #hasContentBlock>{{aktiverBund.gruendungsDatum}}</ng-template>
                          </ng-template>
                          <ng-template appEditMode>
                            <input [formControl]="getControl( 'gruendungsDatum')" focusable editableOnEnter>
                          </ng-template>
                        </app-editable-input>
                      </td>

                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Farben Burschenband/Bund</th>
                      <td>
                        <app-editable-input (update)="updateField( 'farben')" (cancel)="cancelField('farben')">
                          <ng-template appViewMode>
                            <span
                              *ngIf="(aktiverBund.farben === undefined || aktiverBund.farben === null || aktiverBund.farben === ''); else hasContentBlock">
                              <span class="greyed-out"><i>aktuell nicht gesetzt</i></span>
                            </span>
                            <ng-template #hasContentBlock><span [innerHTML]="getFarben(aktiverBund.farben)"></span>
                            </ng-template>
                          </ng-template>
                          <ng-template appEditMode>
                            <input [formControl]="getControl( 'farben')" focusable editableOnEnter>
                          </ng-template>
                        </app-editable-input>
                      </td>
                      <!--                      <td </td>-->
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Fuxenband</th>
                      <td>
                        <app-editable-input (update)="updateField( 'fuxenband')" (cancel)="cancelField('fuxenband')">
                          <ng-template appViewMode>
                            <span *ngIf="hasContent('fuxenband'); else hasContentBlock">
                              <span class="greyed-out"><i>aktuell nicht gesetzt</i></span>
                            </span>
                            <ng-template #hasContentBlock><span [innerHTML]="getFarben(aktiverBund.fuxenband)"></span></ng-template>
                          </ng-template>
                          <ng-template appEditMode>
                            <input (keydown.esc)="unfocusField('fuxenband')" [formControl]="getControl( 'fuxenband')"
                                   focusable editableOnEnter>
                          </ng-template>
                        </app-editable-input>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Mützenformat/Mützenabbild</th>
                      <td>-- in Arbeit --</td>
                    </tr>

                    <tr>
                      <th class="text-nowrap" scope="row">Internetseite Bund</th>
                      <td>
                        <app-editable-input (update)="updateField( 'homepage')" (cancel)="cancelField('homepage')">
                          <ng-template appViewMode>
                            <span *ngIf="hasContent('homepage'); else hasContentBlock">
                              <span class="greyed-out"><i>aktuell nicht gesetzt</i></span>
                            </span>
                            <ng-template #hasContentBlock><span>{{aktiverBund.homepage}}</span></ng-template>
                          </ng-template>
                          <ng-template appEditMode>
                            <input class="scc-input-field" [formControl]="getControl( 'homepage')" focusable
                                   editableOnEnter>
                          </ng-template>
                        </app-editable-input>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Telefonnummer</th>
                      <td>
                        <app-editable-input (update)="updateField( 'telefonNummer')"
                                            (cancel)="cancelField('telefonNummer')">
                          <ng-template appViewMode>
                            <span *ngIf="hasContent('telefonNummer'); else hasContentBlock">
                              <span class="greyed-out"><i>aktuell nicht gesetzt</i></span>
                            </span>
                            <ng-template #hasContentBlock><span>{{aktiverBund.telefonNummer}}</span></ng-template>
                          </ng-template>
                          <ng-template appEditMode>
                            <input class="scc-input-field" (keydown.esc)="unfocusField('telefonNummer')"
                                   [formControl]="getControl( 'telefonNummer')" focusable editableOnEnter>
                          </ng-template>
                        </app-editable-input>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Adresse</th>
                      <td>
                        <app-editable-input (update)="updateField( 'adresseHaus')"
                                            (cancel)="cancelField('adresseHaus')">
                          <ng-template appViewMode>
                            <span *ngIf="hasContent('adresseHaus'); else hasContentBlock">
                              <span class="greyed-out"><i>aktuell nicht gesetzt</i></span>
                            </span>
                            <ng-template #hasContentBlock><span>{{aktiverBund.adresseHaus}}</span></ng-template>
                          </ng-template>
                          <ng-template appEditMode>
                            <input class="scc-input-field" (keydown.esc)="unfocusField('adresseHaus')"
                                   [formControl]="getControl( 'adresseHaus')" focusable editableOnEnter>
                          </ng-template>
                        </app-editable-input>
                        <br/>
                        <app-editable-input (update)="updateField( 'plzHaus')" (cancel)="cancelField('plzHaus')">
                          <ng-template appViewMode>
                            <span *ngIf="hasContent('plzHaus'); else hasContentBlock">
                              <span class="greyed-out scc-view-spaced"><i>aktuell nicht gesetzt</i></span>
                            </span>
                            <ng-template #hasContentBlock><span class="scc-view-spaced">{{aktiverBund.plzHaus}}</span>
                            </ng-template>
                          </ng-template>
                          <ng-template appEditMode>
                            <input class="scc-input-field-sm" (keydown.esc)="unfocusField('plzHaus')"
                                   [formControl]="getControl( 'plzHaus')" focusable editableOnEnter>
                          </ng-template>
                        </app-editable-input>
                        <app-editable-input (update)="updateField( 'ortHaus')" (cancel)="cancelField('ortHaus')">
                          <ng-template appViewMode>
                            <span *ngIf="hasContent('ortHaus'); else hasContentBlock">
                              <span class="greyed-out"><i>aktuell nicht gesetzt</i></span>
                            </span>
                            <ng-template #hasContentBlock><span>{{aktiverBund.ortHaus}}</span></ng-template>
                          </ng-template>
                          <ng-template appEditMode *ngIf="isEditor()">
                            <input *ngIf="isEditor()" class="scc-input-field" (keydown.esc)="unfocusField('ortHaus')"
                                   [formControl]="getControl( 'ortHaus')" focusable editableOnEnter>
                          </ng-template>
                        </app-editable-input>
                      </td>
                    </tr>

                    <tr>
                      <th class="text-nowrap" scope="row">Art d. Verbindung</th>
                      <td>{{aktiverBund.verbindungsArt}}</td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Verbandszugehörigkeit(en)</th>
                      <td>
                        <b>{{aktiverBund.verband.verbandsname}}</b><br/>
                        <span><ul>
                        <li *ngFor="let verbandsEintrag of verbandsteile;">
                          {{verbandsEintrag.verbandsname}}
                        </li>
                        </ul></span></td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Wahlspruch</th>
                      <td>{{aktiverBund.wahlspruch}}</td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Ist aktiv?</th>
                      <td class="" *ngIf="aktiverBund.aktiv === true">Bund ist aktiv
                      </td>
                      <td *ngIf="aktiverBund.aktiv !== true">Bund nicht mehr aktiv
                      </td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">Datum typ</th>
                      <td>{{aktiverBund.datumTyp}}</td>
                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">fusionierte zu...</th>
                      <td *ngIf="aktiverBund.fusioniertZu">{{aktiverBund.fusioniertZu}} (<a
                        *ngIf="aktiverBund.aktiverBundHeute != null"
                        [routerLink]="['/bunddetail/',aktiverBund.aktiverBundHeute.id]">{{aktiverBund.aktiverBundHeute.verbindungsname}}</a>)
                      </td>
                      <td *ngIf="aktiverBund.fusioniertZu == null">./.</td>
                    </tr>
                    <!--              <tr>-->
                    <!--                <th class="text-nowrap" scope="row">Hat Wappen (Import)</th>-->
                    <!--                <td>{{aktiverBund.wappen}}</td>-->
                    <!--              </tr>-->
                    <!--              <tr>-->
                    <!--                <th class="text-nowrap" scope="row">Hat Zirkel (Import)</th>-->
                    <!--                <td>{{aktiverBund.zirkel}}</td>-->
                    <!--              </tr>-->
                    <tr>
                      <th class="text-nowrap" scope="row">Gespeicherte Zirkel</th>
                      <td>
                        <ul>
                          <li *ngFor="let meinZirkel of einzelZirkel;">
                            <b>{{meinZirkel.contentId}}</b><br/>
                            <img [src]="getBinaryDataForZirkel(meinZirkel.contentId)" width="180px">
                          </li>
                          <!--                <span *ngIf="einzelZirkel != null">-->
                          <!--                  <img [src]="getBinaryDataForZirkel()">-->
                          <!--                </span>-->
                        </ul>
                      </td>

                    </tr>
                    <tr>
                      <th class="text-nowrap" scope="row">SCC-ID-Verwandtschaft</th>
                      <td>
                        <ul>

                          <ul>
                            <ng-container *ngFor="let iterbund of alleSCCIDBuende;">
                              <li *ngIf="iterbund.id !== aktiverBund.id">
                                <a [routerLink]="['/bunddetail', iterbund.id]">
                                  {{iterbund.verbindungsname}} ({{iterbund.farben}})
                                </a>
                              </li>
                            </ng-container>
                          </ul>

                        </ul>
                      </td>

                    </tr>
                    <!--                    <tr *ngIf="!isEmpty(allMedia)">-->
                    <!--                      <th class="text-nowrap" scope="row">Gespeicherte Wappen & Scans</th>-->
                    <!--                      <td>-->
                    <!--                        <ul>-->
                    <!--                          <li *ngFor="let mediaElement of allMedia;"><b>{{mediaElement.bundName}}</b><br/>-->
                    <!--                            <img *ngIf="mediaElement.mimeType.startsWith('image')"-->
                    <!--                                 [src]="getBinaryMediaDataForElement(mediaElement.contentId)"-->
                    <!--                                 width="180px">-->
                    <!--                          </li>-->
                    <!--                          &lt;!&ndash;                <span *ngIf="einzelZirkel != null">&ndash;&gt;-->
                    <!--                          &lt;!&ndash;                  <img [src]="getBinaryDataForZirkel()">&ndash;&gt;-->
                    <!--                          &lt;!&ndash;                </span>&ndash;&gt;-->
                    <!--                        </ul>-->
                    <!--                      </td>-->
                    <!--                    </tr>-->
                    <tr>
                      <th class="text-nowrap" scope="row">Wappenkartei</th>
                      <ul>
                        <li *ngFor="let wappenText of aktiverBund.wappen">
                          {{wappenText.sccId}} - {{wappenText.ungeteilt}} - {{wappenText.linksUnten}}
                        </li>
                      </ul>
                    </tr>

                    <tr>
                      <th class="text-nowrap" scope="row">Verknüpfte Wappen</th>
                      <td>Anzahl: <br/>

                        <div *ngIf="wappenListe != undefined" class="table-responsive">
                          <table class="table table-styling">
                            <thead>
                            <tr class="table-primary">
                              <th>#</th>
                              <th>Name Bund</th>
                              <th>Farben</th>
                              <th>Helmzier</th>
                              <th>links oben</th>
                              <th>rechts oben</th>
                              <th>links unten</th>
                              <th>rechts unten</th>
                              <th>Herzschild</th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr *ngFor="let wappenDetail of wappenListe; let idx = index">
                              <th scope="row">{{ idx }}</th>
                              <td>{{ wappenDetail.bundImport }}</td>
                              <td>{{ wappenDetail.farben}}</td>
                              <td>{{ wappenDetail.helmzier }}</td>
                              <td>{{ wappenDetail.linksOben }}</td>
                              <td>{{ wappenDetail.rechtsOben }}</td>
                              <td>{{ wappenDetail.linksUnten }}</td>
                              <td>{{ wappenDetail.rechtsUnten }}</td>
                              <td>{{ wappenDetail.herzschild }}</td>
                            </tr>

                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th class="text-nowrap" scope="row">Word-Quelldatei <a *ngIf="isEditor()"
                                                                             href="javascript:void(0);"
                                                                             (click)="linkBundMetaData()"><i
                        class="feather icon-arrow-right"></i></a></th>
                      <td>
                        <a
                          href="{{getLinkForWord(aktiverBund.id)}}">{{getLinkForWord(
                          aktiverBund.id)}}</a>
                      </td>
                    </tr>
                    <tr *ngIf="bundContent != null && bundContent != undefined">

                      <th class="text-nowrap" scope="row">Beschreibungstext Stammblatt <a
                        (click)="viewAllContentForSCCID()"><i class="feather icon-edit-2"></i> ({{bundContentCount}}
                        )</a>
                      </th>
                      <td>
                        <div *ngFor="let bundText of bundContent; let idx = index">
                          <h5>{{bundText.bundName}}</h5>
                          <ng-container *ngIf="editModeIdxField === -1 || editModeIdxField !== idx">
                            <button class="badge badge-success" (click)="editModeDetail(idx, bundText)">Bearbeiten
                            </button>
                            <hr/>
                            <code [innerHTML]="convertToHTML(bundText.textInhalt)"></code>

                            <!--                          <pell-editor (input)="checkInput($event)" (onChange)="checkInput($event)">-->

                            <!--                          </pell-editor>-->
                          </ng-container>
                          <ng-container *ngIf="editModeIdxField > -1 && editModeIdxField === idx">
                            <button class="badge badge-success" (click)="updateOrCancelEditModeDetail(false)">
                              Speichern
                            </button> &nbsp;
                            <button class="badge badge-warning" (click)="updateOrCancelEditModeDetail(true)">Abbruch
                            </button>
                            <!--                                     [data]="inputField"-->
                            <form (ngSubmit)="onBeschreibungSubmit()" [formGroup]="beschreibungForm">
                            <ckeditor [config]="{ toolbar: [  'bold', 'italic','bulletedList', 'numberedList', 'blockQuote' ,'' ] }"
                                      [editor]="Editor"

                                      class="editor-height"
                                      formControlName="description"
                                      (change)="onChangeContent($event)">

                            </ckeditor>
                            </form>
                          </ng-container>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <!--          <div class="card-header"><h2>{{aktiverBund.verbindungsname | uppercase}} (SCC-ID {{aktiverBund.sccId}})</h2>-->
                <!--          </div>-->
                <!--          <div class="card-block">-->
                <!--            <div><label>DB-ID</label> {{aktiverBund.verbindungId}}</div>-->
                <!--            <div>-->
                <!--              <label>name:-->
                <!--                <input [(ngModel)]="aktiverBund.verbindungsname" placeholder="name">-->
                <!--              </label>-->
                <!--            </div>-->
                <!--          </div>-->

              </ng-template>
            </ngb-tab>
            <ngb-tab title="BILDER">
              <ng-template ngbTabContent>
                <div class="col-sm-12">
                  <app-card cardTitle="Zugehörige Bilder / Mediendaten" [options]="false" blockClass="gallery-masonry">
                    <div class="card-columns" *ngIf="allMedia !== null && allMedia.length > 0">
                      <div class="card" *ngFor="let mediaElement of allMedia">
<!--                        <app-gallery [albums]="[-->
<!--                        { src: getBinaryMediaDataForElement(mediaElement.contentId),-->
<!--                          caption: 'huhu',-->
<!--                          thumb: getBinaryMediaDataForElement(mediaElement.contentId)-->
<!--                        }]">-->
<!--                        </app-gallery>-->

                        <img class="card-img-top" *ngIf="mediaElement.mimeType.startsWith('image')"
                             [src]="getBinaryMediaDataForElement(mediaElement.contentId)"
                             alt="{{mediaElement.originalFilename}}" lightbox />
                        >
                        <div class="card-body" *ngIf="mediaElement.mimeType.startsWith('image')">
                          <h5 class="job-card-desc">Beschreibung</h5>
                          <p>{{mediaElement.mediaDescription}}</p><i *ngIf="isEditor()" class="feather icon-edit-2" (click)="formEditmode=!formEditmode"></i>
                          <input *ngIf="formEditmode" type="text" class="form-control" value="-- noch in Arbeit --"/>
                          <div class="job-meta-data mb-1"><i
                            class="fas fa-bezier-curve"></i>{{mediaElement.mediaElementType}}</div>
                          <div class="job-meta-data"><i class="far fa-calendar-alt"></i>{{mediaElement.creationDate | date:('dd.MM.yyyy HH:mm')}}</div>
                          <div *ngIf="isAdmin()" class="job-meta-data"><i (click)="deleteEntry(mediaElement)"
                                                                          class="far fa-trash-alt"></i></div>
                        </div>
                      </div>
                    </div>
                  </app-card>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h5>Upload-Zone</h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12 col-xl-12">
                        <app-card cardTitle="File Upload" [options]="false">
                          <form [formGroup]="imageUploadForm">
                            <file-upload accept="file_extension|audio/*|video/*|image/*|media_type"
                                         formControlName="files" (change)="changeEventOnFileUploadList()">
                              <!--                              <ng-template #placeholder let-isFileDragDropAvailable="isFileDragDropAvailable">-->
                              <!--                                <div class="icon">-->
                              <!--                                  <svg viewBox="0 0 96 96">-->
                              <!--                                    <g>-->
                              <!--                                      <path d="M62.8,68.1c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.6-1.5-0.6s-1.1,0.2-1.5,0.6-->
                              <!--                        c-0.4,0.4-0.6,0.9-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.6,1.5,0.6s1.1-0.2,1.5-0.6S62.8,68.7,62.8,68.1z M71.3,68.1-->
                              <!--                        c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.6C67.2,67,67,67.5,67,68.1c0,0.6,0.2,1.1,0.6,1.5-->
                              <!--                        s0.9,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6C71.1,69.2,71.3,68.7,71.3,68.1z M75.5,60.7v10.6c0,0.9-0.3,1.6-0.9,2.2-->
                              <!--                        c-0.6,0.6-1.4,0.9-2.2,0.9H23.7c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.4-0.9-2.2V60.7c0-0.9,0.3-1.6,0.9-2.2-->
                              <!--                        c0.6-0.6,1.4-0.9,2.2-0.9h14.1c0.5,1.2,1.2,2.2,2.3,3c1.1,0.8,2.3,1.2,3.7,1.2h8.5c1.3,0,2.6-0.4,3.7-1.2c1.1-0.8,1.9-1.8,2.3-3-->
                              <!--                        h14.1c0.9,0,1.6,0.3,2.2,0.9C75.2,59.1,75.5,59.8,75.5,60.7z M64.8,39.3c-0.4,0.9-1,1.3-2,1.3h-8.5v14.8c0,0.6-0.2,1.1-0.6,1.5-->
                              <!--                        c-0.4,0.4-0.9,0.6-1.5,0.6h-8.5c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5V40.6h-8.5c-0.9,0-1.6-0.4-2-1.3-->
                              <!--                        c-0.4-0.9-0.2-1.6,0.5-2.3l14.8-14.8c0.4-0.4,0.9-0.6,1.5-0.6s1.1,0.2,1.5,0.6L64.3,37C65,37.7,65.1,38.4,64.8,39.3z"/>-->
                              <!--                                    </g>-->
                              <!--                                  </svg>-->
                              <!--                                </div>-->

                              <!--                                <div class="upload-text">-->
                              <!--                                  <ng-container *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable">-->
                              <!--                                    <b>Drag and drop</b> files<br> or click here-->
                              <!--                                  </ng-container>-->
                              <!--                                  <ng-template #isNotDragDropAvailable>-->
                              <!--                                    <b>Click here</b> to<br> choose a files-->
                              <!--                                  </ng-template>-->
                              <!--                                </div>-->
                              <!--                              </ng-template>-->


                              <!--                              <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>-->
                              <!--                                <span *ngIf="isFileDragDropAvailable">drop or click</span>-->
                              <!--                                <span *ngIf="!isFileDragDropAvailable">click</span>-->
                              <!--                              </ng-template>-->

                              <ng-template let-i="index" let-file="file" let-control="control" #item
                                           formArrayName="fileComments">
                                <div class="container" [formGroupName]="i">
                                  <div class="row justify-content-md-center">
                                    <div class="col col-sm-4">
                                      {{ file.name }} <i class="feather icon-trash-2"
                                                         (click)="removeFileFromList(file); control.removeFile(file)"></i>
                                    </div>
                                    <div class="col col-sm-4">
                                      <input formControlName="filecomment" type="text"
                                             class="form-control form-control-default" rows="2"
                                             placeholder="Bitte Bildkommentar eintragen">
                                    </div>
                                    <div class="col col-sm-4">
                                      <select formControlName="fileType" name="select"
                                              class="form-control form-control-default">
                                        <option value="{{mediaSelection}}"
                                                *ngFor="let mediaSelection of this.mediatypes">{{mediaSelection}}</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </file-upload>
                          </form>
                          <div class="text-center m-t-20">
                            <div class="spinner-border text-success" role="status" *ngIf="pendingNetwork">
                              <span class="sr-only">Loading...</span>
                            </div>
                            <button class="btn btn-primary" (click)="uploadFiles()" *ngIf="!pendingNetwork">Jetzt
                              hochladen
                            </button>
                          </div>
                        </app-card>
                        <app-card cardTitle="Linkübernahme" [options]="false">

                          <form [formGroup]="urlLinkForm" (ngSubmit)="onSubmitURLLink()">
                            <div class="col col-sm-4">
                            <span>
                            Bitte hier einen direkten Link auf ein Bild oder ein Video angeben. Dieses wird im Hintergrund geholt und in die Datenbank integriert.<br/>
                              </span>
                              <label>Webadresse</label><input formControlName="linkAdresse" type="text"
                                                              class="form-control form-control-default"
                                                              [ngClass]="{ 'is-invalid': urlLinkSubmitted && linkForm.linkAdresse.errors }"><br/>
                              <label>Beschreibung</label><input formControlName="linkKommentar" type="text"
                                                                class="form-control form-control-default"
                                                                [ngClass]="{ 'is-invalid': urlLinkSubmitted && linkForm.linkKommentar.errors}"><br/>
                            </div>
                            <div class="spinner-border text-success" role="status" *ngIf="pendingNetwork">
                              <span class="sr-only">Loading...</span>
                            </div>
                            <button class="btn btn-primary" *ngIf="!pendingNetwork">Jetzt laden</button>
                          </form>
                        </app-card>
                        <div class="inline-block">
                          <!--                <div-->
                          <!--                  ngfDrop-->
                          <!--                  [(validDrag)]="baseDropValid"-->
                          <!--                  (fileOver)="hasBaseDropZoneOver=$event"-->
                          <!--                  [(files)]="files"-->
                          <!--                  [accept]="accept"-->
                          <!--                  [maxSize]="maxSize"-->
                          <!--                  [(dragFiles)]="dragFiles"-->
                          <!--                  [(lastInvalids)]="lastInvalids"-->
                          <!--                  class="well my-drop-zone"-->
                          <!--                  [class.invalid-drag]="baseDropValid===false"-->
                          <!--                  [class.valid-drag]="baseDropValid"-->
                          <!--                  (filesChange)="lastFileAt=getDate()"-->
                          <!--                  [fileDropDisabled]="fileDropDisabled"-->
                          <!--                >-->
                          <!--                  Base drop zone-->
                          <!--                </div>-->
                          <!--                <strong>accept:</strong>-->
                          <!--                <div>-->
                          <!--                  <input type="text" [(ngModel)]="accept"/>-->
                          <!--                </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-template>
            </ngb-tab>
            <ngb-tab title="ZIRKEL">
              <ng-template ngbTabContent>
                <div class="col-sm-12">
                  <app-card cardTitle="Verknüpfte Zirkel aus Zirkelfindex" [options]="false"
                            blockClass="gallery-masonry">
                    <div class="card-columns">
                      <div class="card" *ngFor="let zirkelElem of einzelZirkel">
                        <img class="card-img-top"
                             [src]="getBinaryDataForZirkel(zirkelElem.contentId)" alt="{{zirkelElem.contentId}}">
                        <div class="card-body">
                          <h5 class="job-card-desc">Beschreibung</h5>
                          <p>{{zirkelElem.zirkelFarben}}</p><i *ngIf="isEditor()" class="feather icon-edit-2"></i>
                          <p>{{zirkelElem.sccIdText}}</p><i *ngIf="isEditor()" class="feather icon-anchor"></i>
                          <input *ngIf="formEditmode" type="text" class="form-control "/>
                          <div class="job-meta-data mb-1">
                            <i class="feather icon-type"></i> <a [routerLink]="['/singlezirkellist', zirkelElem.id]">{{zirkelElem.textzirkel}}</a>
                          </div>
                          <!--                          <div *ngIf="isAdmin()" class="job-meta-data"><i (click)="deleteEntry(mediaElement)" class="far fa-trash-alt"></i></div>-->
                        </div>
                      </div>
                    </div>
                  </app-card>
                </div>
              </ng-template>
            </ngb-tab>
            <ngb-tab title="AUKTIONEN">
              <ng-template ngbTabContent>
                <app-auctiondetails [bundId]="this.aktiverBund.id"></app-auctiondetails>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4>Kommentare zum Bund</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-xl-12">
              <h6>Neuen Kommentar einfügen</h6>
              <textarea class="form-control max-textarea" maxlength="255" rows="4"
                        [(ngModel)]="newCommentValue"></textarea>
              <button type="button" class="btn btn-primary" (click)="this.addComment()">Kommentar speichern</button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-xl-12">
              <div class="card table-card">
                <div class="card-header borderless">
                  <h5>Vorherige Kommentare</h5>
                </div>
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <div class="recent-scroll" style="height:384px;position:relative;">
                      <table class="table table-hover">
                        <tbody>
                        <tr *ngFor="let oneComment of allComments">
                          <td>
                            <app-profilepic [username]="oneComment.createdBy"></app-profilepic>
                          </td>
                          <td>
                            <h6 class="mb-1">{{oneComment.createdBy}}</h6>
                            <p class="m-0">{{oneComment.commentText}}</p>
                          </td>
                          <td>
                            <h6 class="text-muted">{{oneComment.creationDate | date:('dd.MM.yyyy HH:mm')}}</h6>
                          </td>
                          <!--                          <td><a href="#!" class="badge badge-light-primary badge-pill f-12 mr-2">Reject</a><a href="#!" class="badge badge-light-success badge-pill f-12 mr-2">Approve</a></td>-->
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <app-ui-modal #bundContentZuordnen [dialogClass]="'modal-lg'">
      <div class="app-modal-header">
        <h4 class="modal-title">Zuordnungsfenster</h4>
        <br/>
        <ng-container
          *ngIf="aktiverBund !== null && aktiverBund !== undefined">{{aktiverBund.verbindungsname}}</ng-container>
        <button type="button" class="close basic-close" (click)="bundContentZuordnen.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="app-modal-body">
        <h5>Bund <-> Bund Inhalt</h5>
        <perfect-scrollbar class="overflow-container" [style.width]="'100%'" [style.height]="'300px'"
                           [config]="config">
          <table class="table">
            <tr *ngFor="let einBund of listencontent">
              <td [ngClass]="{'table-back-red': einBund.bund === null || einBund.bund === undefined}">

                <a href="javascript:void(0);"
                   (click)="updateBundContentData(einBund, aktiverBund)">{{einBund.sccId}}</a>
                <br/>
                <span class="badge badge-info" *ngIf="einBund.basisMapping === true">Basis-Map</span><br/>
                <span class="badge badge-secondary"
                      *ngIf="einBund.bund !== null">{{einBund.bund.verbindungsname}}</span>

              </td>
              <td>{{einBund.bundName}}<br/>
                <pre>
                <span class="font-italic text-info">{{einBund.textInhalt.trim().substr(0, 100)}}
                  ...</span>
                  </pre>
              </td>
              <td></td>
            </tr>
          </table>
        </perfect-scrollbar>
      </div>
      <div class="app-modal-footer">
        <button type="button" class="btn btn-default waves-effect"
                (click)="bundContentZuordnen.hide()">Close
        </button>
        <!--        <button type="button" class="btn btn-primary waves-effect waves-light ">Save changes</button>-->
      </div>
    </app-ui-modal>

    <app-ui-modal #bundMetaDatazuordnen [dialogClass]="'modal-lg'">
      <div class="app-modal-header">
        <h4 class="modal-title">Zuordnungsfenster</h4>
        <br/>
        <ng-container *ngIf="aktiverBund !== null && aktiverBund !== undefined">{{aktiverBund.verbindungsname}}
          - {{aktiverBund.ort}}</ng-container>
        <button type="button" class="close basic-close" (click)="bundMetaDatazuordnen.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="app-modal-body">
        <h5>Bund <-> Bund Inhalt</h5>
        <perfect-scrollbar class="overflow-container" [style.width]="'100%'" [style.height]="'300px'"
                           [config]="config">
          <table class="table">
            <tr *ngFor="let metaEntry of bundMetaListe">
              <td [ngClass]="{'table-back-red': metaEntry.bund === null || metaEntry.bund === undefined}">

                <a href="javascript:void(0);"
                   (click)="updateMetaDataEntryForBund(metaEntry, aktiverBund)">{{metaEntry.sccid}}</a>
                <br/>

                <span class="badge badge-success"
                      *ngIf="metaEntry.bund !== null && metaEntry.bund.id === aktiverBund.id">{{metaEntry.bund.verbindungsname}}</span>
                <span class="badge badge-secondary"
                      *ngIf="metaEntry.bund !== null  && metaEntry.bund.id !== aktiverBund.id">{{metaEntry.bund.verbindungsname}}</span>

              </td>
              <td>{{metaEntry.bundName}}<br/>
                {{metaEntry.sourceFileName}} - Seiten: {{metaEntry.pages}}

              </td>
              <td></td>
            </tr>
          </table>
        </perfect-scrollbar>
      </div>
      <div class="app-modal-footer">
        <button type="button" class="btn btn-default waves-effect"
                (click)="bundMetaDatazuordnen.hide()">Close
        </button>
        <!--        <button type="button" class="btn btn-primary waves-effect waves-light ">Save changes</button>-->
      </div>
    </app-ui-modal>

    <app-ui-modal #bundHistory [dialogClass]="'modal-lg'">
      <div class="app-modal-header">
        <h4 class="modal-title">Bund-Bearbeitungsverlauf</h4>
      </div>
      <div class="app-modal-body">

        <perfect-scrollbar class="overflow-container" [style.width]="'100%'" [style.height]="'300px'"
                           [config]="config">
          <table class="table">
            <tr *ngFor="let historyEntry of currentBundHistory">
              <td>
              </td>
              <td><span class="text-warning mb-1" style="font-size: 12px;">{{historyEntry.lastModifiedDate | date:('dd.MM.yyyy HH:mm')}} {{historyEntry.lastModifiedBy}}</span>
                {{historyEntry.verbindungsname}}<br/>
                {{historyEntry.aktiv}}|{{historyEntry.telefonNummer}}|{{historyEntry.burschenband}}
                <button class="badge badge-light-secondary">Wiederherstellen</button>
              </td>
              <td></td>
            </tr>
          </table>
        </perfect-scrollbar>
      </div>
      <div class="app-modal-footer">
        <button type="button" class="btn btn-default waves-effect"
                (click)="bundHistoryModal.hide()">Close
        </button>
        <!--        <button type="button" class="btn btn-primary waves-effect waves-light ">Save changes</button>-->
      </div>
    </app-ui-modal>

  </div>
</div>
