import {Component, OnInit} from '@angular/core';
import {SccService} from '../../scc.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {catchError} from "rxjs/operators";

@Component({
  selector: 'app-requestpasswordreset',
  templateUrl: './requestpasswordreset.component.html',
  styleUrls: ['./requestpasswordreset.component.scss']
})
export class RequestpasswordresetComponent implements OnInit {
  form: FormGroup;
  formUserMail: string;

  // Dynamic parameters for this component's route: /example-params/:first/:second
  routeParams: Params;

  // Query parameters found in the URL: /example-params/one/two?query1=one&query2=two
  queryParams: Params;

  constructor(private route: ActivatedRoute, private router: Router, private sccService: SccService,
              private formBuilder: FormBuilder, private toastr: ToastrService) {
    this.formUserMail = 'usermail';
    this.getRouteParams();
    this.form = this.formBuilder.group(
      {
        usermail: ['', [Validators.required]],
      }
    );
  }

  ngOnInit() {
    console.log(this.queryParams);
    const options = {
      autoClose: false,
      keepAfterRouteChange: false
    };
  }

  save() {
    // prüfe, ob benutzeremila
    console.log('___ {}', this.form.get(this.formUserMail).value);
    if (this.form.get(this.formUserMail).value) {
      // sende request an server
      const req = this.sccService.resetPasswordRequest(this.form.get(this.formUserMail).value);
      // req.pipe(
      //   catchError(this.handleError())
      // );
      req.subscribe(res => {
        console.log('result {}', res);
        this.toastr.success('Anfrage gesendet!', 'Bitte prüfen Sie Ihren Posteingang!');
      });
      // Antwort auswerten
    } else {
      // error
    }
  }

  // private handleError(error: HttpErrorResponse) {
  //   if (error.error instanceof ErrorEvent) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error('An error occurred:', error.error.message);
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong.
  //     console.error(
  //       `Backend returned code ${error.status}, ` +
  //       `body was: ${error.error}`);
  //   }
  //   // Return an observable with a user-facing error message.
  //   return throwError(
  //     'Something bad happened; please try again later.');
  // }


  getRouteParams() {

    // Route parameters
    this.route.params.subscribe(params => {
      this.routeParams = params;
    });

    // URL query parameters
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
    });
  }
}
