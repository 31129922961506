import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login.component";
import {NgModule} from "@angular/core";
import {LogoutComponent} from "./logout.component";

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    children: [

      // {path: '', redirectTo: 'i18n', pathMatch: 'full'},
      // { path: 'i18n', loadChildren: () => import('src/app/book-monkey/iteration-7/i18n/app.module').then(m => m.AppModule) },
    ]
  }, {path: 'logout', component: LogoutComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LoginRoutingModule {

}
