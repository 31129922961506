import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {SccService} from '../../scc.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Userdata} from '../../model/userdata.model';
import {Role, UserRole} from '../../model/implementation';
import {UIRole} from "../../model/UIRole";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-appadmin',
  templateUrl: './appadmin.component.html',
  styleUrls: ['./appadmin.component.scss']
})
export class AppadminComponent implements OnInit {
  newUserForm: FormGroup;
  allUsers: Array<Userdata>;
  allRoles: Array<UIRole>;

  constructor(private fb: FormBuilder, private sccService: SccService, private router: Router, private toastr: ToastrService) {
    this.createForm();
  }
  ngOnInit(): void {
    this.sccService.getAllUsers().subscribe(res => {
      this.allUsers = res;
    });
    this.sccService.getAllUserRoles().subscribe( res => {
      this.allRoles = new Array<UIRole>();
      for (let singleRole of res) {
        let entry  = {
            role: singleRole,
            value: singleRole
        };
        this.allRoles.push(entry);
      }
    });
  }

  get ctrl() { return this.newUserForm.controls; }

  private createForm() {
    this.newUserForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
      username: ['', Validators.required],
      realname: [''],
      bund: [''],
      checkArray: this.fb.array([])
    });
  }

  getAllRolesUser(userid: number): void {
    this.sccService.getUserroles(userid).subscribe((data) => {
      return data;
    });
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.newUserForm.get('checkArray') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value === e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }


  onSave(email, password, username, realname, bund) {
    console.log(this.newUserForm);
    let req = this.sccService.addUser(email, password, username, realname, bund, this.newUserForm.value.checkArray);
    req.subscribe((res: HttpResponse<any>) => {
      console.log('result {}', res);
      if (res.status === 208) {
        this.toastr.info('Datei ist ermittelt und wurde runtergeladen.', 'Link - Erfolg');
      }
      this.newUserForm.reset();
      this.router.navigate([]);
    }, (error: HttpErrorResponse) => {
      console.log('http error {}', error);
    });
    //
  }
}
