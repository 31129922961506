import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'SCC Online';

  sccUserName: string;

  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit() {

    let url = environment.settings.backend;

    let headers: HttpHeaders = new HttpHeaders({
      'Authorization': 'Basic ' + localStorage.getItem('token')
    });
    let options = { headers: headers };
    // this.http.post<Observable<Object>>(url, {}, options).
    // subscribe(principal => {
    //     this.sccUserName = principal['name'];
    //   },
    //   error => {
    //     if (error.status === 401) {
    //       console.log('Error 401 ',);
    //       this.router.navigate(['/login'])
    //
    //     }
    //       // alert('Unauthorized');
    //   }
    // );

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }


}
