import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DocsOverviewComponent} from "./docs-overview.component";

const routes: Routes = [
  {
    path: '',
    component: DocsOverviewComponent,
    data: {
      title: 'Anleitung - Changelog Übersicht',
      icon: 'icon-layout-sidebar-left',
      caption: 'Anleitung',
      status: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocsOverviewRoutingModule { }
