import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SccService} from '../../scc.service';
import {FormControl} from '@angular/forms';
import {Page} from '../../model/page';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {BundWrapper} from '../../model/bundwrapper.model';
import {Bund, MetaDataBund} from '../../model/implementation';
import {Observable} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';

@Component({
  selector: 'app-orphan-bundmetadata-page',
  templateUrl: './orphan-bundmetadata.component.html',
  styleUrls: ['./orphan-bundmetadata.component.scss']
})
export class OrphanBundmetadataComponent implements OnInit {
  @ViewChild('farbenT') public farbenT: TemplateRef<any>;
  @ViewChild('idT') public idT: TemplateRef<any>;
  @ViewChild('verbindungsnameT') public verbindungsnameT: TemplateRef<any>;

  @ViewChild('bundmetadatamodal') metaModal;

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;

  totalModalDatasets = 0;

  env = environment;
  loadingIndicator = true;
  browseComponent = new FormControl('');
  metadataliste = new Array<MetaDataBund>();

  relevantBundListe = new Array<BundWrapper>();

  mdbBund: MetaDataBund;

  page = new Page();

  columns = [];

  dtOptions: DataTables.Settings = {};

  submitted = false;
  suchwort = new FormControl('');

  farben = new FormControl('');
  ort = new FormControl('');
  sccid = new FormControl('');

  switchStatusBundEmpty = true;
  switchStatusVerband = true;

  public config: any;

  onSubmit() {
    this.submitted = true;
  }

  constructor(private sccService: SccService, private router: Router, private activatedRoute: ActivatedRoute) {


    this.page.pageNumber = 0;
    this.page.size = 20;
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('page') !== null) {
        this.page.pageNumber = Number(queryParams.get('page'));
      } else {
        this.page.pageNumber = 0;
      }
    });


    const that = this;
    this.dtOptions = {
      language: {
        url: '/assets/i18n/datatable_de.json'
      },
      pagingType: 'full_numbers',
      lengthMenu: [20],
      lengthChange: false,
      pageLength: this.page.size,
      displayStart: (this.page.pageNumber * this.page.size),
      serverSide: true,
      processing: true,
      searching: false,
      autoWidth: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.getList({
          page: (dataTablesParameters.start / dataTablesParameters.length),
          size: dataTablesParameters.length
        }).subscribe((data) => {
          this.router.navigate([],
            {
              relativeTo: this.activatedRoute,
              queryParams: {page: (dataTablesParameters.start / dataTablesParameters.length)},
              queryParamsHandling: 'merge'
            });
          callback({
            recordsTotal: data.totalElements,
            recordsFiltered: data.totalElements,
            data: []
          });


          this.metadataliste = [...data.content];
          this.page.pageNumber = data.pageable.pageNumber;
          this.page.size = data.pageable.pageSize;
          this.page.totalElements = data.totalElements;
          this.page.totalPages = data.totalPages;

        });
      },
      columns: [{data: 'id'}, {data: 'bundName'}, {data: 'sccid'}, {data: 'pages'}, {data: 'sourceFileName'}],
      columnDefs: [
        {targets: [0, 1, 2, 3, 4], width: '80px'}
      ]
    };


    //     .subscribe(data => {
    //
    //
    //       this.wappenliste = [...data.content];
    //       // this.page.pageNumber = data.pageable.pageNumber;
    //       // this.page.size = data.pageable.pageSize;
    //       // this.page.totalElements = data.totalElements;
    //       // this.page.totalPages = data.totalPages;
    //     });
    //   },
    // };


    // this.getList({offset: 0});

  }

  getRenderedColors(colorInput: string): string {
    return this.sccService.getRenderedColors(colorInput);
  }

  search() {
    //   this.router.navigate(['/search-page'], {
    //     queryParams: {
    //       bundsearch: this.suchwort.value,
    //       farben: this.farben.value,
    //       ort: this.ort.value,
    //       sccid: this.sccid.value
    //     }
    //   });
    //
    //   this.findBundByFilter(this.suchwort.value, this.farben.value, this.ort.value, this.sccid.value, {offset: 0});
  }

  //
  // findBundByFilter(suchbegriff: string, farben: string, ort: string, sccid: string, pageNo): void {
  //   this.sccService.getBuendeWithNameFilter(suchbegriff, farben, ort, sccid, pageNo).subscribe(data => {
  //     this.page.pageNumber = data.pageable.pageNumber;
  //     this.page.size = data.size;
  //     this.page.totalElements = data.totalElements;
  //     this.page.totalPages = data.totalPages;
  //   });
  //   console.log('foo ' + suchbegriff);
  // }


  getList(pageNo): Observable<any> {
    return this.sccService.getMetaDataBundList(true, pageNo, this.switchStatusBundEmpty, this.switchStatusVerband);
  }

  getSelectedBundList(sccid: string): Array<Bund> {
    this.sccService.getBundBySCCId(sccid).subscribe((data) => {
    });
    return null;
  }

  setMetaDataBundEmpty(): void {
    this.mdbBund.bundEmpty = true;
    this.mdbBund.hasNoMatch = false;
    const req = this.sccService.updateMetaDataBund(this.mdbBund);
    req.subscribe((res => {
      // this.page.pageNumber = 0;
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
        this.metaModal.hide();
      });

    }));
  }

  setMetaDataHasNoMatch(): void {
    this.mdbBund.bundEmpty = false;
    this.mdbBund.hasNoMatch = true;
    const req = this.sccService.updateMetaDataBund(this.mdbBund);
    req.subscribe((res => {
      // this.page.pageNumber = 0;
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
        this.metaModal.hide();
      });

    }));
  }

  updateMetaDataBundLink(linkedBund: Bund): void {
    this.mdbBund.bund = linkedBund;
    this.mdbBund.bundEmpty = false;
    this.mdbBund.hasNoMatch = false;
    const req = this.sccService.updateMetaDataBund(this.mdbBund);
    req.subscribe((res => {
      // this.page.pageNumber = 0;
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
        this.metaModal.hide();
      });

    }));
  }

  checkBundAttachedToMetaDataBund(bundid: number): number {
    this.sccService.getMetaDataBundCount(bundid).subscribe((data) => {
      console.log('data: {}', data);
      return data;
    });
    return null;
  }

  openModal(mdb: MetaDataBund): void {
    this.mdbBund = mdb;
    this.metaModal.show();
    this.sccService.getBundBySCCId(this.sccService.sanitizedSccId(mdb.sccid.trim()), 'verbindungsname').subscribe((data) => {
      this.relevantBundListe = new Array<BundWrapper>();
      if (data !== null && data.totalElements !== null) {
        this.totalModalDatasets = data.totalElements;
      }
      for (const bundAktuell of data.content) {
        this.sccService.getMetaDataBundCount(bundAktuell.id).subscribe(data2 => {
          const bw = new BundWrapper();
          bw.bund = bundAktuell;

          if (Number(data2) > 0) {
            bw.count = data2;
          } else {
            bw.count = null;
          }
          this.relevantBundListe.push(bw);
        });
      }
    });
  }

  public isEmpty(myVar): boolean {
    return (myVar && (Object.keys(myVar).length === 0));
  }

  swapSwitchBundEmpty() {
    this.switchStatusBundEmpty = !this.switchStatusBundEmpty;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(null, false);
    });
  }

  swapSwitchVerband() {
    this.switchStatusVerband = !this.switchStatusVerband;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(null, false);
    });
  }

  sanitizedSccId(sccid: string): string {
    return this.sccService.sanitizedSccId(sccid);
  }
}
