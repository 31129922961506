import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RequestpasswordresetComponent} from "./requestpasswordreset.component";

const routes: Routes = [
  {
    path: '',
    component: RequestpasswordresetComponent,
    data: {
      title: 'Passwort zuruecksetzen',
      icon: 'icon-layout-sidebar-left',
      caption: 'Passwort reset',
      status: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequestpasswordresetRoutingModule { }
