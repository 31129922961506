import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SinglezirkellistComponent} from './singlezirkellist.component';
import {SinglezirkellistRoutingModule} from './singlezirkellist-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataTablesModule} from 'angular-datatables';

@NgModule({
  imports: [
    CommonModule,
    SinglezirkellistRoutingModule,
    SharedModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule
  ],
  declarations: [SinglezirkellistComponent]
})
export class SinglezirkellistModule {
}
