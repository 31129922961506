import {Component, Input, OnInit} from '@angular/core';
import {SccService} from "../../../scc.service";
import {Role} from "../../../model/implementation";

@Component({
  selector: 'app-userroleviewer',
  templateUrl: './userroleviewer.component.html',
  styleUrls: ['./userroleviewer.component.scss']
})
export class UserroleviewerComponent implements OnInit {

  @Input('userId')
  private userid: number;

  userRoles: Role[];

  constructor(private sccservice: SccService) { }

  ngOnInit() {
    this.sccservice.getUserroles(this.userid).subscribe((data) => {
      this.userRoles = data;
    });
  }

}
