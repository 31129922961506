import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZirkellisteComponent } from './zirkelliste.component';
import {ZirkellisteRoutingModule} from './zirkelliste-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataTablesModule} from "angular-datatables";

@NgModule({
  imports: [
    CommonModule,
    ZirkellisteRoutingModule,
    SharedModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule
  ],
  declarations: [ZirkellisteComponent]
})
export class ZirkellisteModule { }
