<app-card cardTitle="EBay - Anbindung" [options]="false">
  <div class="row">
    <div class="col-md-6">
      <form [formGroup]="auctionsearchForm">
        <div class="form-group">
          <label for="auctionid">eBay Auktions ID</label>
          <input class="form-control" id="auctionid" formControlName="auctionid">
        </div>
        <button type="submit" class="btn btn-primary" (click)="getAuction()">Auktion speichern
        </button>
      </form>
    </div>
    <div class="spinner-border text-success" role="status" *ngIf="auctionPending">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>Auktionsnr</th>
            <th>Auktionstitel</th>
            <th>Auktionsbeschreibung</th>
            <th>Anbieter</th>
            <th>Datum</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let oneAuction of auctionList">
            <td>{{oneAuction.auctionId.substr(3).replace('|0','')}}</td>
            <td>{{oneAuction.auctionTitle}}</td>
            <td [innerHTML]="oneAuction.auctionDescription.substr(0,40)"></td>
            <td>{{oneAuction.auctionSeller}}</td>
            <td>{{oneAuction.auctionEndDate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</app-card>
