import {Component, Input, OnInit} from '@angular/core';
import {SccService} from '../../scc.service';
import {AuthenticationService} from '../../sccshared/authentication/authentication.service';
import {ProfileinfoService} from '../../sccshared/profileinfo/profileinfo.service';

@Component({
  selector: 'app-profilepic',
  templateUrl: './profilepic.component.html',
  styleUrls: ['./profilepic.component.scss']
})
export class ProfilepicComponent implements OnInit {

  userProfileImageSet: boolean;

  @Input() username: string;

  constructor(private sccService: SccService, private authenticationService: AuthenticationService, private profileInfoService: ProfileinfoService) {
  }

  ngOnInit() {
    this.userProfileImageSet = false;
    this.profileInfoService.messageEmitter.subscribe(msg => {
      this.hasUserProfileImage();
    });
    this.hasUserProfileImage();
  }

  hasUserProfileImage() {
    this.userProfileImageSet = false;
    this.sccService.hasUserProfileImage(this.authenticationService.getLoggedInUserId()).subscribe((data) => {
      if (data !== false) {
        this.userProfileImageSet = true;
      }
    });
  }

  getLinkForUserProfile() {
    if (this.username !== null && this.username !== undefined && this.username !== '') {
      return this.getUserProfileURIByUsername(this.username);
    } else {
      return this.getUserProfileURI();
    }
  }

  getUserProfileURI() {
    return this.sccService.getAbsoluteUserMediaURI(this.authenticationService.getLoggedInUserId().toString());
  }

  getUserProfileURIByUsername(userName: string) {
    return this.sccService.getAbsoluteUsernameMediaURI(userName);
  }


}
