import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserprofileComponent} from './userprofile.component';
import {SharedModule} from '../../shared/shared.module';
import {UserprofileRoutingModule} from './userprofile-routing.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FileUploadModule} from "@iplab/ngx-file-upload";

@NgModule({
  declarations: [UserprofileComponent],
  imports: [
    CommonModule,
    SharedModule,
    UserprofileRoutingModule,
    ReactiveFormsModule,
    FileUploadModule

  ]
})
export class UserprofileModule {
}
