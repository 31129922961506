import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {ReactiveFormsModule} from "@angular/forms";
import {PasswordresetComponent} from "./passwordreset.component";
import {PasswordresetRoutingModule} from "./passwordreset-routing.module";

@NgModule({
  declarations: [PasswordresetComponent],
    imports: [
        CommonModule,
        SharedModule,
        PasswordresetRoutingModule,
        ReactiveFormsModule

    ]
})
export class PasswordresetModule {
}
