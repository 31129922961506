import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'SCC Online Menü',
    main: [
      {
        state: 'search-page',
        short_label: 'S',
        name: 'Suchseite',
        type: 'link',
        icon: 'ti-search'
      },
      {
        state: 'zirkelliste',
        short_label: 'Z',
        name: 'Zirkelsuche',
        type: 'link',
        icon: 'ti-search'
      },
      {
        state: '',
        short_label: 'Z',
        name: 'Wappenliste',
        type: 'link',
        icon: 'ti-view-list-alt'
      },
      {
        state: '',
        short_label: 'Z',
        name: 'Verbandsliste',
        type: 'link',
        icon: 'ti-view-list-alt'
      }
    ]
  }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
