<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card [cardTitle]="'Filter'">
        <form (submit)="search()" method="post">
          <div class="row seacrh-header">
            <div class="col-sm-6">
              <span class="m-b-20">Filter</span><br>
            </div>
          </div>
          <div class="row searcrh-header">
            <div class="col-lg-12 col-sm-12 col-xs-12">
              <div class="input-group input-group-button input-group-primary">
                <div class="col-sm-3 m-b-5">
                  <input type="text" class="form-control" placeholder="Farben"
                         [formControl]="farben" id="farben" name="farben">
                </div>
                <div class="col-sm-3 m-b-5">
                  <input type="text" class="form-control" placeholder="SCCID"
                         [formControl]="sccid" id="sccid" name="sccid">
                </div>
                <div class="col-lg-9 col-sm-9">
                  <button class="btn btn-primary input-group-addon" id="basic-addon1">Suchen</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </app-card>
      <app-card [title]="'Suchergebnis'">
        <table datatable class="table-xl table-striped table-bordered table-hover"
               [dtOptions]="dtOptions" style="width: 90%">
          <thead>
          <tr>
            <th>DatenbankID</th>
            <th>Zirkeltext</th>
            <th>SCC-ID</th>
            <th>Farben</th>
            <th>Vorschaubild</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let curZirkel of zirkelliste">
            <tr>
              <td>{{curZirkel.id}}</td>
              <td>{{curZirkel.textzirkel}}</td>
              <td><a [routerLink]="['/search-page']"
                     [queryParams]="{sccid: curZirkel.sccIdText}">{{curZirkel.sccIdText}}</a></td>
              <td><span [innerHTML]="getRenderedColors(curZirkel.zirkelFarben)"></span></td>
              <td><img [src]="getBinaryDataForZirkel(curZirkel.contentId)" width="180px"></td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </app-card>
    </div>
  </div>
</div>
