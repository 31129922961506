import { Directive, Input, HostListener } from '@angular/core';
import {EditableInputComponent} from './editable-input.component';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[editableOnEnter]'
})
export class EditableOnEnterDirective {
  constructor(private editable: EditableInputComponent) {
  }

  @HostListener('keyup.enter')
  onEnter() {
    this.editable.toViewMode();
  }


  @HostListener('keyup.esc')
  onEscape() {
    this.editable.toViewModeCanceled();
  }
}
