<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card [title]="'Datenbanksuche'">
        <div class="col-sm-12 col-xl-4 m-b-30">
          <span style="vertical-align: center">Verband-Datensätze enthalten?</span>
          <div class="switch switch-primary d-inline m-r-10">
            <input type="checkbox" id="switch-p-1" (click)="swapSwitch1()" [ngModel]="switchStatus1">
            <label for="switch-p-1" class="cr"></label>
          </div>
          <span style="vertical-align: center">"Kein Findex" ausfiltern</span>
          <div class="switch switch-primary d-inline m-r-10">
            <input type="checkbox" id="switch-p-2" (click)="swapSwitch2()" [ngModel]="switchStatusBundEmpty">
            <label for="switch-p-2" class="cr"></label>
          </div>
        </div>
        <!--        <p>Bitte entsprechende Filter setzen und dann über Enter suchen</p>-->
        <!--        <form (submit)="search()" method="post">-->
        <!--          <div class="row seacrh-header">-->
        <!--            <div class="col-sm-6">-->
        <!--              <span class="m-b-20">Benutze die Suche & Filter zur Ergebniseingrenzung</span><br>-->
        <!--            </div>-->
        <!--            <div class="col-lg-8 offset-lg-4 offset-sm-12 col-sm-6 offset-sm-1 col-xs-12">-->
        <!--              <div class="input-group input-group-button input-group-primary">-->
        <!--                <label for="verbindungsname">Verbindungsname</label>-->
        <!--                <input type="text" class="form-control" placeholder="Suchen..."-->
        <!--                       [formControl]="suchwort" id="verbindungsname" name="suchwort">-->
        <!--                <label for="farben">Farben</label>-->
        <!--                <input type="text" class="form-control" placeholder="Suchen..."-->
        <!--                       [formControl]="farben" id="farben" name="farben">-->
        <!--                <label for="ORT">Ort</label>-->
        <!--                <input type="text" class="form-control" placeholder="Suchen..."-->
        <!--                       [formControl]="ort" id="ort" name="ort">-->

        <!--                <button class="btn btn-primary input-group-addon" id="basic-addon1">Search</button>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="row">-->
        <!--            <div class="col-sm-12">-->
        <!--              <label for="sccIdSuche">SCCID</label>-->
        <!--              <input type="text" class="form-control" placeholder="00000"-->
        <!--                     [formControl]="sccid" id="sccIdSuche" name="sccid">-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </form>-->

        <table datatable class="table-xl table-striped table-bordered table-hover"
               [dtOptions]="dtOptions">
          <thead>
          <tr>
            <th>DatenbankID</th>
            <th>Name d. Verbindung</th>
            <th>SCC-ID</th>
            <th>Quelldatei</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let bundContentEntry of bundContentListe">
            <td>{{ bundContentEntry.id }}</td>
            <td>

              <span>{{ bundContentEntry.bundName }}</span>
              <span class="badge badge-warning">{{ bundContentEntry.hasNoMatch ? 'k. Zuordnung'
                : '' }}</span>
              <span class="badge badge-info">{{ bundContentEntry.bundEmpty ? 'Findex leer'
                : '' }}</span>
              <!--                <span class="badge badge-warning">{{ aktWappen.hasNoMatch?'k. Zuordnung':'' }}</span>-->
            </td>
            <td><a [routerLink]="['/search-page']"
                   [queryParams]="{sccid: sanitizedSccId(bundContentEntry.sccId)}">{{ bundContentEntry.sccId }}</a>
            </td>
            <td class="col-md-5 col-orphan-max"><span class="text-monospace"
                                                      (click)="openTextContentModal(bundContentEntry.textInhalt)"><pre>{{ bundContentEntry.textInhalt.trim().substr(
              0, 90) }}</pre></span>
            </td>
            <td>

              <button class="btn btn-info" (click)="openModal(bundContentEntry)">Zuordnen</button>
              <!--              <button class="btn btn-info" (click)="markNotFound(aktWappen)">Bund-Findex leer</button>-->
              <!--              <button class="btn btn-warning" (click)="markVerband(aktWappen)">IST VERBAND</button>-->
            </td>
          </tr>
          </tbody>
        </table>

        <!--        <ngx-datatable-->
        <!--          class="data-table table-responsive"-->
        <!--          [loadingIndicator]="loadingIndicator"-->
        <!--          [headerHeight]="50"-->
        <!--          [footerHeight]="50"-->
        <!--          [rowHeight]="50"-->
        <!--          [rows]="bundContentListe"-->
        <!--          [columns]="columns"-->
        <!--          [externalPaging]="true"-->
        <!--          [footerHeight]="100"-->
        <!--          [columnMode]="'force'"-->
        <!--          [count]="page.totalElements"-->
        <!--          [offset]="page.pageNumber"-->
        <!--          [limit]="page.size"-->
        <!--          [virtualization]="false"-->
        <!--          (page)='getList($event)'-->

        <!--        >-->

        <!--          <ngx-datatable-column name="SCCID" prop="sccId">-->
        <!--            <ng-template let-row="row" let-column="column" let-value="value" let-i="index" let-rowIndex="rowIndex"-->
        <!--                         ngx-datatable-cell-template>-->

        <!--              <span-->
        <!--                (dblclick)="editing[rowIndex + '-name'] = true"-->
        <!--                *ngIf="!editing[rowIndex + '-name']">{{value}}</span>-->
        <!--              <input-->
        <!--                            autofocus-->
        <!--                            (blur)="updateValue($event, 'name', rowIndex)"-->
        <!--                            *ngIf="editing[rowIndex + '-name']"-->
        <!--                            type="text"-->
        <!--                            [value]="value"-->
        <!--                          />-->
        <!--            </ng-template>-->
        <!--          </ngx-datatable-column>-->
        <!--          <ngx-datatable-column name="Name d. Verbindung" prop="bundName">-->
        <!--            <ng-template #verbindungsnameT let-row="row" let-value="value" let-i="index"-->
        <!--                         ngx-datatable-cell-template>-->
        <!--              <span>{{value}}</span>-->
        <!--            </ng-template>-->
        <!--          </ngx-datatable-column>-->
        <!--          <ngx-datatable-column name="Inhalt Bund" prop="textInhalt">-->
        <!--            <ng-template #textContentT let-row="row" let-value="value" let-i="index"-->
        <!--                         ngx-datatable-cell-template>-->
        <!--              <span class="text-monospace" (click)="openTextContentModal(value)"><pre>{{value.trim().substr(0,90)}}</pre></span>-->
        <!--            </ng-template>-->
        <!--          </ngx-datatable-column>-->
        <!--          <ngx-datatable-column name="Actions" [width]="200" sortable="false" prop="$key">-->
        <!--            <ng-template let-id="value" let-row="row" let-value="value" ngx-datatable-cell-template>-->
        <!--              <button class="btn btn-primary btn-mini btn-round" (click)="openModal(row)">Zuordnen</button>  |-->
        <!--              <button class="btn btn-info btn-mini btn-round" (click)="updateBundContentIsVerband()">Verband</button>  |-->
        <!--              <button class="btn btn-danger btn-mini btn-round">DB Ohne Bund</button>-->
        <!--              <button class="btn btn-success btn-mini btn-round">DB Bund unklar</button>-->

        <!--            </ng-template>-->
        <!--          </ngx-datatable-column>-->
        <!--        </ngx-datatable>-->


      </app-card>
    </div>
  </div>

  <app-ui-modal #bundcontentmodal>
    <div class="app-modal-header">
      <h4 class="modal-title">Zuordnungsfenster</h4>
      <button type="button" class="close basic-close" (click)="bundcontentmodal.hide()">
      </button>
      <span aria-hidden="true"
            *ngIf="selBundContent !== null && selBundContent !== undefined">{{selBundContent.sccId}} {{selBundContent.bundName}}</span>
      <div>
        <button type="button" class="btn btn-warning" ngbTooltip="btn-warning"
                (click)="setMetaDataHasNoMatch()">Findex-Name nicht passend
        </button>
        |
        <button type="button" class="btn btn-info" ngbTooltip="btn-warning"
                (click)="setMetaDataBundEmpty()">Bund-Findex leer
        </button>
      </div>

    </div>
    <div class="app-modal-body">
      <h5>Meta von </h5>
      <perfect-scrollbar class="overflow-container" [style.width]="'100%'" [style.height]="'300px'"
                         [config]="config">
        <table class="table">
          <tr *ngFor="let bundEntry of relevantBundListe">
            <td
              [ngClass]="{'table-back-red': bundEntry.bund.aktiv === false || bundEntry.bund.aktiv === null}">
              <a class="cursor-pointer"
                 (click)="updateBundContentLink(bundEntry.bund)">{{bundEntry.bund.sccId}}</a>
            </td>
            <td>{{bundEntry.bund.verbindungsname}} ({{bundEntry.bund.ort}}
              ) {{bundEntry.bund.gruendungsDatum}}
              <span *ngIf="bundEntry.count"><i class="icofont icofont-edit"></i> {{bundEntry.count}}
                </span>
              <!--            <td>{{bundEntry.verbindungsname}} ({{bundEntry.ort}}) ({{checkBundAttachedToMetaDataBund(bundEntry.verbindungId)}}<br/>-->
            </td>
            <td></td>
          </tr>
        </table>
      </perfect-scrollbar>
    </div>
    <div class="app-modal-footer">
      <span class="badge badge-danger"
            *ngIf="totalModalDatasets !== null && totalModalDatasets > 100">Nicht alle Sätze angezeigt</span>
      <span class="badge badge-success"
            *ngIf="totalModalDatasets !== null && totalModalDatasets < 100">Ingesamt {{totalModalDatasets}}
        Datensätze</span>

      <button type="button" class="btn btn-default waves-effect"
              (click)="bundcontentmodal.hide()">Schliessen
      </button>
    </div>
  </app-ui-modal>

  <app-ui-modal #textdetailpopup>
    <div class="app-modal-header">
      <h4 class="modal-title">Stammdateninhalt</h4>
      <button type="button" class="close basic-close" (click)="textdetailpopup.hide()">

      </button>
    </div>
    <div class="app-modal-body">
      <h5>Stammdateninhalt </h5>
      <perfect-scrollbar class="overflow-container" [style.width]="'100%'" [style.height]="'300px'"
                         [config]="config">
        <span class="text-monospace"><pre>{{textdetailpopupText}}</pre></span>
      </perfect-scrollbar>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-default waves-effect"
              (click)="closeTextContentModal()">Schlie&szlig;en
      </button>
    </div>
  </app-ui-modal>

</div>
