<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card [cardTitle]="'Suchfilter'">
        <form (submit)="search()" method="post" [formGroup]="searchForm">
          <div class="row searcrh-header">
            <div class="col-lg-12 col-sm-12 col-xs-12">
              <div class="input-group input-group-button input-group-primary">
                <div class="col-sm-2 m-b-5">
                  <input type="text" class="form-control" placeholder="Farben"
                         formControlName="farben" id="farben" name="farben">
                </div>
                <div class="col-sm-2 m-b-5">
                  <input type="text" class="form-control" placeholder="SCCID"
                         formControlName="sccid" id="sccid" name="sccid">
                </div>
                <div class="col-sm-2 m-b-5">
                  <input type="text" class="form-control" placeholder="Bundbezeichnung/Ort"
                         formControlName="searchbundname" id="searchbundname" name="searchbundname">
                </div>
                <div class="col-sm-4">

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="input-group input-group-button input-group-primary">
              <div class="col-sm-2 mb-sm-3"><input type="text" class="form-control" placeholder="Oben Links" formControlName="obenLinks" id="obenLinks" name="obenLinks"></div>
              <div class="col-sm-2 mb-sm-3"><input type="text" class="form-control" placeholder="Oben Rechts" formControlName="obenRechts" id="obenRechts" name="obenRechts"></div>
              <div class="col-sm-2 mb-sm-3"><input type="text" class="form-control" placeholder="Unten Links" formControlName="untenLinks" id="untenLinks" name="untenLinks"></div>
              <div class="col-sm-2 mb-sm-3"><input type="text" class="form-control" placeholder="Unten rechts" formControlName="untenRechts" id="untenRechts" name="untenRechts"></div>
              <div class="col-sm-2 mb-sm-3"><input type="text" class="form-control" placeholder="Helmzier" formControlName="helmzier" id="helmzier" name="helmzier"></div>
              <div class="col-sm-2 mb-sm-3"><input type="text" class="form-control" placeholder="Herzschild" formControlName="herzschild" id="herzschild" name="herzschild"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-9 col-sm-9">
              <button class="btn btn-primary input-group-addon" id="basic-addon1">Suchen</button>
            </div>
          </div>
        </form>
      </app-card>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <app-card [cardTitle]="'Suchergebnis'">
        <div class="row">
          <div class="col-lg-12 col-sm-12">Bitte beachten: Die Felder link-oben-rechts-unten sind spiegelverkehrt erfasst zum sichtbaren Element. Das heißt: Das Feld, was man oben rechts sieht, ist hier oben LINKS. Ebenso unten.</div>
        </div>
        <table datatable class="table-xl table-striped table-bordered table-hover"
               [dtOptions]="dtOptions">
          <thead>
          <tr>
            <th>DatenbankID</th>
            <th>Wappen-Bund-Text</th>
            <th>SCC-ID</th>
            <th>Farben</th>
            <th>links oben</th>
            <th>rechts oben</th>
            <th>links unten</th>
            <th>rechts unten</th>
            <th>Herzschild</th>
            <th>Helmzier</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let curWappen of wappenliste">
            <tr>
              <td>{{curWappen.id}}</td>
              <td>{{curWappen.nameImport}}</td>
              <td><a [routerLink]="['/search-page']"
                     [queryParams]="{sccid: trimmed(curWappen.sccId)}">{{curWappen.sccId}}</a></td>
              <td>{{curWappen.farben}}</td>
              <td>{{curWappen.linksOben}}</td>
              <td>{{curWappen.rechtsOben}}</td>
              <td>{{curWappen.linksUnten}}</td>
              <td>{{curWappen.rechtsUnten}}</td>
              <td>{{curWappen.herzschild}}</td>
              <td>{{curWappen.helmzier}}</td>
              <td>&nbsp;</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </app-card>
    </div>
  </div>
</div>
