import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, retry} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../environments/environment';
import {
  Bund,
  BundComment,
  BundContent, EBayAuction, MediaData,
  MetaDataBund, Role,
  SCCNews,
  Wappen,
  Zirkel
} from './model/implementation';
import {UserDTO} from './model/userDTO.model';
import {Userdata} from './model/userdata.model';
import {WappenDTO} from './model/wappenDTO.model';
import {CommentDTOModel} from './model/commentDTO.model';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class SccService {
  private static API_BASE = environment.settings.backend;
  env = environment;
  // private bundUrl = this.env.settings.backend + '/sccid?sccid=6D145&aktiv=true';
  // private bundUrl = 'https://jsonplaceholder.typicode.com/todos/1';

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private einzelbundURL = this.env.settings.backend + '/bund/${bundId}';
  private searchURL = this.env.settings.backend + '/search';

  constructor(
    private http: HttpClient
  ) {

  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  // getBuende(): Observable<any> {
  //   console.log('foo on ', this.bundUrl);
  //
  //   return this.http.get(this.bundUrl)
  //     .pipe(
  //       map(this.extractData));
  //   // tap(_ => this.log('fetched heroes')),
  //   // catchError(this.handleError('getHeroes', []))
  //
  //   // console.log('blubb::', blubb$);
  //   // return blubb$;
  // }

  getZirkelForBund(bundId: number) {
    return this.http.get(SccService.API_BASE + `/bund/${bundId}/zirkel`)
      .pipe(
        map(this.extractData)
      );
  }

  getMediaForBund(bundId: number) {
    return this.http.get(SccService.API_BASE + `/bund/${bundId}/media`)
      .pipe(
        map(this.extractData)
      );
  }

  uploadMediaToBund(anhang: File, bundId: string, fileDescription: string, fileType: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', anhang);
    formData.append('fileDescription', fileDescription);
    formData.append('fileType', fileType);
    return this.http.post(SccService.API_BASE + `/bund/${bundId}/upload`,
      formData,
      {
        reportProgress: true,
        responseType: 'text'
      }
    );
  }

  uploadUserProfileImage(anhang: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', anhang[0]);
    formData.append('fileDescription', 'UserProfile');
    formData.append('fileType', anhang.type);
    return this.http.post(SccService.API_BASE + `/userdata/profileimage`,
      formData,
      {
        reportProgress: true,
        responseType: 'text'
      }
    );
  }

  hasUserProfileImage(userId: number): Observable<any> {
    return this.http.get(SccService.API_BASE + `/userdata/${userId}/hasprofileimage`);
  }

  getWappenlisteForBund(bundId: number) {
    return this.http.get(SccService.API_BASE + `/bund/${bundId}/wappen`);
  }

  getBuendeWithNameFilter(suchbegriff: string, farben: string, ort: string, sccid: string, gdJahr: string, wahlspruch: string, nuraktiv: string, verbandsfilter: string, pageNo): Observable<any> {
    // let params = new HttpParams();
    // params.append("verbindungsname", suchbegriff);
    console.log('nuraktiv: {}', nuraktiv);
    return this.http.get(this.searchURL, {
      params: {
        verbindungsname: suchbegriff,
        farben: farben != null ? farben : '',
        ort,
        sccid,
        gdjahr: gdJahr,
        wahlspruch,
        nuraktiv,
        verbandid: verbandsfilter,
        page: pageNo.page,
        size: pageNo.size
      }
    })
      .pipe(
        map(this.extractData));

  }

  getBuendeWithNameFilterAjax(suchbegriff: string, farben: string, ort: string, sccid: string, gdJahr: string, wahlspruch: string, nuraktiv: string, verbandsfilter: string, pageNo): Observable<any> {
    // let params = new HttpParams();
    // params.append("verbindungsname", suchbegriff);
    return this.http.get(this.searchURL, {
      params: {
        verbindungsname: suchbegriff !== null ? suchbegriff : '',
        farben: farben !== null ? farben : '',
        ort: ort !== null ? ort : '',
        sccid: sccid !== null ? sccid : '',
        gdjahr: gdJahr !== null ? gdJahr : '',
        wahlspruch: wahlspruch !== null ? wahlspruch : '',
        nuraktiv: nuraktiv !== null ? nuraktiv : '',
        verbandid: verbandsfilter !== null ? verbandsfilter : '',
        page: pageNo.page,
        size: pageNo.size
      }
    });
  }

  getAbsoluteImageURI(contentId: string): string {
    const retVal = SccService.API_BASE + `/imagezirkel/${contentId}`;
    return retVal;
  }

  getAbsoluteMediaURI(contentId: string): string {
    const retVal = SccService.API_BASE + `/imagewappen/${contentId}`;
    return retVal;
  }

  getAbsoluteUserMediaURI(userId: string): string {
    const retVal = SccService.API_BASE + `/profileimage/${userId}`;
    return retVal;
  }


  getEinzelBund(bundId: number): Observable<any> {
    return this.http.get(SccService.API_BASE + `/bund/${bundId}`, {});
  }

  getBundContent(bundId: number): Observable<any> {
    return this.http.get(SccService.API_BASE + `/bund/${bundId}/content`, {});
  }

  getImageMetadata(imageId: number) {
    return this.http.get(SccService.API_BASE + `/image/${imageId}/metadata`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }

  getRenderedColors(colorInput: string): string {
    if (colorInput === null) {
      return;
    }
    const toArray = colorInput.split('-');
    let komplett = '';

    toArray.forEach(farbe => {
      farbe = farbe.toLowerCase().trim();
      switch (farbe) {
        case 'ro':
          farbe = 'red';
          break;
        case 'gr':
          farbe = 'green';
          break;
        case 'ge':
          farbe = 'yellow';
          break;
        case 'bl':
          farbe = 'blue';
          break;
        case 'go':
          farbe = 'gold';
          break;
        case 'w':
          farbe = 'white';
          break;
        case 'sch':
          farbe = 'black';
          break;
        case 'vi':
          farbe = 'violet';
          break;
        case 'or':
          farbe = 'orange';
          break;
        case 'si':
          farbe = 'silver';
          break;
        default:
          farbe = '*' + farbe;
      }
      if (farbe.substr(0, 1) === '*' && farbe.length > 1) {
        komplett = komplett + '<div class="couleur-container">' + farbe + '</div>';
      } else {
        komplett = komplett + '<div class="' + farbe + '-couleur couleur-container">&nbsp;&nbsp;&nbsp;&nbsp;</div>';
      }
    });
    return komplett;
  }

  saveData(aktiverBund: Bund): Observable<any> {
    return this.http.put(SccService.API_BASE + `/bund/${aktiverBund.id}`, aktiverBund, {
      // params: {
      //   verbindungsname: suchbegriff,
      //   farben: farben != null? farben: '',
      //   ort: ort,
      //   sccid: sccid,
      //   page: pageNo.offset
      // }
    });
    // request.subscribe(value => {
    //   console.log(value);
    // });
  }


  // ---- Bund Content
  getBundContentFor(sccId: string): Observable<any> {
    const callerURL = SccService.API_BASE + `/bundcontent/search?sccid=${sccId}`;
    return this.http.get(callerURL)
      .pipe(
        map(this.extractData)
      );
  }


  getBundContentOrphanCount(): Observable<any> {
    const callerURL = SccService.API_BASE + '/bundcontent/orphancount';
    return this.http.get(callerURL)
      .pipe(
        map(this.extractData)
      );
  }

  getMetaDataBundOrphanCount(): Observable<any> {
    const callerURL = SccService.API_BASE + '/metadatabund/orphancount';
    return this.http.get(callerURL)
      .pipe(
        map(this.extractData)
      );
  }

  getMetaDataBundCount(id: number): Observable<any> {
    const callerURL = SccService.API_BASE + `/metadatabund/count?verbindungid=${id}`;
    return this.http.get(callerURL)
      .pipe(
        map(this.extractData)
      );
  }

  getMetaDataBundList(orphaned: boolean, pageNo, switchStatusBundEmpty: boolean, switchStatusVerband: boolean): Observable<any> {
    const callerURL = SccService.API_BASE + '/metadatabund';

    let params = new HttpParams();
    if (orphaned === true) {
      params = params.append('orphan', 'true');
    }
    if (switchStatusBundEmpty !== null && switchStatusBundEmpty === true) {
      params = params.append('ignoreempty', 'true');
    }
    if (switchStatusVerband !== null && switchStatusVerband === true) {
      params = params.append('verband', 'true');
    }

    params = params.append('page', pageNo.page).append('size', pageNo.size);

    return this.http.get(callerURL, {
      params
    })
      .pipe(
        map(this.extractData)
      );
  }

  getWappenList(orphaned: boolean, emptyBund: boolean, pageObj, sccid?: string, farben?: string, bundname?: string, obenlinks?: string,
                obenrechts?: string, untenlinks?: string, untenrechts?: string, helmzier?: string,
                herzschild?: string): Observable<any> {
    const callerURL = SccService.API_BASE + '/wappen';
    // if (orphaned === true) {
    //   callerURL = callerURL + '?orphan=true&ignoreVerband=true';
    // }
    // console.log('traced {} {} {}', orphaned, emptyBund, pageObj);
    return this.http.get(callerURL, {
      params: {
        page: pageObj.page,
        orphan: orphaned ? 'true' : 'false',
        ignoreVerband: orphaned ? 'true' : 'false',
        bundEmpty: emptyBund ? 'true' : 'false',
        farben: farben !== null ? farben : '',
        sccid: sccid !== null ? sccid : '',
        obenlinks: obenlinks !== null ? obenlinks : '',
        untenlinks: untenlinks !== null ? untenlinks : '',
        obenrechts: obenrechts !== null ? obenrechts : '',
        untenrechts: untenrechts !== null ? untenrechts : '',
        helmzier: helmzier !== null ? helmzier : '',
        herzschild: herzschild !== null ? herzschild : '',
        bundname: bundname !== null ? bundname : ''
      }
    });
  }

  getWappenOrphanCount(): Observable<any> {
    const callerURL = SccService.API_BASE + '/wappen/orphancount';
    return this.http.get(callerURL)
      .pipe(
        map(this.extractData)
      );
  }

  getBundBySCCId(sccid: string, sorting?: string): Observable<any> {
    let params = new HttpParams();
    if (sorting != null) {
      params = params.append('sort', sorting);
    }
    params = params.append('sccid', sccid);
    params = params.append('size', '100');
    return this.http.get(SccService.API_BASE + `/search`,
      {params}
    );
  }

  updateMetaDataBund(mdb: MetaDataBund) {
    const callerURL = SccService.API_BASE + `/metadatabund/${mdb.id}`;
    const request = this.http.put(callerURL, mdb, {});
    return request;
  }

  updateWappen(selWappen: Wappen, refBund: Bund) {
    const callerURL = SccService.API_BASE + `/wappen/${selWappen.id}?bundid=${refBund.id}`;
    const request = this.http.put(callerURL, selWappen, {});
    return request;
  }

  updateBundContent(selBundContent: BundContent, refBund?: Bund) {
    let params = new HttpParams();
    if (refBund != null) {
      params = params.append('bundid', refBund.id.toString());
    }
    const callerURL = SccService.API_BASE + `/bundcontent/${selBundContent.id}`;
    const request = this.http.put(callerURL, selBundContent, {params});
    return request;
  }

  getBundcontentListeForBund(bundId: number) {
    return this.http.get(SccService.API_BASE + `/bund/${bundId}/content`);
  }

  getBundContentList(orphaned: boolean, pageNo, withVerband?: boolean, ignoreBundEmpty?: boolean): Observable<any> {
    const callerURL = SccService.API_BASE + '/bundcontent';

    let params = new HttpParams();
    if (orphaned === true) {
      params = params.append('orphan', 'true');
    }
    if (withVerband !== null && withVerband === false) {
      params = params.append('verband', 'false');
    }
    if (ignoreBundEmpty === true) {
      params = params.append('ignoreEmpty', 'true');
    }
    params = params.append('page', pageNo.page);
    return this.http.get(callerURL, {
      params
    });
    // .pipe(
    //   map(this.extractData)
    // );
  }

  getUserData(): Observable<any> {
    const callerURL = SccService.API_BASE + '/userdata/profile';
    return this.http.get<UserDTO>(callerURL, {
      params: {}
    })
      .pipe(
        retry(1)
      );
  }

  updateUserData(userdata: UserDTO) {
    const callerURL = SccService.API_BASE + '/userdata/profile';
    return this.http.put<UserDTO>(callerURL, userdata);
  }

  resetPasswordRequest(mailAddress: string) {
    const callerURL = SccService.API_BASE + '/userdata/resetpassword';
    const bodyData = {
      useremail: mailAddress
    };
    return this.http.post(callerURL, bodyData, {
      params: {}
    })
      .pipe(
        retry(1)
      );
  }

  updatePassword(tokenData: string, newPassData: string) {
    const callerURL = SccService.API_BASE + '/userdata/updatepassword';
    const bodyData = {
      passwordToken: tokenData,
      newPass: newPassData
    };
    return this.http.post(callerURL, bodyData, {
      params: {}
    })
      .pipe(
        retry(1)
      );
  }

  addUser(email: string, password: string, username: string, realname: string, bund: string, roles: Array<String>) {
    const callerURL = SccService.API_BASE + '/admin/users';
    const bodyData = {
      email,
      password,
      username,
      realname,
      bund,
      roles
    };
    return this.http.post(callerURL, bodyData, {
      params: {},
      observe: 'response'
    })
      .pipe(
        retry(1)
      );
  }

  getAllUsers() {
    const callerURL = SccService.API_BASE + '/admin/users';
    return this.http.get<Userdata[]>(callerURL);
  }

  getUserroles(userid: number) {
    const callerURL = SccService.API_BASE + `/admin/userroles/${userid}`;
    return this.http.get<Role[]>(callerURL);
  }

  getAllNews() {
    const callerURL = SccService.API_BASE + '/news';
    return this.http.get<SCCNews[]>(callerURL);
  }

  setWappenIsVerband(inpWappen: Wappen, b: WappenDTO) {
    const callerURL = SccService.API_BASE + `/wappen/${inpWappen.id}/isverband`;
    const request = this.http.put<WappenDTO>(callerURL, b, {});
    return request;
  }

  setWappenBundNotFound(inpWappen: Wappen, wap: WappenDTO) {
    const callerURL = SccService.API_BASE + `/wappen/${inpWappen.id}/nomatch`;
    const request = this.http.put<WappenDTO>(callerURL, wap, {});
    return request;
  }

  getAllZirkelTypes(pageObj, charPrefix: string): Observable<any> {
    const callerURL = SccService.API_BASE + '/zirkel/zirkeltypes';
    let params = new HttpParams();
    params = params.append('page', pageObj.page);
    params = params.append('prefix', charPrefix);
    return this.http.get(callerURL, {params});
  }

  getAllZirkelTypesDistinct(pageObj, charPrefix: string): Observable<any> {
    const callerURL = SccService.API_BASE + '/zirkel/distinctzirkeltypes';
    let params = new HttpParams();
    params = params.append('page', pageObj.page);
    params = params.append('prefix', charPrefix);
    return this.http.get(callerURL, {params});
  }


  updateBundContentVerband(selBundContent: BundContent, b: boolean) {
    const callerURL = SccService.API_BASE + `/wappen/${selBundContent.id}/isverband`;
    const request = this.http.put<BundContent>(callerURL, b, {});
    return request;
  }

  // addBundComment(bundComment: BundComment) {
  //   const callerURL = SccService.API_BASE + `/bundcomments`;
  //   const request = this.http.post<BundComment>(callerURL, bundComment, {});
  //   return request;
  // }
  addBundComment(bundComment: CommentDTOModel) {
    const callerURL = SccService.API_BASE + `/bundcomments`;
    const request = this.http.post<BundComment>(callerURL, bundComment, {});
    return request;
  }

  getAllComments(bundId: number) {
    const callerURL = SccService.API_BASE + `/bundcomments/` + bundId;
    const request = this.http.get<any>(callerURL, {});
    return request;
  }

  getAllMediaTypes(): Observable<any> {
    const callerURL = SccService.API_BASE + `/mediatypes`;
    return this.http.get<any>(callerURL, {})
      .pipe(
        map(this.extractData)
      );
  }

  pushURLUpload(bundId: string, linkaddress: string, linkcomment: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('linkAdresse', linkaddress);
    formData.append('linkKommentar', linkcomment);
    return this.http.post(SccService.API_BASE + `/linkupload/${bundId}`,
      formData,
      {
        reportProgress: true,
        responseType: 'json'
      }
    );
  }

  deleteMediaDataEntry(id: string) {

    return this.http.delete(SccService.API_BASE + `/mediadata/${id}`, {});
  }

  invokeEbayAuctionGrabbing(id: string, bundId: string) {
    // const formData: FormData = new FormData();
    // formData.append('auctionId', id);
    return this.http.post(SccService.API_BASE + `/auction/ebay/${bundId}`,
      {
        auctionId: id,
        additionalDescription: ''
      },
      {
        params: {}
        // reportProgress: true,
        // responseType: 'json'
      }
    );
  }

  checkAuctionsForBund(bundId: string): Observable<any> {
    return this.http.get(SccService.API_BASE + `/auction/${bundId}`);
  }

  getAllUserRoles(): Observable<any> {
    return this.http.get(SccService.API_BASE + `/admin/roles`);
  }

  // Helper

  sanitizedSccId(sccId: string): string {
    if (sccId !== null) {
      sccId = sccId.trim();
      sccId = sccId.replace('-', '');
      sccId = sccId.replace('(w)', '');
      sccId = sccId.replace('(m+w)', '');
      sccId = sccId.replace('(ex)', '');
      sccId = sccId.replace(' ', '');
      sccId = sccId.replace('(ex ?)', '');
      sccId = sccId.replace('(ex?)', '');
      sccId = sccId.trim();
      return sccId;
    }
    return '';
  }

  getBuendeById(id: string, sorting?: string): Observable<any> {
    let params = new HttpParams();
    if (sorting != null) {
      params = params.append('sort', sorting);
    }
    return this.http.get(SccService.API_BASE + `/bund/${id}/similar`, {params});
  }

  getAllMatchingZirkel(param: { size: any; page: number }, searchPrefix: string, buchstabenId: string, farben: string, sccid: string): Observable<any> {
    const callerURL = SccService.API_BASE + `/zirkel/listall/${buchstabenId}`;
    let params = new HttpParams();
    params = params.append('page', String(param.page));
    if (searchPrefix) {
      params = params.append('prefix', searchPrefix);
    }
    if (farben) {
      params = params.append('farben', farben);
    }
    if (sccid) {
      params = params.append('sccid', sccid);
    }
    return this.http.get(callerURL, {params});
  }

  deleteUserProfileImage() {
    return this.http.delete(SccService.API_BASE + `/userdata/profileimage`);
  }

  getLast5Comments(): Observable<any> {
    const callerURL = SccService.API_BASE + `/bundcomments/lastcomments`;
    return this.http.get(callerURL);
  }

  getLast5ChangesBund(): Observable<any> {
    const callerURL = SccService.API_BASE + `/bund/lastedits`;
    return this.http.get(callerURL);
  }

  getAbsoluteUsernameMediaURI(userName: string) {
    const retVal = SccService.API_BASE + `/profileimage/${userName}?userType=true`;
    return retVal;
  }

  getCountBundContentForSCCID(sccid: string) {
    const callerURL = SccService.API_BASE + `/bundcontent/${sccid}/count`;
    return this.http.get(callerURL);
  }

  getMetaDataBundFor(sccId: string): Observable<any> {
    const callerURL = SccService.API_BASE + `/metadatabund/search?sccid=${sccId}`;
    return this.http.get(callerURL);
  }


  // AuditController
  getAuditForBund(sccId: string): Observable<any> {
    const callerURL = SccService.API_BASE + `/audit/bund/${sccId}`;
    return this.http.get(callerURL);
  }

  getVerbandList(pageObj, verbandname?: string, verbandsabk?: string, verbandaktiv?: boolean,
                 verbandgrp?: string): Observable<any> {
    const callerURL = SccService.API_BASE + '/verband';
    // if (orphaned === true) {
    //   callerURL = callerURL + '?orphan=true&ignoreVerband=true';
    // }
    // console.log('traced {} {} {}', orphaned, emptyBund, pageObj);
    return this.http.get(callerURL, {
      params: {
        page: pageObj.page,
        verbandname: verbandname !== null ? verbandname : '',
        verbandsabk: verbandsabk !== null ? verbandsabk : '',
        verbandaktiv: verbandaktiv ? 'true' : 'false',
        verbandgrp: verbandgrp != null ? verbandgrp : ''
      }
    });
  }

  getVerbandsSistersForVerband(pageObj, verbandid: number): Observable<any> {
    const callerURL = SccService.API_BASE + '/verband/similarverband';
    // if (orphaned === true) {
    //   callerURL = callerURL + '?orphan=true&ignoreVerband=true';
    // }
    // console.log('traced {} {} {}', orphaned, emptyBund, pageObj);
    return this.http.get(callerURL, {
      params: {
        // page: pageObj.page,
        verbandid: verbandid != null ? verbandid.toString() : ''
      }
    });
  }

}
