<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card [title]="'Datenbanksuche'">

        <button class="btn" (click)="toggleBundEmpty()" [ngClass]="{'btn-info':filterBundEmpty === true, 'btn-outline-info':filterBundEmpty === false}">
          {{filterBundEmpty?'Filter Bund leer aktiv':'Filter Bund leer NICHT aktiv'}}
        </button>
        <div style="height:25px;"></div>
        <table datatable class="table table-striped table-bordered nowrap table-hover" [dtOptions]="dtOptions">
          <thead>
          <tr>
            <th>DatenbankID</th>
            <th>Name d. Verbindung</th>
            <th>SCC-ID</th>
            <th>Couleur/Farben</th>
            <th>Wappenbestandteile</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let aktWappen of wappenliste">
            <td>{{ aktWappen.id }}</td>
            <td>
                <span>{{ aktWappen.bundImport }}</span>
                <span class="badge badge-warning">{{ aktWappen.hasNoMatch?'k. Zuordnung':'' }}</span>
            </td>
            <td>
              <a [routerLink]="['/search-page']" [queryParams]="{sccid: sanitizedSccId(aktWappen.sccId)}">{{ aktWappen.sccId }}</a>
            </td>
            <td><span [innerHTML]="getRenderedColors(aktWappen.farben)"></span></td>
            <td>{{ aktWappen.linksOben }}</td>
            <td>
              <button [routerLink]="['/bunddetail', aktWappen.id]" class="btn btn-info">Detailansicht</button>
              <button class="btn btn-info" (click)="openModal(aktWappen)">Zuordnen</button>
<!--              <button class="btn btn-info" (click)="markNotFound(aktWappen)">Bund-Findex leer</button>-->
              <button class="btn btn-warning" (click)="markVerband(aktWappen)">IST VERBAND</button>
            </td>
          </tr>
          </tbody>
        </table>
      </app-card>
    </div>
  </div>

  <app-ui-modal #bundmetadatamodal>
    <div class="app-modal-header">
      <h4 class="modal-title">Zuordnungsfenster</h4>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="bundmetadatamodal.hide()">

      </button>
      <span aria-hidden="true"
            *ngIf="selWappen !== null && selWappen !== undefined">{{selWappen.sccId}} {{selWappen.nameImport}}</span>
      <div>
        <button type="button" class="btn btn-warning" ngbTooltip="btn-warning" (click)="markNotFound(selWappen)">Findex-Name nicht passend</button>  |
        <button type="button" class="btn btn-info" ngbTooltip="btn-warning" (click)="markBundListEmpty(selWappen)">Bund-Findex leer</button>
      </div>
    </div>
    <div class="app-modal-body">
      <h5>Meta von </h5>
      <perfect-scrollbar class="overflow-container" [style.width]="'100%'" [style.height]="'300px'"
                         [config]="config">
        <table class="table">
          <tr *ngFor="let bundEntry of relevantBundListe">
            <td
              [ngClass]="{'table-back-red': bundEntry.bund.aktiv === false || bundEntry.bund.aktiv === null}">
              <a class="cursor-pointer" (click)="updateWappenLink(bundEntry.bund)" >{{bundEntry.bund.sccId}}</a>
            </td>
            <td>{{bundEntry.bund.verbindungsname}} ({{bundEntry.bund.ort}}) {{bundEntry.bund.gruendungsDatum}}
              <span *ngIf="bundEntry.count"><i class="icofont icofont-edit"></i> {{bundEntry.count}}
                </span>
              <!--            <td>{{bundEntry.verbindungsname}} ({{bundEntry.ort}}) ({{checkBundAttachedToMetaDataBund(bundEntry.verbindungId)}}<br/>-->
            </td>
            <td></td>
          </tr>
        </table>
      </perfect-scrollbar>
    </div>
    <div class="app-modal-footer">
      <span class="badge badge-danger" *ngIf="totalModalDatasets !== null && totalModalDatasets > 100">Nicht alle Sätze angezeigt</span>
      <span class="badge badge-success" *ngIf="totalModalDatasets !== null && totalModalDatasets < 100">Ingesamt {{totalModalDatasets}} Datensätze</span>
      <button type="button" class="btn btn-default waves-effect"
              (click)="bundmetadatamodal.hide()">Close
      </button>
    </div>
  </app-ui-modal>

</div>
