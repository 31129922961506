<!--<section class="login p-fixed d-flex text-center bg-primary common-img-bg">-->
<!--  <div class="container">-->
<!--    <div class="row">-->
<!--      <div class="col-sm-12">-->
<!--        <div class="login-card card-block auth-body mr-auto ml-auto">-->
<!--          <form [formGroup]="form" (ngSubmit)="save()" #formDir="ngForm" novalidate-->
<!--                class="md-float-material">-->
<!--            <div class="auth-box">-->
<!--              <div class="row m-b-20">-->
<!--                <div class="col-md-12">-->
<!--                  <h3 class="text-left txt-primary">Passwort zurücksetzen</h3>-->
<!--                </div>-->
<!--              </div>-->
<!--              <hr/>-->
<!--              <div class="col-md-12">-->
<!--                <label class="control-label col-md-12 text-inverse text-left">Ihr Neues-->
<!--                  Passwort</label>-->
<!--                <input class="form-control" type="password" formControlName="newpass">-->
<!--                <span class="md-line"></span>-->
<!--              </div>-->
<!--              &lt;!&ndash;                <span class="text-danger ml-3" *ngIf="newpass.invalid && formDir.submitted">&ndash;&gt;-->
<!--              &lt;!&ndash;      Neues Passwort wird benötigt&ndash;&gt;-->
<!--              &lt;!&ndash;    </span>&ndash;&gt;-->
<!--              <div class="col-md-12">-->
<!--                <label class=" control-label col-md-12 text-inverse text-left">Ihr Neues Passwort-->
<!--                  (Wiederholung)</label>-->
<!--                <input class="form-control" type="password" formControlName="newpassvalidate">-->
<!--                <span class="md-line"></span>-->
<!--                &lt;!&ndash;                <span class="text-danger ml-3" *ngIf="newpass.invalid && formDir.submitted">&ndash;&gt;-->
<!--                &lt;!&ndash;      Neues Passwort wird benötigt&ndash;&gt;-->
<!--                &lt;!&ndash;    </span>&ndash;&gt;-->
<!--              </div>-->
<!--              &lt;!&ndash;              <div class="form-group row">&ndash;&gt;-->
<!--              &lt;!&ndash;                <label class=" control-label col-md-12">Neues Passwort</label>&ndash;&gt;-->
<!--              &lt;!&ndash;                <div class="col-md-12">&ndash;&gt;-->
<!--              &lt;!&ndash;                  <input class="form-control" type="password" formControlName="password">&ndash;&gt;-->
<!--              &lt;!&ndash;                </div>&ndash;&gt;-->
<!--              &lt;!&ndash;                <span class="text-danger ml-3" *ngIf="password.invalid && formDir.submitted">&ndash;&gt;-->

<!--              &lt;!&ndash;    </span>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              <div class="row m-t-30 ">-->
<!--                <div class="col-md-12">-->
<!--                  <button type="submit" class="btn btn-success float-right">Speichern</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </form>-->
<!--          &lt;!&ndash; order-card start &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--</div>-->
<!--</section>-->


<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <img src="assets/images/scc/logo.png" alt="" class="img-fluid">
        <h1 class="text-white my-4">Passwort zurücksetzen</h1>
        <h4 class="text-white font-weight-normal">Ändern Sie hier ihr Passwort, falls Sie es
          vergessen haben...</h4>
      </div>
    </div>
    <div class="auth-side-form">
      <form [formGroup]="form" (ngSubmit)="save()" #formDir="ngForm" novalidate class="md-float-material">
        <div class=" auth-content">
          <img src="assets/images/auth/auth-logo-dark.png" alt=""
               class="img-fluid mb-4 d-block d-xl-none d-lg-none">
          <h4 class="mb-3 f-w-400">Zurücksetzen</h4>
          <div class="input-group mb-4">
            <div class="input-group-prepend">
<!--              <span class="input-group-text"><i class="feather icon-mail"></i></span>-->
            </div>
            <label class="control-label col-md-12 text-inverse text-left">Ihr Neues
              Passwort</label>
            <input class="form-control" type="password" formControlName="newpass">
            <label class=" control-label col-md-12 text-inverse text-left">Ihr Neues Passwort
              (Wiederholung)</label>
            <input class="form-control" type="password" formControlName="newpassvalidate">
            <span class="md-line"></span>
          </div>
          <button class="btn btn-block btn-primary mb-4" type="submit">Passwort Reset</button>
<!--          <div class="text-center">-->
<!--            <div class="saprator my-4"><span>OR</span></div>-->
<!--            <button class="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle">-->
<!--              <i class="fab fa-facebook-f"></i></button>-->
<!--            <button-->
<!--              class="btn text-white bg-googleplus mb-2 mr-2 wid-40 px-0 hei-40 rounded-circle"><i-->
<!--              class="fab fa-google-plus-g"></i></button>-->
<!--            <button class="btn text-white bg-twitter mb-2  wid-40 px-0 hei-40 rounded-circle"><i-->
<!--              class="fab fa-twitter"></i></button>-->
<!--          </div>-->
        </div>
      </form>
    </div>
  </div>
</div>
