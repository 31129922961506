import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

export class User {
  constructor(
    public status: string,
  ) {
  }
}

export class JwtResponse {
  constructor(
    public jwttoken: string,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  authenticate(username, password) {
    return this.httpClient.post<any>(environment.settings.backend
      + '/authenticate', {username, password}).pipe(
      map(
        userData => {
          localStorage.setItem('username', username);
          const tokenStr = 'Bearer ' + userData.token;
          localStorage.setItem('token', tokenStr);
          return userData;
        }
      )
    )
  }

  isUserLoggedIn() {
    let user = localStorage.getItem('username');
    return !(user === null);
  }

  logOut() {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
  }

  getLoggedInUser(): string {
    let jwtElement = this.extractJSONFromJWTToken();
    if (jwtElement != null) {
      return jwtElement.sub;
    }
    return '---';
  }

  getLoggedInUserId(): number {
    let jwtElement = this.extractJSONFromJWTToken();
    if (jwtElement != null) {
      return jwtElement.uid;
    }
    return null;
  }

  getUserRoles(): string[] {
    return null;
  }

  hasUserRole(searchRole: string): boolean {
    /**
     *
     sub: "thorsten",
     exp: 1579008769,
     iat: 1578990769,
     rol: [
     "ROLE_ADMIN",
     "ROLE_EDITOR",
     "ROLE_VIEWER"
     ]
     */
    let jsonObj = this.extractJSONFromJWTToken();
    if (jsonObj != null && jsonObj.rol.indexOf(searchRole.toUpperCase()) > -1) {
      return true;
    } else {
      return false;
    }
  }

  extractJSONFromJWTToken(): any {
    const jwtToken = localStorage.getItem('token');
    if (jwtToken !== null && jwtToken !== '') {
      const jwtElement = atob(jwtToken.split('.')[1]);
      let tmpJson = null;
      if (jwtElement != null) {
        tmpJson = JSON.parse(jwtElement);
      }

      return tmpJson;
    }
    return null;
  }

}
