import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileinfoService {

  messageEmitter = new EventEmitter<string>();

  constructor() {

  }
}
