import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BundDetailComponent } from './bunddetail.component';
import {BundDetailRoutingModule} from './bunddetail-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { AuctiondetailsComponent } from './auction/auctiondetails/auctiondetails.component';
import { FocusableDirective} from '../../shared/components/editable-input/focusable.directive';
import { ViewModeDirective} from '../../shared/components/editable-input/view-mode.directive';
import { EditModeDirective} from '../../shared/components/editable-input/edit-mode.directive';
import { EditableOnEnterDirective} from '../../shared/components/editable-input/editable-on-enter.directive';
import {PellModule} from "angular-pell";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {CrystalLightboxModule} from "@crystalui/angular-lightbox";

@NgModule({
  imports: [
    CommonModule,
    BundDetailRoutingModule,
    SharedModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    FileUploadModule,
    PellModule,
    CKEditorModule,
    CrystalLightboxModule
  ],
  declarations: [BundDetailComponent, AuctiondetailsComponent, FocusableDirective, ViewModeDirective, EditModeDirective, EditableOnEnterDirective]
})
export class BundDetailModule { }
