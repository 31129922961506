import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../../sccshared/authentication/authentication.service';
import {FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {

  model: any = {};
  isError: boolean;
  loading: boolean;

  returnUrl: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authService: AuthenticationService,
    private toastr: ToastrService
  ) {
    this.isError = false;
    this.loading = false;
  }

  ngOnInit() {
    localStorage.setItem('token', '');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  hasError(): boolean {
    return this.isError;
  }
  login() {
    this.authService.authenticate(this.model.username, this.model.password).subscribe(
      isValid => {
          if (isValid) {
            localStorage.setItem('username', this.model.username);
            // @ts-ignore
            const tokenStr = 'Bearer ' + isValid.token;
            localStorage.setItem('token', tokenStr);

            // this.router.navigate(['']);
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.isError = true;
          }}, error => {
        if (error.status === 401) {
          this.isError = true;
          this.toastr.error('Bitte überprüfen Sie die eingegebenen Zugangsdaten', 'Loginproblem');
        }
      }
    );
    // let url = 'http://localhost:8080/authenticate';
    // this.http.post<Observable<boolean>>(url, {
    //   username: this.model.username,
    //   password: this.model.password
    // }).subscribe(isValid => {
    //   if (isValid) {
    //     localStorage.setItem('token', btoa(this.model.username + ':' + this.model.password));
    //     this.router.navigate(['']);
    //   } else {
    //     alert("Authentication failed.")
    //   }
    // });
  }
}
