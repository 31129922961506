<div class="auth-wrapper align-items-stretch aut-bg-img">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <img src="assets/images/scc/logo.png" alt="" class="img-fluid">
        <h1 class="text-white my-4">Passwort vergessen?</h1>
        <h4 class="text-white font-weight-normal">Hier können Sie ein neues Passwort anfordern.</h4>
      </div>
    </div>
    <div class="auth-side-form">
      <form [formGroup]="form" (ngSubmit)="save()" #formDir="ngForm" novalidate
            class="md-float-material">
        <div class=" auth-content">
          <img src="assets/images/auth/auth-logo-dark.png" alt=""
               class="img-fluid mb-4 d-block d-xl-none d-lg-none">
          <div class="input-group mb-4">
            <h4 class="mb-4 f-w-400">Passwort zurücksetzen</h4>
            <div class="input-group mb-2">
              <label class="control-label col-md-12 text-inverse text-left">Ihre E-Mail-Adresse
              </label>
              <input class="form-control" type="text" formControlName="usermail">
              <span class="md-line"></span>
            </div>
          </div>
          <button class="btn btn-block btn-primary mb-4">Absenden</button>
        </div>
      </form>
    </div>
  </div>
</div>
