import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';

import {SccService} from '../../scc.service';

import {FormControl} from '@angular/forms';
import {Page} from '../../model/page';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {Searchbundlistresponse} from '../../model/searchbundlistresponse';
import {DataTableDirective} from 'angular-datatables';
import {Bund} from '../../model/implementation';
import {AuthenticationService} from '../../sccshared/authentication/authentication.service';
import {VerbandslisteComponent} from "../verbandsliste/verbandsliste.component";

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {
  @ViewChild('farbenT') public farbenT: TemplateRef<any>;
  @ViewChild('idT') public idT: TemplateRef<any>;
  @ViewChild('verbindungsnameT') public verbindungsnameT: TemplateRef<any>;

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  // buende: Bund[];

  env = environment;
  loadingIndicator = true;
  browseComponent = new FormControl('');
  alleBuende = new Array<Bund>();

  aktiverBund: Bund;

  page = new Page();

  columns = [];

  submitted = false;
  model = String();
  suchwort = new FormControl('');

  farben = new FormControl('');
  ort = new FormControl('');
  sccid = new FormControl('');
  gdjahr = new FormControl('');
  wahlspruch = new FormControl('');
  nuraktiv = new FormControl('');
  verbandsfilter = new FormControl('');

  onSubmit() {
    this.submitted = true;
  }

  get diagnostic() {
    return JSON.stringify(this.model);
  }

  constructor(private sccService: SccService, private router: Router, private activatedRoute: ActivatedRoute, private authenticationService: AuthenticationService) {
    this.page.pageNumber = 0;
    this.page.size = 20;
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe();
    this.activatedRoute.queryParamMap.subscribe(
      paramsWeb => {
        if (paramsWeb.get('bundsearch') != null ||
          paramsWeb.get('ort') != null ||
          paramsWeb.get('farben') != null ||
          paramsWeb.get('gdjahr') != null ||
          paramsWeb.get('sccid') != null ||
          paramsWeb.get('wahlspruch') != null ||
          paramsWeb.get('childrenVerband') != null ||
          paramsWeb.get('nuraktiv')
        ) {
          this.findBundByFilter(paramsWeb.get('bundsearch'), paramsWeb.get('farben'), paramsWeb.get('ort'),
            paramsWeb.get('sccid'), paramsWeb.get('gdjahr'), paramsWeb.get('wahlspruch'), paramsWeb.get('nuraktiv'), paramsWeb.get('childrenVerband'), {offset: 0});
        }
        this.suchwort.setValue(paramsWeb.get('bundsearch'));
        this.ort.setValue(paramsWeb.get('ort'));
        this.farben.setValue(paramsWeb.get('farben'));
        this.gdjahr.setValue(paramsWeb.get('gdjahr'));
        this.sccid.setValue(paramsWeb.get('sccid'));
        this.wahlspruch.setValue(paramsWeb.get('wahlspruch'));
        this.nuraktiv.setValue((paramsWeb.get('nuraktiv') === 'true'));
        // this.nuraktiv.setValue(paramsWeb.get('nuraktiv'));
        this.verbandsfilter.setValue(paramsWeb.get('childrenVerband'));
      }
    );

    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 20,
      serverSide: true,
      processing: true,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        that.searchForBund(this.suchwort.value, this.farben.value, this.ort.value, this.sccid.value, this.gdjahr.value, this.wahlspruch.value, this.nuraktiv.value, this.verbandsfilter.value, {
          page: (dataTablesParameters.start / dataTablesParameters.length),
          size: dataTablesParameters.length
        }).subscribe(resp => {
          that.alleBuende = resp.content;

          // this.dtTrigger.next();
          // console.log('RESPONSE: {}', resp);

          callback({
            recordsTotal: resp.totalElements,
            recordsFiltered: resp.totalElements,
            data: []
          });
        });
      },
      columns: [{data: 'id'}, {data: 'verbindungsname'}, {data: 'ort'}, {data: 'sccId'}, {data: 'gruendungsDatum'}, {data: 'farben'}, {data: 'wahlspruch'}],
      columnDefs: [{
        targets: [0, 1, 2, 3], width: '80px'
      }, {targets: [4, 5, 6, 7], width: '100px'}]
    };


    // this.columns = [
    //   {prop: 'verbindungId', cellTemplate: this.verbindungIdT},
    //   {name: 'verbindungsname', cellTemplate: this.verbindungsnameT},
    //   {name: 'ort'},
    //   {name: 'sccId'},
    //   {prop: 'farben', name: 'Couleur/Farben', cellTemplate: this.farbenT}];

    // console.log('Environment: ', this.env);

  }

  getRenderedColors(colorInput: string): string {
    return this.sccService.getRenderedColors(colorInput);
  }

  // getBuende(pageNo): void {
  //   this.sccService.getBuende().subscribe((data) => {
  //     console.log(data);
  //     this.page.pageNumber = data.pageNumber;
  //     this.page.size = data.size;
  //     this.page.totalElements = data.totalElements;
  //     this.page.totalPages = data.totalPages;
  //     this.alleBuende = data.content;
  //     this.alleBuende = [...this.alleBuende];
  //     console.log('total: ', this.page);
  //     console.log(this.alleBuende);
  //   });
  // }
  userIsAdmin(): boolean {
    return this.authenticationService.hasUserRole('ROLE_ADMIN');
  }

  search() {
    this.router.navigate(['/search-page'], {
      queryParams: {
        bundsearch: this.suchwort.value,
        farben: this.farben.value,
        ort: this.ort.value,
        sccid: this.sccid.value,
        gdjahr: this.gdjahr.value,
        wahlspruch: this.wahlspruch.value,
        nuraktiv: this.nuraktiv.value,
        verbandid: this.verbandsfilter.value
      }
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
    this.searchForBund(this.suchwort.value, this.farben.value, this.ort.value, this.sccid.value, this.gdjahr.value, this.wahlspruch.value, this.nuraktiv.value, this.verbandsfilter.value, {page: 1});
  }

  // tslint:disable-next-line:max-line-length
  searchForBund(suchbegriff: string, farben: string, ort: string, sccid: string, gdJahr: string, wahlspruch: string, nuraktiv: string, verbandsfilter: string, pageNo): Observable<Searchbundlistresponse> {
    return this.sccService.getBuendeWithNameFilterAjax(suchbegriff, farben, ort, sccid, gdJahr, wahlspruch, nuraktiv, verbandsfilter, pageNo);
  }

  findBundByFilter(suchbegriff: string, farben: string, ort: string, sccid: string, gdJahr: string, wahlspruch: string, nuraktiv: string, verbandFilter: string, pageNo): void {
    this.sccService.getBuendeWithNameFilter(suchbegriff, farben, ort, sccid, gdJahr, wahlspruch, nuraktiv, verbandFilter, pageNo).subscribe(data => {
      // console.log('antwort: ', data);
      // this.page.pageNumber = data.pageable.pageNumber;
      // this.page.size = data.size;
      // this.page.totalElements = data.totalElements;
      // this.page.totalPages = data.totalPages;

      // this.alleBuende = data.content;
      // console.log('total: ', this.page);


      /**
       * pageable:
       * offset: 0
       * pageNumber: 0
       * pageSize: 20
       * paged: true
       * sort: {sorted: false, unsorted: true, empty: true}
       * unpaged: false
       */

    });
    // console.log('foo ' + suchbegriff);
  }

  onDemo(blubb: any): void {
    console.log(blubb);
  }

  onSelect(bund: Bund): void {
    this.aktiverBund = bund;
    console.log(bund);
  }

  onActivate($event: any) {
    if ($event.type === 'click') {
      // console.log($event.row);
      const navigationExtras: NavigationExtras = {
        queryParams: {
          limitDachverband: false
        }
      };

      const param1 = $event.row.id;
      this.router.navigate(['bunddetail', param1], navigationExtras);
    }
  }
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
