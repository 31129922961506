import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SccService} from '../../scc.service';
import {
  Bund,
  BundComment,
  BundContent,
  MediaData,
  MetaDataBund, Verband,
  Wappen,
  Zirkel
} from '../../model/implementation';
import {HttpClient} from '@angular/common/http';
import {FileUploadValidators} from '@iplab/ngx-file-upload';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../sccshared/authentication/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {CommentDTOModel} from '../../model/commentDTO.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ChangeEvent} from '@ckeditor/ckeditor5-angular';


@Component({
  selector: 'app-bund-detail',
  templateUrl: './bunddetail.component.html',
  styleUrls: ['./bunddetail.component.scss']
})
export class BundDetailComponent implements OnInit, AfterViewInit {
  pendingNetwork: boolean;
  bundContentCount: number;

  detailControls: FormGroup;

  public Editor = ClassicEditor;
  // wenn man alle anzeigen will, die in den Editor eincompiliert wurden, dass hier in initialization:
  // const allPlugins = this.Editor.builtinPlugins;
  // for (const onePlug of allPlugins) {
  //   console.log(onePlug.pluginName);
  // }
  // https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/configuration.html#removing-features

  editModeIdxField: number;

  inputField: string;

  public formDataPreview?: string;


  @Input() aktiverBund: Bund;
  @Input() einzelZirkel: Zirkel[];
  @Input() allMedia: MediaData[];
  @Input() bundContent: BundContent[];
  @Input() wappenListe: Wappen[];
  listencontent: BundContent[];
  bundMetaListe: MetaDataBund[];
  alleSCCIDBuende: Bund[];
  verbandsteile: Verband[];

  currentBundHistory: Bund[];
  public imageAlbum: any;
  public config: any;

  @ViewChild('bundContentZuordnen') bundModal;
  @ViewChild('bundHistory') bundHistoryModal;
  @ViewChild('bundMetaDatazuordnen') bundMetaDataModal;

  @ViewChild('bundcontentListe') bundcontentListeModal;

  newCommentValue: '';
  // accept = '*';
  // files: File[] = [];
  // progress: number;
  // // url = 'https://evening-anchorage-3159.herokuapp.com/api/'
  // url = 'https://localhost:8080/api/...';
  // hasBaseDropZoneOver = false;
  // httpEmitter: Subscription;
  // httpEvent: HttpEvent<{}>;
  // lastFileAt: Date;

  allComments: BundComment[];

  sendableFormData: FormData; // populated via ngfFormData directive

  dragFiles: any;
  validComboDrag: any;
  lastInvalids: any;
  fileDropDisabled: any;
  maxSize: any;
  baseDropValid: any;

  // --- Upload Form ---
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
  // private filesControl = new FormControl(null, FileUploadValidators.filesLimit(5));
  private storedFileSize = 0;
  // private filesControl = new FileUploadControl(FileUploadValidators.filesLimit(5));
  public imageUploadForm = new FormGroup({
    files: this.filesControl,
    fileComments: new FormArray([])
  });

  // Link Form
  urlLinkSubmitted: boolean;
  urlLinkForm: FormGroup;

  // uploadFiles(): SubscriptionSubscription {
  uploadList: File[];

  mediatypes: string[];

  public beschreibungForm = new FormGroup( {
    description: new FormControl( '<p>A <b>really</b> nice fellow.</p>' )
  });
  // onActivate($event: any) {
  //   if ($event.type === 'click') {
  //     console.log($event.row);
  //     const navigationExtras: NavigationExtras = {
  //       queryParams: {
  //         limitDachverband: false
  //       }
  //     };
  //
  //     const param1 = $event.row.verbindungId;
  //     this.router.navigate(['bunddetail', param1], navigationExtras);
  //   }
  // }
  formEditmode: boolean;


  constructor(private route: ActivatedRoute, private sccService: SccService,
              public httpClient: HttpClient, private formBuilder: FormBuilder,
              private authService: AuthenticationService,
              private toastr: ToastrService) {
    this.route.params.subscribe(params => this.fetchBund(params.id));
    this.route.params.subscribe(params => this.getZirkelForBund(params.id));
    this.route.params.subscribe(params => this.getBinaryDataForMediaForBund(params.id));
    this.route.params.subscribe(params => this.fetchBundContent(params.id));
    this.route.params.subscribe(params => this.getWappenInfos(params.id));
    this.route.params.subscribe(params => this.getAllBuendeForSCCId(params.id));
    this.route.params.subscribe(params => this.getCountForBundContentOfSCCID(params.id));
    // this.route.params.subscribe(params => this.getVerbandslisteForBund(params.id));
    this.editModeIdxField = -1;
  }

  public ngAfterViewInit() {
    // tslint:disable-next-line:no-non-null-assertion
    this.beschreibungForm!.valueChanges
      .subscribe( values => {
        this.formDataPreview = JSON.stringify( values );
      } );

  }

  get linkForm() {
    return this.urlLinkForm.controls;
  }

  get fileComments(): FormArray {
    return this.imageUploadForm.get('fileComments') as FormArray;
  }

  public get description(): AbstractControl {
    return this.beschreibungForm!.controls.description;
  }

  ngOnInit() {

    // init form fields for in place editor
    this.detailControls = new FormGroup({
        fuxenband: new FormControl('', null),
        farben: new FormControl('', null),
        gruendungsDatum: new FormControl('', null),
        homepage: new FormControl('', null),
        telefonNummer: new FormControl('', null),
        adresseHaus: new FormControl('', null),
        plzHaus: new FormControl('', null),
        ortHaus: new FormControl('', null)
      }
    );
    // end init

    this.getMediaTypesForDropdown();

    this.imageUploadForm.get('files').statusChanges.subscribe(x => {
      if (this.filesControl.value.length !== this.fileComments.length) {
        // delete
        if (this.filesControl.value.length < this.fileComments.length) {

        }
        // add
        if (this.filesControl.value.length > this.fileComments.length) {
          this.addUploadComment();
        }
      }
    });

    this.urlLinkForm = this.formBuilder.group({
      linkAdresse: ['', Validators.required],
      linkKommentar: ['', Validators.required],
    }, {});
    // console.log('Link-Form:', this.urlLinkForm);
  }

  addUploadComment() {
    this.fileComments.push(this.newFileComment());
  }

  newFileComment(): FormGroup {
    return new FormGroup({
      filecomment: new FormControl(''),
      fileType: new FormControl('')
    });
  }

  // public toggleStatus() {
  //   this.filesControl.disabled ? this.filesControl.enable() : this.filesControl.disable();
  // }

  public changeEventOnFileUploadList() {
    // leftover
  }

  public removeFileFromList(datei: File) {
    // console.log('remove {}', datei);
    let idx = 0;
    for (const entry of this.filesControl.value) {
      if (entry === datei) {
        // console.log('removing at position {}', idx);
        this.fileComments.removeAt(idx);
      }
      idx++;
    }

  }


  // Actions for URL Link Form (tab2)
  onSubmitURLLink() {
    this.urlLinkSubmitted = true;
    if (this.urlLinkForm.invalid) {
      return;
    }
    this.pendingNetwork = true;
    this.sccService.pushURLUpload(this.aktiverBund.id.toString(), this.urlLinkForm.value.linkAdresse, this.urlLinkForm.value.linkKommentar)
    .subscribe((data) => {
      // console.log('response: {}', data);
      this.pendingNetwork = false;
      this.toastr.info('Datei ist ermittelt und wurde runtergeladen.', 'Link - Erfolg');
      this.getBinaryDataForMediaForBund(this.aktiverBund.id);
    }, error => {
      console.log('error occured {}', error);
      this.pendingNetwork = false;
      this.toastr.error('Es ist ein Fehler aufgetreten. Bitte Technik informieren oder erneut probieren.', 'Fehler');
    });
    // console.log(this.urlLinkForm.value);
  }

  onReset() {
    this.urlLinkSubmitted = false;
    this.urlLinkForm.reset();
  }

  // Rest...


  fetchBund(bundId: number) {

    this.sccService.getEinzelBund(bundId).subscribe((data) => {
      this.aktiverBund = data;
      Object.keys(this.detailControls.controls).forEach((key) => {
        // this.detailControls.get(key).setValidators((this.aktiverBund[key]));
        this.detailControls.get(key).setValue((this.aktiverBund[key]));

      });

      // this.detailControls.get('fuxenband').setValue(this.aktiverBund.fuxenband);
      this.loadComments();
      this.getVerbandslisteForBund(this.aktiverBund.verband.id);
    });
  }

  fetchBundContent(bundId: number): void {
    this.sccService.getBundContent(bundId).subscribe((data) => {
      // console.log('fetchContent:', data);
      this.bundContent = data.content;
    });
  }

  getZirkelForBund(bundId: number): void {
    // console.log('getting Zirkel for ', bundId);
    this.sccService.getZirkelForBund(bundId).subscribe((data) => {
      // console.log('Zirkeldata:', data);
      if (data != null && data) {
        this.einzelZirkel = data as Zirkel[];
      } else {
        this.einzelZirkel = null;
      }
    });
  }

  getVerbandslisteForBund(verbandId: number): void {
    this.sccService.getVerbandsSistersForVerband(null, verbandId).subscribe((data) => {
      if (data != null && data) {
        this.verbandsteile = data as Verband[];
      } else {
        this.verbandsteile = null;
      }
    });
  }

  getBinaryDataForZirkel(contentId: string) {
    return this.sccService.getAbsoluteImageURI(contentId);
  }

  getBinaryMediaDataForElement(contentId: string) {
    return this.sccService.getAbsoluteMediaURI(contentId);
  }

  getBinaryDataForMediaForBund(bundId: number) {
    console.log('getting Binary List for ', bundId);
    this.sccService.getMediaForBund(bundId).subscribe((data) => {
      // console.log('Mediadata:', data);
      this.allMedia = data as MediaData[];
      // this
    });
  }

  getWappenInfos(bundId: number): void {
    this.sccService.getWappenlisteForBund(bundId).subscribe((data: any[]) => {
      // console.log('WappenListe: ', data);
      // @ts-ignore
      this.wappenListe = data.content;
    });
  }

  getLinkForWord(bundId: number) {
    if (this.allMedia !== undefined && this.allMedia !== null && this.allMedia && Object.keys(this.allMedia).length > 0) {

      for (const mediaElement of this.allMedia) {
        if (!mediaElement.mimeType.startsWith('image')) {
          return this.sccService.getAbsoluteMediaURI(mediaElement.contentId);
        }
      }
    }

  }

  getFarben(farbenInput: string): string {
    return this.sccService.getRenderedColors(farbenInput);
  }

  public isEmpty(myVar): boolean {
    return (myVar && (Object.keys(myVar).length === 0));
  }

  convertToHTML(content: string) {
    // console.log('content ist ', content);
    return content.replace(new RegExp('\n', 'g'), '<br />');
  }

  updateData() {
    this.sccService.saveData(this.aktiverBund).subscribe((response) => {
      this.aktiverBund = response;
    });
  }

  updateBundContentData(bundContentToBeUpdated: BundContent, bund: Bund) {
    // console.log('updating... {}', bund.sccId);
    bundContentToBeUpdated.bund = bund;
    bundContentToBeUpdated.basisMapping = false;
    // this.sccService.saveData(this.aktiverBund);
    this.sccService.updateBundContent(bundContentToBeUpdated, bund).subscribe();
    this.bundModal.hide();
    this.toastr.success('Zuordnung aktualisiert', 'BundContent');
  }


  linkBundContent() {
    this.bundModal.show();
    this.sccService.getBundContentFor(this.aktiverBund.sccId).subscribe((data) => {
      // console.log('---> {}', data);
      this.listencontent = data;
    });
  }

  linkBundMetaData() {
    this.bundMetaDataModal.show();
    this.sccService.getMetaDataBundFor(this.aktiverBund.sccId).subscribe((data) => {
      this.bundMetaListe = data;
    });
  }

  uploadFiles(): void {
    if (this.filesControl.value != null) {
      this.uploadList = this.filesControl.value;
      this.pendingNetwork = true;
      let idx = 0;
      for (const uploadElement of this.uploadList) {
        const fmd = this.fileComments.value[idx];
        this.sccService.uploadMediaToBund(uploadElement, this.aktiverBund.id.toString(), fmd.filecomment, fmd.fileType).subscribe(data => {
          if (data === 'success') {
            this.filesControl.setValue([]);
            this.pendingNetwork = false;
            this.getBinaryDataForMediaForBund(this.aktiverBund.id);
            this.toastr.info('Alle Dateien wurden erfolgreich hochgeladen.', 'Upload komplett');
          }
        }, error => {
          this.toastr.error('Es gab ein Problem mit dem Upload.', 'Fehler beim Upload');
        });
        // const req = new HttpRequest<FormData>(
        //   'POST',
        //   this.url,
        //   this.sendableFormData, {
        //     reportProgress: true// , responseType: 'text'
        //   });

        // return this.httpEmitter = this.HttpClient.request(req)
        // .subscribe(
        //   event => {
        //     this.httpEvent = event;
        //
        //     if (event instanceof HttpResponse) {
        //       delete this.httpEmitter;
        //       console.log('request done', event);
        //     }
        //   },
        //   error => alert('Error Uploading Files: ' + error.message)
        // );
        //
        idx++;
      }
    }

  }

  getDate() {
    return new Date();
  }

  addComment() {
    // @ts-ignore
    const bc: CommentDTOModel = {
      bundId: this.aktiverBund.id,
      comment: this.newCommentValue,
    };
    this.sccService.addBundComment(bc).subscribe(resp => {
      this.loadComments();
    });

  }

  loadComments() {
    // console.log('bund: {}', this.aktiverBund);
    this.sccService.getAllComments(this.aktiverBund.id).subscribe(
      (data) => {
        // console.log('---> {}', data);
        this.allComments = data.content;
      }
    );
  }

  getMediaTypesForDropdown(): void {
    this.sccService.getAllMediaTypes().subscribe((data => {
        this.mediatypes = data;
      }
    ));
  }

  isAdmin(): boolean {
    return this.authService.hasUserRole('ROLE_ADMIN');
  }

  isEditor(): boolean {
    let result = false;
    if (this.authService.hasUserRole('ROLE_EDITOR')) {
      result = true;
    }
    if (this.authService.hasUserRole('ROLE_ADMIN')) {
      result = true;
    }
    return result;
  }

  deleteEntry(mediaElement: MediaData) {
    return this.sccService.deleteMediaDataEntry(mediaElement.id.toString()).subscribe((data) => {
      this.toastr.success('Gelöscht', 'Löscherfolg');
      this.getBinaryDataForMediaForBund(this.aktiverBund.id);
    }, error => {
      this.toastr.error('Problem beim Löschen. Error:' + error.status, 'ERROR');
    });
  }

  getAllBuendeForSCCId(id: string) {
    return this.sccService.getBuendeById(id).subscribe((data) => {
      this.alleSCCIDBuende = data.content;
      // console.log(data);
    });
  }

  getCountForBundContentOfSCCID(sccid: string) {
    this.sccService.getCountBundContentForSCCID(sccid).subscribe((data) => {
      this.bundContentCount = Number(data).valueOf();
    });
  }

  viewAllContentForSCCID() {

  }

  updateMetaDataEntryForBund(metaEntry: MetaDataBund, targetBund: Bund) {
    metaEntry.bund = targetBund;
    this.sccService.updateMetaDataBund(metaEntry).subscribe();
    this.bundMetaDataModal.hide();
    this.toastr.success('Zuordnung aktualisiert', 'MetaDataBund');

  }

  // -- in place editor krams --

  getControl(field: string): FormControl {
    return this.detailControls.get(field) as FormControl;
  }

  cancelField(field: string) {
    this.getControl(field).setValue(this.aktiverBund[field]);
  }

  updateField(field: string) {
    const control = this.getControl(field);

    if (control.valid && control.value !== this.aktiverBund[field]) {
      this.aktiverBund[field] = control.value;
      this.updateData();
      // this.entities = this.entities.map((e, i) => {
      //   if (index === i) {
      //     return {
      //       ...e,
      //       [field]: control.value
      //     }
      //   }
      //   return e;
      // })
    }

  }

  unfocusField(fieldname: string) {

  }

  hasContent(targetField: string) {
    return (this.aktiverBund[targetField] === undefined || this.aktiverBund[targetField] === null || this.aktiverBund[targetField] === '');
  }

  checkInput($event: Event) {
    // console.log('huhu', $event);

  }

  editModeDetail(detailNo: number, bundText: BundContent) {
    this.inputField = this.convertToHTML(bundText.textInhalt);
    // console.log('editing stammdateen', detailNo);
    this.beschreibungForm.get('description').setValue(this.convertToHTML(bundText.textInhalt));
    this.editModeIdxField = detailNo;
  }

  updateOrCancelEditModeDetail(cancel: boolean) {
    if (cancel === true) {
       this.editModeIdxField = -1;
    } else {
      this.bundContent[this.editModeIdxField].textInhalt = this.beschreibungForm.value.description;
      this.sccService.updateBundContent(this.bundContent[this.editModeIdxField]).subscribe((res) => {
        this.toastr.info('Text aktualisiert.', 'Gespeichert');
      });
      this.onBeschreibungSubmit();
      this.editModeIdxField = -1;
    }
  }

  onChangeContent($event: ChangeEvent) {
    // console.log($event.editor.data);
  }

  onBeschreibungSubmit(): void {
    // console.log('Formularinhalt: {} - {}', this.beschreibungForm.value.description, this.editModeIdxField);

  }

  showEntryHistory() {
    // hier Daten von _audit holen
    this.sccService.getAuditForBund(this.aktiverBund.id.toString()).subscribe( (data) => {
      this.bundHistoryModal.show();
      this.currentBundHistory = data;
    });
    // und anzeigen in einem Popup

  }
}
