import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../../sccshared/authentication/authentication.service';

@Component({
  selector: 'logout',
  template: '<div></div>'
})

export class LogoutComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.authService.logOut();
    this.router.navigate(['']);
  }
}
