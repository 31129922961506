import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerbandslisteComponent } from './verbandsliste.component';
import {VerbandslisteRoutingModule} from './verbandsliste-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataTablesModule} from 'angular-datatables';
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        CommonModule,
        VerbandslisteRoutingModule,
        SharedModule,
        NgxDatatableModule,
        ReactiveFormsModule,
        FormsModule,
        DataTablesModule,
        NgbTooltipModule
    ],
  declarations: [VerbandslisteComponent]
})
export class VerbandslisteModule { }
