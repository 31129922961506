import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {SccService} from '../../scc.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Bund, BundComment, SCCNews} from "../../model/implementation";
import {Observable} from "rxjs";

@Component({
  selector: 'app-startseite',
  templateUrl: './startseite.component.html',
  styleUrls: ['./startseite.component.scss']
})
export class StartseiteComponent implements OnInit {

  bundcontentOrphan = 0;
  metadataBundOrphan = 0;
  wappenOrphan = 0;
  allNews = Array<SCCNews>();

  lastComments = Array<BundComment>();
  lastEdits = Array<Bund>();

  constructor(private route: ActivatedRoute, private router: Router, private sccService: SccService) {

  }

  ngOnInit() {
    this.sccService.getBundContentOrphanCount().subscribe((result) => {
      this.bundcontentOrphan = result;
    });
    this.sccService.getMetaDataBundOrphanCount().subscribe((result) => {
      this.metadataBundOrphan = result;
    });
    this.sccService.getWappenOrphanCount().subscribe((result) => {
      this.wappenOrphan = result;
    });
    this.sccService.getAllNews().subscribe((result) => {
      this.allNews = result;
    });
    this.getLast5Comments();
    this.getLast5BundEdits();
  }

  getBundContentOrphans(): number {
    return this.bundcontentOrphan;
  }

  getWappenOrphans(): number {
    return this.wappenOrphan;
  }

  getMetaDataBundOrphans(): number {
    return this.metadataBundOrphan;
  }

  getLast5Comments(): void {
    this.sccService.getLast5Comments().subscribe((data) => {
      // console.log(data);
      this.lastComments = data;
    });
  }
  getLast5BundEdits(): void {
    this.sccService.getLast5ChangesBund().subscribe((data) => {
      this.lastEdits = data;
    });
  }
  jumpToMetaDataBund() {
    this.router.navigate(['orphan-bundmetadata']);
  }

  jumpToWappen() {
    this.router.navigate(['orphan-wappen']);
  }

  jumpToBundContent() {
    this.router.navigate(['orphan-bundcontent']);
  }

  getPillClass(newsTypeEnum: "HOT" | "CHANGES" | "PERSONAL" | "NEWS" | "ADVERTS") {
    if (newsTypeEnum === "NEWS") {
      return {'btn-primary': true};
    }
    if (newsTypeEnum === "PERSONAL") {
      return {'btn-dark': true};
    }
    if (newsTypeEnum === "ADVERTS") {
      return {'btn-danger': true};
    }
    if (newsTypeEnum === "HOT") {
      return {'btn-success': true};
    }
    if (newsTypeEnum === "CHANGES") {
      return {'btn-secondary': true};
    }

    return {'btn-info': true};
  }

}


@Pipe({
  name: 'bundcontentorphan'
})
export class BundContentOprhanPipe implements PipeTransform {
  transform(): any {

  }
}
