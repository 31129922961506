import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'startseite',
        title: 'Portalseite',
        type: 'item',
        url: '/startseite',
        icon: 'feather icon-anchor'
      },
      {
        id: 'suchseite',
        title: 'Suchseite',
        type: 'item',
        url: '/search-page',
        icon: 'feather icon-sun'
      },
      {
        id: 'zirkelsuche',
        title: 'Zirkelsuche',
        type: 'item',
        url: '/zirkelliste',
        icon: 'feather icon-tablet'
      },
      {
        id: 'wappenliste',
        title: 'Wappenliste',
        type: 'item',
        url: '/wappenliste',
        icon: 'feather icon-triangle'
      },
      {
        id: 'verbandsliste',
        title: 'Verbandsliste',
        type: 'item',
        url: '/verbandsliste',
        icon: 'feather icon-user-check'
      }
    ]
  },
  {
    id: 'support',
    title: 'Support',
    type: 'group',
    icon: 'feather icon-help-circle',
    children: [
      {
        id: 'documentation',
        title: 'Dokumentation',
        type: 'item',
        icon: 'feather icon-book',
        classes: 'nav-item',
        url: '/docs',
        target: false,
        external: false
      },
      {
        id: 'need-support',
        title: 'Hilfe benötigt?',
        type: 'item',
        icon: 'feather icon-help-circle',
        classes: 'nav-item',
        url: 'mailto:info@sccdb.de',
        target: true,
        external: true,
        badge: {
          title: 'v1.0',
          type: 'badge-primary'
        }
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
