import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SccService} from '../../scc.service';
import {FormControl} from '@angular/forms';
import {Page} from '../../model/page';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Bund, BundContent, Zirkel} from '../../model/implementation';
import {DataTableDirective} from 'angular-datatables';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-singlezirkellist',
  templateUrl: './singlezirkellist.component.html',
  styleUrls: ['./singlezirkellist.component.scss']
})
export class SinglezirkellistComponent implements OnInit {

  //
  // @ViewChild('farbenT') public farbenT: TemplateRef<any>;
  // @ViewChild('idT') public idT: TemplateRef<any>;
  // @ViewChild('verbindungsnameT') public verbindungsnameT: TemplateRef<any>;

  env = environment;
  // loadingIndicator = true;
  // browseComponent = new FormControl('');
  // alleBuende = new Array<Bund>();
  //
  // aktiverBund: Bund;
  // suchbund: String;

  page = new Page();

  columns = [];

  submitted = false;
  model = String();
  suchwort = new FormControl('');

  farben = new FormControl('');
  ort = new FormControl('');
  sccid = new FormControl('');
  gdjahr = new FormControl('');

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  zirkelliste = new Array<Zirkel>();
  private searchPrefix: string;

  private iterZirkel: string;
  private zirkelgroupId: string;

  onSubmit() {
    this.submitted = true;
  }

  get diagnostic() {
    return JSON.stringify(this.model);
  }

  constructor(private sccService: SccService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.page.pageNumber = 0;
    this.page.size = 20;
  }

  ngOnInit() {
    // this.activatedRoute.paramMap.subscribe();
    this.activatedRoute.params.subscribe(params => {
      this.zirkelgroupId = params.id;
    });
    this.activatedRoute.queryParamMap.subscribe(
      paramsWeb => {
        if (
          paramsWeb.get('zirkelPrefix') != null
        ) {
          // this.sccid = paramsWeb.get('sccid');
          if (paramsWeb.get('farben') !== null) {
            this.farben.setValue(paramsWeb.get('farben'));
          }
          // this.findBundByFilter(paramsWeb.get('bundsearch'), paramsWeb.get('farben'), paramsWeb.get('ort'),
          //   paramsWeb.get('sccid'), paramsWeb.get('gdjahr'), {offset: 0});
        }
        if (paramsWeb.get('page') !== null) {
          this.page.pageNumber = Number(paramsWeb.get('page'));
        } else {
          this.page.pageNumber = 0;
        }
      }
    );

    const that = this;
    this.dtOptions = {
      language: {
        url: '/assets/i18n/datatable_de.json'
      },
      pagingType: 'full_numbers',
      lengthMenu: [20],
      lengthChange: false,
      pageLength: this.page.size,
      displayStart: (this.page.pageNumber * this.page.size),
      serverSide: true,
      processing: true,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        that.sccService.getAllMatchingZirkel({
          page: (dataTablesParameters.start / dataTablesParameters.length),
          size: dataTablesParameters.length
        }, this.searchPrefix, this.zirkelgroupId, this.farben.value, this.sccid.value).subscribe((data) => {
          this.router.navigate([],
            {
              relativeTo: this.activatedRoute,
              queryParams: {page: (dataTablesParameters.start / dataTablesParameters.length)},
              queryParamsHandling: 'merge'
            });
          callback({
            recordsTotal: data.totalElements,
            recordsFiltered: data.totalElements,
            data: []
          });

          this.zirkelliste = [...data.content];
          this.page.pageNumber = data.pageable.pageNumber;
          this.page.size = data.pageable.pageSize;
          this.page.totalElements = data.totalElements;
          this.page.totalPages = data.totalPages;

        });
      },
      columns: [{data: 'id'}, {data: 'bundName'}, {data: 'sccid'}, {data: 'pages'}, {data: 'sourceFileName'}],
      columnDefs: [
        {targets: [0, 1, 2, 3, 4], width: '80px'}
      ]
    };

    // this.sccService.getAllZirkelTypes().subscribe(
    //   data => {
    //     console.log('tataaaa');
    //     console.log(data);
    //   }
    // );
  }

  getRenderedColors(colorInput: string): string {
    return this.sccService.getRenderedColors(colorInput);
  }

  search() {
    this.router.navigate(['/singlezirkellist/' + this.zirkelgroupId], {
      queryParams: {
        farben: this.farben.value,
        sccid: this.sccid.value,
      }
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });

  }

  getBinaryDataForZirkel(contentId: string) {
    return this.sccService.getAbsoluteImageURI(contentId);
  }


}
