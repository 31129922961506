import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Bund} from '../../model/implementation';
import {SccService} from '../../scc.service';
import {FormControl} from '@angular/forms';
import {Page} from '../../model/page';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {BundWrapper} from '../../model/bundwrapper.model';
import {BundContent} from '../../model/implementation';
import {Observable} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';


@Component({
  selector: 'app-orphan-wappen-page',
  templateUrl: './orphan-bundcontent.component.html',
  styleUrls: ['./orphan-bundcontent.component.scss']
})
export class OrphanBundcontentComponent implements OnInit {
  @ViewChild('farbenT') public farbenT: TemplateRef<any>;
  @ViewChild('IdT') public IdT: TemplateRef<any>;
  @ViewChild('verbindungsnameT') public verbindungsnameT: TemplateRef<any>;
  @ViewChild('textContentT') public textContentT: TemplateRef<any>;

  @ViewChild('bundcontentmodal') metaModal;
  @ViewChild('textdetailpopup') textdetailpopup;

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  totalModalDatasets = 0;
  dtOptions: DataTables.Settings = {};

  switchStatus1 = true;
  switchStatusBundEmpty = true;

  textdetailpopupText: string;

  env = environment;
  loadingIndicator = true;
  browseComponent = new FormControl('');
  bundContentListe = new Array<BundContent>();

  relevantBundListe = new Array<BundWrapper>();

  selBundContent: BundContent;

  page = new Page();
  columns = [];

  editing = {};

  rows = [];

  submitted = false;
  suchwort = new FormControl('');

  farben = new FormControl('');
  ort = new FormControl('');
  sccid = new FormControl('');

  public config: any;

  private styleTag: HTMLStyleElement;


  onSubmit() {
    this.submitted = true;
  }

  constructor(private sccService: SccService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.page.pageNumber = 0;
    this.page.size = 20;
    // this.styleTag = this.buildStyleElement();
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('page') !== null) {
        this.page.pageNumber = Number(queryParams.get('page'));
      } else {
        this.page.pageNumber = 0;
      }
    });
    // this.activatedRoute.paramMap.subscribe();
    // this.activatedRoute.queryParamMap.subscribe(
    //   paramsWeb => {
    //     if (paramsWeb.get('bundsearch') != null ||
    //       paramsWeb.get('ort') != null ||
    //       paramsWeb.get('farben') != null ||
    //       paramsWeb.get('sccid') != null
    //     ) {
    //       // this.findBundByFilter(paramsWeb.get('bundsearch'), paramsWeb.get('farben'), paramsWeb.get('ort'),
    //       //   paramsWeb.get('sccid'), {offset: 0});
    //     }
    //   }
    // );
    // this.getList({offset: 0});

    const that = this;
    this.dtOptions = {
      language: {
        url: '/assets/i18n/datatable_de.json'
      },
      pagingType: 'full_numbers',
      lengthMenu: [20],
      lengthChange: false,
      pageLength: this.page.size,
      displayStart: (this.page.pageNumber * this.page.size),
      serverSide: true,
      processing: true,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        that.getList({
          page: (dataTablesParameters.start / dataTablesParameters.length),
          size: dataTablesParameters.length
        }).subscribe((data) => {
          this.router.navigate([],
            {
              relativeTo: this.activatedRoute,
              queryParams: {page: (dataTablesParameters.start / dataTablesParameters.length)},
              queryParamsHandling: 'merge'
            });
          callback({
            recordsTotal: data.totalElements,
            recordsFiltered: data.totalElements,
            data: []
          });

          this.bundContentListe = [...data.content];
          this.page.pageNumber = data.pageable.pageNumber;
          this.page.size = data.pageable.pageSize;
          this.page.totalElements = data.totalElements;
          this.page.totalPages = data.totalPages;

        });
      },
      columns: [{data: 'id'}, {data: 'bundName'}, {data: 'sccid'}, {data: 'pages'}, {data: 'sourceFileName'}],
      columnDefs: [
        {targets: [0, 1, 2, 3, 4], width: '80px'}
      ]
    };


  }

  getRenderedColors(colorInput: string): string {
    return this.sccService.getRenderedColors(colorInput);
  }

  search() {
    //   this.router.navigate(['/search-page'], {
    //     queryParams: {
    //       bundsearch: this.suchwort.value,
    //       farben: this.farben.value,
    //       ort: this.ort.value,
    //       sccid: this.sccid.value
    //     }
    //   });
    //
    //   this.findBundByFilter(this.suchwort.value, this.farben.value, this.ort.value, this.sccid.value, {offset: 0});
  }

  //
  // findBundByFilter(suchbegriff: string, farben: string, ort: string, sccid: string, pageNo): void {
  //   this.sccService.getBuendeWithNameFilter(suchbegriff, farben, ort, sccid, pageNo).subscribe(data => {
  //     this.page.pageNumber = data.pageable.pageNumber;
  //     this.page.size = data.size;
  //     this.page.totalElements = data.totalElements;
  //     this.page.totalPages = data.totalPages;
  //   });
  //   console.log('foo ' + suchbegriff);
  // }


  getList(pageNo): Observable<any> {
    return this.sccService.getBundContentList(true, pageNo, this.switchStatus1, this.switchStatusBundEmpty);
  }

  getSelectedBundList(sccid: string): Array<Bund> {
    this.sccService.getBundBySCCId(sccid).subscribe((data) => {
    });
    return null;
  }

  updateBundContentLink(linkedBund: Bund): void {
    this.selBundContent.bund = linkedBund;
    const req = this.sccService.updateBundContent(this.selBundContent, linkedBund);
    req.subscribe((res => {
      // this.page.pageNumber = 0;
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
        this.metaModal.hide();
      });
    }));
  }

  setMetaDataBundEmpty(): void {
    this.selBundContent.bundEmpty = true;
    this.selBundContent.hasNoMatch = false;
    const req = this.sccService.updateBundContent(this.selBundContent);
    req.subscribe((res => {
      // this.page.pageNumber = 0;
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
        this.metaModal.hide();
      });

    }));
  }

  setMetaDataHasNoMatch(): void {
    this.selBundContent.bundEmpty = false;
    this.selBundContent.hasNoMatch = true;
    const req = this.sccService.updateBundContent(this.selBundContent);
    req.subscribe((res => {
      // this.page.pageNumber = 0;
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
        this.metaModal.hide();
      });

    }));
  }



  checkBundAttachedToMetaDataBund(bundid: number): number {
    this.sccService.getMetaDataBundCount(bundid).subscribe((data) => {
      return data;
    });
    return null;
  }

  openModal(inpBundContent: BundContent): void {
    this.selBundContent = inpBundContent;
    this.metaModal.show();
    this.sccService.getBundBySCCId(this.sccService.sanitizedSccId(inpBundContent.sccId.trim()), "verbindungsname")
      .subscribe((data) => {
        this.relevantBundListe = new Array<BundWrapper>();
        if (data !== null && data.totalElements !== null) {
          this.totalModalDatasets = data.totalElements;
        }
        for (const bundAktuell of data.content) {
          this.sccService.getBundcontentListeForBund(bundAktuell.id).subscribe(data2 => {
            const bw = new BundWrapper();
            bw.bund = bundAktuell;

            if (Number(data2) > 0) {
              bw.count = Number(data2);
            } else {
              bw.count = null;
            }
            this.relevantBundListe.push(bw);
          });
        }
      });
  }

  openTextContentModal(inputData: String): void {
    // document.body.appendChild(this.styleTag);
    this.textdetailpopupText = inputData.trim();
    this.textdetailpopup.show();

  }

  closeTextContentModal(): void {

    // document.body.removeChild(this.styleTag);
    this.textdetailpopup.hide();
  }

  updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    console.log('UPDATED!', this.rows[rowIndex][cell]);
  }

  public isEmpty(myVar): boolean {
    return (myVar && (Object.keys(myVar).length === 0));
  }

  swapSwitch1() {
    this.switchStatus1 = !this.switchStatus1;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(null, false);
    });
  }

  swapSwitch2() {
    this.switchStatusBundEmpty = !this.switchStatusBundEmpty;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(null, false);
    });
  }

  updateBundContentIsVerband() {
    const req = this.sccService.updateBundContentVerband(this.selBundContent, true);
    req.subscribe((res => {
      // this.page.pageNumber = 0;
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
        this.metaModal.hide();
      });

    }));
  }

  sanitizedSccId(sccId: string) {
    return this.sccService.sanitizedSccId(sccId);
  }
}
