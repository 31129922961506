import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from "../../sccshared/authentication/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class SccAuthGuard implements CanActivate {

  constructor(private authService: AuthenticationService,  private router: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('huhu guard');
    if (this.authService.isUserLoggedIn()) {
      // needs some more jwt fix, as it only checks for the storagesession existence
      // console.log('pass Guard');
      return true;
    } else {
      // console.log('fail Guard');
       return this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      // return false;
    }

    // return true;
  }

}
