<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card [title]="'Applikationskonfiguration'">
      </app-card>
      <app-card [title]="'Benutzerliste'">
        <ul *ngFor="let actualUser of allUsers">
          <li>{{actualUser.realname}} - {{actualUser.username}} -  {{actualUser.email}} <app-userroleviewer [userId]="actualUser.id"></app-userroleviewer></li>
        </ul>
      </app-card>
      <app-card [title]="'Benutzeranlage'">
        <div class="row">
          <div class="col-md-12 col-xl-6">
            <form [formGroup]="newUserForm"
                  (ngSubmit)="onSave(email.value, password.value, username.value, realname.value, bund.value)">
              <input type="text" name="email" class="form-control" placeholder="E-Mail-Adresse"
                     formControlName="email" #email>
              <div *ngIf="ctrl.email.touched && ctrl.email.hasError('required')"
                   class="text-danger">Required
              </div>
              <br/>
              <input type="password" name="password" class="form-control" placeholder="Passwort"
                     formControlName="password" #password>
              <div *ngIf="ctrl.password.touched && ctrl.password.hasError('required')"
                   class="text-danger">Required
              </div>
              <br/>
              <input type="text" name="username" class="form-control" placeholder="Benutzername"
                     formControlName="username" #username>
              <div *ngIf="ctrl.username.touched && ctrl.username.hasError('required')"
                   class="text-danger">Required
              </div>
              <br/>
              <input type="text" name="realname" class="form-control" placeholder="Ihr Name"
                     formControlName="realname" #realname>
              <br/>
              <input type="text" name="bund" class="form-control" placeholder="Wo aktiv?"
                     formControlName="bund" #bund>
              <br/>
              <div class="form-group">
                <div class="checkbox checkbox-primary d-inline" *ngFor="let oneRole of this.allRoles; let i=index">
                  <input type="checkbox" id="checkbox-p-{{i}}" name="checkbox-p-{{i}}" [value]="oneRole.value.name" (change)="onCheckboxChange($event)" />
                  <label for="checkbox-p-{{i}}" class="cr">{{oneRole.role.name}}</label>
                </div>
              </div>
              <br/>
              <button type="submit" class="btn btn-success float-right"
                      [ngClass]="newUserForm.invalid?'disabled':''"
                      [disabled]="newUserForm.invalid">Benutzer anlegen
              </button>
            </form>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>
