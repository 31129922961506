<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card [cardTitle]="'Suchfilter'">
        <form (submit)="search()" method="post" [formGroup]="searchForm">
          <div class="row searcrh-header">
            <div class="col-lg-12 col-sm-12 col-xs-12">
              <div class="input-group input-group-button input-group-primary">
                <div class="col-sm-2 m-b-5">
                  <input type="text" class="form-control" placeholder="Verbandsname"
                         formControlName="verbandname" id="verbandname" name="verbandname">
                </div>
                <div class="col-sm-2 m-b-5">
                  <input type="text" class="form-control" placeholder="Abkürzung"
                         formControlName="verbandsabk" id="verbandsabk" name="verbandsabk">
                </div>
                <div class="col-sm-2 m-b-5">
                  <input type="text" class="form-control" placeholder="Aktiv"
                         formControlName="verbandaktiv" id="verbandaktiv" name="verbandaktiv">
                </div>
                <div class="col-sm-4">

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-9 col-sm-9">
              <button class="btn btn-primary input-group-addon" id="basic-addon1">Suchen</button>
            </div>
          </div>
        </form>
      </app-card>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <app-card [cardTitle]="'Suchergebnis'">
        <div class="row">
          <div class="col-lg-12 col-sm-12">In der Liste sind "Vorgänger"verbände und aktive Verbände verzeichnet. Über die Filter können diese ausgeblendet werden.</div>
          <div class="col-lg-12 col-sm-12 font-italic">Aktuell sind die "Stammblätter" zum Verband noch in Arbeit (04/2022)</div>
        </div>
        <table datatable class="table-xl table-striped table-bordered table-hover"
               [dtOptions]="dtOptions">
          <thead>
          <tr>
            <th>DatenbankID</th>
            <th>Verbandsname</th>
            <th>Verbandsabk.</th>
            <th>noch "aktiv"?</th>
            <th>Gründungsjahr</th>
            <th>fusioniert zu</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let curVerband of verbandliste">
            <tr>
              <td>{{curVerband.id}}</td>
              <td><a [routerLink]="['/search-page']"
                     [queryParams]="{sccid: trimmed(curVerband.lfdeNr)}">{{curVerband.verbandsname}}</a></td>
              <td>{{curVerband.verbandsabkuerzung}}</td>
              <td>{{curVerband.verbandAktiv?"Ja":"Nein"}}</td>
              <td>{{curVerband.gruendungsjahrVerband}}</td>
              <td>{{curVerband.fusioniertZu}}</td>
              <td>
                <button type="button" class="btn btn-info" ngbTooltip="btn-warning"
                        (click)="showHistorical(curVerband.lfdeNr)">Historische
                </button>
                <button type="button" class="btn btn-success" ngbTooltip="btn-warning"
                        (click)="showBuendeForGroup(curVerband.id)">Verbandsbünde
                </button>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </app-card>
    </div>
  </div>
</div>
