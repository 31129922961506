import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SccService} from '../../../../scc.service';
import {Bund, EBayAuction} from '../../../../model/implementation';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-auctiondetails',
  templateUrl: './auctiondetails.component.html',
  styleUrls: ['./auctiondetails.component.scss']
})
export class AuctiondetailsComponent implements OnInit {
  auctionsearchForm: FormGroup;

  @Input() bundId: string;
  auctionList = new Array<EBayAuction>();
  auctionPending: boolean;

  constructor(private sccService: SccService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.auctionsearchForm = new FormGroup({
      auctionid: new FormControl('')
    });
    this.getListOfAuctionsForBund();

  }

  getAuction() {
    // console.log('accessing server... with auction {} and bund {}', this.auctionsearchForm.get('auctionid').value, this.bundId );
    this.auctionPending = true;
    this.sccService.invokeEbayAuctionGrabbing(this.auctionsearchForm.get('auctionid').value, this.bundId).subscribe((data) => {
      // console.log('server responsded: {}', data);
      this.toastr.info('Auktion hinzugefügt. Bilder in Bilderseite', 'Auktion');
      this.auctionPending = false;
      this.getListOfAuctionsForBund();
    });
  }

  getListOfAuctionsForBund() {
    this.sccService.checkAuctionsForBund(this.bundId).subscribe( data => {
      this.auctionList = data;
    });

  }
}
