<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card [title]="'Userprofile'">
        <div class="row">
          <div class="col-md-12 col-xl-6">
            <form [formGroup]="form" (ngSubmit)="save()" #formDir="ngForm" novalidate>
              <div class="form-group row">
                <label class=" control-label col-md-12">Name</label>
                <div class="col-md-12">
                  <input class="form-control" type="text" formControlName="realname">
                </div>
              </div>
              <div class="form-group row">
                <label class=" control-label col-md-12">Bund/Bünde</label>
                <div class="col-md-12">
                  <input class="form-control" type="text" formControlName="bund">
                </div>
              </div>
              <div class="form-group row">
                <label class=" control-label col-md-12">E-Mail</label>
                <div class="col-md-12">
                  <input class="form-control" type="text" formControlName="email">
                </div>
                <span class="text-danger ml-3" *ngIf="email.invalid && formDir.submitted">
                  E-Mail is required.
                </span>
              </div>
              <div class="form-group row">
                <label class=" control-label col-md-12">Neues Passwort</label>
                <div class="col-md-12">
                  <input class="form-control" type="password" formControlName="password">
                </div>
                <span class="text-danger ml-3" *ngIf="password.invalid && formDir.submitted">
                </span>
              </div>
              <div class="form-group row">
                <div class="col-md-9">
                  <file-upload simple [accept]="'image/*'" formControlName="profile">
                    <ng-template let-formProfileImage #placeholder>
                      <ng-container *ngIf="formProfileImage; else emptyList">
                        {{ formProfileImage.name }}
                      </ng-container>

                      <ng-template #emptyList>
                        Datei auswählen...
                      </ng-template>
                    </ng-template>

                    <ng-template let-control="control" #button>
                      Auswahl
                    </ng-template>
                  </file-upload>
                </div>
                <div class="col-md-3">
                  <img *ngIf="userProfileImageSet" [src]="getUserProfileURI()" class="thumbnail-img"/>
                  <span *ngIf="!userProfileImageSet"><img src="assets/images/noprofile.svg"
                                                          class="thumbnail-img">

                  </span>
                  <a style="vertical-align: top" *ngIf="userProfileImageSet" (click)="deleteUserPic()"><i  class="feather icon-trash-2"></i></a>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-success float-right">Save</button>
                  <!--                <button class="btn btn-secondary float-left" (click)="cancel()">Cancel</button>-->
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- order-card start -->
      </app-card>
    </div>
  </div>
  <div class="row">


  </div>
  <ng-template #loading>
    Loading...
  </ng-template>
</div>
