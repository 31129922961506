import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {AdminComponent} from './layout/admin/admin.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {MenuItems} from './shared/menu-items/menu-items';
import {BreadcrumbsComponent} from './layout/admin/breadcrumbs/breadcrumbs.component';

import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchPageModule} from './theme/search-page/search-page.module';
import {BundDetailModule} from './theme/bunddetail/bunddetail.module';


import {SccHttpInterceptorService} from './scc-http-interceptor.service';
import {LoginModule} from './theme/login/login.module';
import {OrphanBundmetadataModule} from './theme/orphan-bundmetadata/orphan-bundmetadata.module';
import {OrphanWappenModule} from './theme/orphan-wappen/orphan-wappen.module';
import {OrphanBundcontentModule} from './theme/orphan-bundcontent/orphan-bundcontent.module';

import {UserprofileModule} from './theme/userprofile/userprofile.module';
import {PasswordresetModule} from './theme/passwordreset/passwordreset.module';
import {AppadminModule} from './theme/appadmin/appadmin.module';
import {StartseiteModule} from './theme/startseite/startseite.module';
import {ZirkellisteModule} from './theme/zirkelliste/zirkelliste.module';

import { APP_BASE_HREF, PlatformLocation } from '@angular/common';

import {
  NgbPaginationModule,
  NgbAlertModule,
  NgbDropdownModule,
  NgbTooltipModule, NgbButtonsModule, NgbTabsetModule, NgbNavModule
} from '@ng-bootstrap/ng-bootstrap';
import {NavigationItem} from './layout/admin/navigation/navigation';
import {NavigationComponent} from './layout/admin/navigation/navigation.component';
import {NavBarComponent} from './layout/admin/nav-bar/nav-bar.component';
import {NavLeftComponent} from './layout/admin/nav-bar/nav-left/nav-left.component.js';
import {NavRightComponent} from './layout/admin/nav-bar/nav-right/nav-right.component';
import {NavGroupComponent} from './layout/admin/navigation/nav-content/nav-group/nav-group.component';
import {NavContentComponent} from './layout/admin/navigation/nav-content/nav-content.component';
import {NavCollapseComponent} from './layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import {NavItemComponent} from './layout/admin/navigation/nav-content/nav-item/nav-item.component';
import {ToggleFullScreenDirective} from './shared/full-screen/toggle-full-screen';
import {TitleComponent} from './layout/admin/title/title.component';

import { UiSwitchModule } from 'ngx-ui-switch';
import {NavSearchComponent} from './layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import {DocsOverviewModule} from './docs/overview/docs-overview.module';
import {RequestpasswordresetModule} from './theme/requestpasswordreset/requestpasswordreset.module';
import {AlertComponent} from './shared/components/sccnotifications/alert.component';
import {ToastrModule} from 'ngx-toastr';
import {SinglezirkellistModule} from './theme/singlezirkellist/singlezirkellist.module';
import {WappenlisteModule} from './theme/wappenliste/wappenliste.module';
import { ProfilepicComponent } from './theme/profilepic/profilepic.component';
import {VerbandslisteModule} from "./theme/verbandsliste/verbandsliste.module";


@NgModule({
    declarations: [
        AppComponent,
        AdminComponent,
        BreadcrumbsComponent,
        NavigationComponent,
        NavContentComponent,
        NavGroupComponent,
        NavCollapseComponent,
        NavItemComponent,
        TitleComponent,
        NavBarComponent,
        NavLeftComponent,
        NavSearchComponent,
        NavRightComponent,
        // ChatUserListComponent,
        // FriendComponent,
        // ChatMsgComponent,
        // ConfigurationComponent,
        ToggleFullScreenDirective,
        AlertComponent

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        DocsOverviewModule,
        SharedModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        SearchPageModule,
        BundDetailModule,
        LoginModule,
        OrphanBundmetadataModule,
        OrphanWappenModule,
        OrphanBundcontentModule,
        UserprofileModule,
        StartseiteModule,
        PasswordresetModule,
        AppadminModule,
        NgbPaginationModule,
        NgbAlertModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbButtonsModule,
        NgbNavModule,
        UiSwitchModule,
        ZirkellisteModule,
        SinglezirkellistModule,
        WappenlisteModule,
      VerbandslisteModule,
        RequestpasswordresetModule,
        ToastrModule.forRoot(
            {
                timeOut: 8000,
                positionClass: 'toast-top-right',
                preventDuplicates: true,
                autoDismiss: true
            }
        )

    ],
    providers: [MenuItems,
        {provide: HTTP_INTERCEPTORS, useClass: SccHttpInterceptorService, multi: true}, NavigationItem,
        {
            provide: APP_BASE_HREF,
            useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
            deps: [PlatformLocation]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
