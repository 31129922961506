import {Bund} from './implementation';

export interface IBundWrapper {
  bund?: Bund;
  // metaEntry?: MetaDataBund
  count?: number;
}

export class BundWrapper implements IBundWrapper {
  constructor(
    public bund?: Bund,
    public count?: number
    // public metaEntry?: MetaDataBund
  ) {

  }
}
