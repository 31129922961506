import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OrphanBundcontentComponent} from './orphan-bundcontent.component';

const routes: Routes = [
  {
    path: '',
    component: OrphanBundcontentComponent,
    data: {
      title: 'SCC-Online Verwaiste Einträge BundContent',
      icon: 'icon-layout-sidebar-left',
      caption: 'Änderungs-Admin SCC',
      status: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrphanBundcontentRoutingModule { }
