import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPageComponent } from './search-page.component';
import {SearchPageRoutingModule} from './search-page-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataTablesModule} from 'angular-datatables';

@NgModule({
  imports: [
    CommonModule,
    SearchPageRoutingModule,
    SharedModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule
  ],
  declarations: [SearchPageComponent]
})
export class SearchPageModule { }
