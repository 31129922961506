import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SccService} from '../../scc.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Page} from '../../model/page';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Bund, BundContent, Wappen, Zirkel} from '../../model/implementation';
import {DataTableDirective} from 'angular-datatables';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-wappenliste',
  templateUrl: './wappenliste.component.html',
  styleUrls: ['./wappenliste.component.scss']
})
export class WappenlisteComponent implements OnInit {

  env = environment;

  page = new Page();

  columns = [];

  submitted = false;
  model = String();
  suchwort = new FormControl('');
  searchForm = new FormGroup({
    farben: new FormControl(''),
    sccid: new FormControl(''),
    searchbundname: new FormControl(''),
    obenLinks: new FormControl(''),
    obenRechts: new FormControl(''),
    untenLinks: new FormControl(''),
    untenRechts: new FormControl(''),
    herzschild: new FormControl(''),
    helmzier: new FormControl('')
  });

  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  wappenliste = new Array<Wappen>();
  private searchPrefix: string;

  private iterZirkel: string;

  onSubmit() {
    this.submitted = true;
  }

  get diagnostic() {
    return JSON.stringify(this.model);
  }

  constructor(private sccService: SccService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.page.pageNumber = 0;
    this.page.size = 20;
  }

  ngOnInit() {
    // this.activatedRoute.paramMap.subscribe();
    this.activatedRoute.queryParamMap.subscribe(
      paramsWeb => {
        this.searchPrefix = paramsWeb.get('zirkelPrefix');
        this.searchForm.patchValue({
          sccid: paramsWeb.get('sccid'),
          searchbundname: paramsWeb.get('searchbundname'),
          farben: paramsWeb.get('farben'),
          obenLinks: paramsWeb.get('linksoben'),
          untenLinks: paramsWeb.get('linksunten'),
          untenRechts: paramsWeb.get('rechtsunten'),
          obenRechts: paramsWeb.get('rechtsoben'),
          helmzier: paramsWeb.get('helmzier'),
          herzschild: paramsWeb.get('herzschild')
        });
        // this.findBundByFilter(paramsWeb.get('bundsearch'), paramsWeb.get('farben'), paramsWeb.get('ort'),
        //   paramsWeb.get('sccid'), paramsWeb.get('gdjahr'), {offset: 0});

        if (paramsWeb.get('page') !== null) {
          this.page.pageNumber = Number(paramsWeb.get('page'));
        } else {
          this.page.pageNumber = 0;
        }
      }
    );

    const that = this;
    this.dtOptions = {
      language: {
        url: '/assets/i18n/datatable_de.json'
      },
      pagingType: 'full_numbers',
      lengthMenu: [20],
      lengthChange: false,
      pageLength: this.page.size,
      displayStart: (this.page.pageNumber * this.page.size),
      serverSide: true,
      processing: true,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        that.sccService.getWappenList(false, false, {
          page: (dataTablesParameters.start / dataTablesParameters.length),
          size: dataTablesParameters.length
        }, this.getFormValue('sccid'),
          this.getFormValue('farben'),
          this.getFormValue('searchbundname'),
          this.getFormValue('obenLinks'),
          this.getFormValue('obenRechts'),
          this.getFormValue('untenLinks'),
          this.getFormValue('untenRechts'),
          this.getFormValue('helmzier'),
          this.getFormValue('herzschild')).subscribe((data) => {
          this.router.navigate([],
            {
              relativeTo: this.activatedRoute,
              queryParams: {page: (dataTablesParameters.start / dataTablesParameters.length)},
              queryParamsHandling: 'merge'
            });
          callback({
            recordsTotal: data.totalElements,
            recordsFiltered: data.totalElements,
            data: []
          });

          this.wappenliste = [...data.content];
          this.page.pageNumber = data.pageable.pageNumber;
          this.page.size = data.pageable.pageSize;
          this.page.totalElements = data.totalElements;
          this.page.totalPages = data.totalPages;

        });
      },
      columns: [{data: 'id'}, {data: 'bundName'}, {data: 'sccid'}, {data: 'pages'}, {data: 'sourceFileName'}],
      columnDefs: [
        {targets: [0, 1, 2, 3, 4], width: '80px'}
      ]
    };

    // this.sccService.getAllZirkelTypes().subscribe(
    //   data => {
    //     console.log('tataaaa');
    //     console.log(data);
    //   }
    // );
  }

  getRenderedColors(colorInput: string): string {
    return this.sccService.getRenderedColors(colorInput);
  }

  search() {
    this.router.navigate(['/wappenliste'], {
      queryParams: {
        bundsearch: this.suchwort.value,
        farben: this.getFormValue('farben'),
        sccid: this.getFormValue('sccid'),
        searchbundname: this.getFormValue('searchbundname'),
        linksoben: this.getFormValue('obenLinks'),
        linksunten: this.getFormValue('untenLinks'),
        rechtsoben: this.getFormValue('obenRechts'),
        rechtsunten: this.getFormValue('untenRechts'),
        helmzier: this.getFormValue('helmzier'),
        herzschild: this.getFormValue('herzschild'),
      }
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });

  }
  getFormValue(field: string): string {
    let myVal;
    if (this.searchForm.get(field) != null) {
      myVal = this.searchForm.get(field).value;
    }
    if (myVal != null) {
      return myVal;
    } else {
      return '';
    }
  }

  filterListe(buchstabe: any) {
    this.router.navigate(['/wappenliste'], {
      queryParams: {
        zirkelPrefix: buchstabe
      }
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  getBinaryDataForZirkel(contentId: string) {
    return this.sccService.getAbsoluteImageURI(contentId);
  }

  trimmed(sccIdInput: string): string {
    sccIdInput = sccIdInput.replace('-', '');
    sccIdInput = sccIdInput.trim();
    return sccIdInput;
  }
}
