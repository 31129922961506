import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrphanWappenComponent } from './orphan-wappen.component';
import {OrphanWappenRoutingModule} from './orphan-wappen-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataTablesModule} from "angular-datatables";

@NgModule({
  imports: [
    CommonModule,
    OrphanWappenRoutingModule,
    SharedModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule
  ],
  declarations: [OrphanWappenComponent]
})
export class OrphanWappenModule { }
