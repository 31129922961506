import {NgModule} from '@angular/core';
import {LoginComponent} from "./login.component";
import {LoginRoutingModule} from "./login-routing.module";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LogoutComponent} from "./logout.component";

@NgModule({
  declarations: [LoginComponent, LogoutComponent],
  imports: [LoginRoutingModule,
    CommonModule,
    SharedModule,
  FormsModule, ReactiveFormsModule]
})
export class LoginModule {

}
