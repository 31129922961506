<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-md-6 col-xl-3">
          <div class="card bg-c-blue order-card">
            <div class="card-block">
              <h6 class="m-b-20 cursor-pointer" (click)="jumpToBundContent()">Content Data ohne
                Bundzuordnung</h6>
              <h2 class="text-right"><i
                class="ti-cloud-up f-left"></i><span>{{this.getBundContentOrphans()}}</span></h2>
              <p class="m-b-0">Gesetzt<span class="f-right">____</span></p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card bg-c-pink order-card">
            <div class="card-block">
              <h6 class="m-b-20 cursor-pointer" (click)="jumpToMetaDataBund()">MetaDataBund ohne
                Bundzuordnung</h6>
              <h2 class="text-right"><i
                class="ti-gallery f-left"></i><span>{{this.getMetaDataBundOrphans()}}</span></h2>
              <p class="m-b-0">Gesetzt<span class="f-right">_____</span></p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card bg-c-yellow order-card">
            <div class="card-block">
              <h6 class="m-b-20 cursor-pointer" (click)="jumpToWappen()">Wappen</h6>
              <h2 class="text-right"><i
                class="ti-reload f-left"></i><span>{{this.getWappenOrphans()}}</span></h2>
              <p class="m-b-0">Gesetzt<span class="f-right">_____</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
        <app-card cardTitle="Unsere Neuigkeiten" [options]="true">
          <ul class="feed-blog" *ngFor="let singleNews of allNews">
            <li class="active-feed">
              <div class="feed-user-img">
                <img src="assets/images/noprofile.svg" class="img-radius " alt="User-Profile-Image">
              </div>
              <h6><span class="badge m-r-5 badge-pill"
                        [ngClass]="getPillClass(singleNews.newsTypeEnum)">{{singleNews.newsTypeEnum}}</span> {{singleNews.title}}
                <small class="text-muted"> {{singleNews.publicationDate | date:('dd.MM.yyyy')}}</small></h6>
              <p class="m-b-15 m-t-15">{{singleNews.content}}</p>
              <!--                    <div class="row">-->
              <!--                      <div class="col-auto text-center">-->
              <!--                        <img src="assets/images/blog/blog-r-1.jpg" alt="img" class="img-fluid img-100">-->
              <!--                        <h6 class="m-t-15 m-b-0">Old Scooter</h6>-->
              <!--                        <p class="text-muted m-b-0"><small>PNG-100KB</small></p>-->
              <!--                      </div>-->
              <!--                      <div class="col-auto text-center">-->
              <!--                        <img src="assets/images/blog/blog-r-2.jpg" alt="img" class="img-fluid img-100">-->
              <!--                        <h6 class="m-t-15 m-b-0">Wall Art</h6>-->
              <!--                        <p class="text-muted m-b-0"><small>PNG-150KB</small></p>-->
              <!--                      </div>-->
              <!--                      <div class="col-auto text-center">-->
              <!--                        <img src="assets/images/blog/blog-r-3.jpg" alt="img" class="img-fluid img-100">-->
              <!--                        <h6 class="m-t-15 m-b-0">Microphone</h6>-->
              <!--                        <p class="text-muted m-b-0"><small>PNG-150KB</small></p>-->
              <!--                      </div>-->
              <!--                    </div>-->
            </li>
          </ul>
        </app-card>
        </div>
        <div class="col-sm">
          <app-card cardTitle="Letzte Kommentare" [options]="false">
            <ul>
            <li *ngFor="let bundComm of lastComments">{{bundComm.creationDate | date:'dd.MM.yyyy'}} <a [routerLink]="['/bunddetail', bundComm.bund.id]">{{bundComm.bund.verbindungsname}}</a>: {{bundComm.commentText}} ({{bundComm.author.realname !== null ? bundComm.author.realname : bundComm.author.username}})</li>
            </ul>
          </app-card>
          <app-card cardTitle="Letzte Änderungen" [options]="false">
            <ul>
              <li *ngFor="let bundEdit of lastEdits">{{bundEdit.creationDate | date:'dd.MM.yyyy'}} <a [routerLink]="['/bunddetail', bundEdit.id]">{{bundEdit.verbindungsname}}</a>: ({{bundEdit.sccId}}, {{bundEdit.ort}}, Editor: {{bundEdit.lastModifiedBy}})</li>
            </ul>
          </app-card>
        </div>
      </div>
    </div>
  </div>

</div>
