import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {SccService} from '../../scc.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})
export class PasswordresetComponent implements OnInit {
  form: FormGroup;
  formNewPass: string;
  formNewPassValidate: string;

  // Dynamic parameters for this component's route: /example-params/:first/:second
  routeParams: Params;

  // Query parameters found in the URL: /example-params/one/two?query1=one&query2=two
  queryParams: Params;

  constructor(private route: ActivatedRoute, private router: Router, private sccService: SccService,
              private formBuilder: FormBuilder, private toastr: ToastrService) {
    this.formNewPass = 'newpass';
    this.formNewPassValidate = 'newpassvalidate';
    this.getRouteParams();
    this.form = this.formBuilder.group(
      {
        newpass: ['', [Validators.required]],
        newpassvalidate: ['', [Validators.required]]
      }
    );
  }

  ngOnInit() {
    console.log(this.queryParams);
  }

  save() {
    if (this.queryParams.token != null) {
      // prüfe, ob Password und Validation equal
      if (this.form.get(this.formNewPass).value === this.form.get(this.formNewPassValidate).value && this.queryParams.token) {
        // sende request an server
        const req = this.sccService.updatePassword(this.queryParams.token, this.form.get(this.formNewPass).value);
        req.subscribe(res => {
          console.log('result {}', res);
          this.toastr.success('Passwort geändert!', 'Bitte mit den neuen Daten anmelden.');
        });
        // Antwort auswerten
      } else {
        console.log('passwoerter ungleich', this.form.get(this.formNewPass).value);
      }

    } else {
      console.log('missing token');
    }
    console.log('speichern...');
  }

  getRouteParams() {

    // Route parameters
    this.route.params.subscribe(params => {
      this.routeParams = params;
    });

    // URL query parameters
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
    });
  }
}
