<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <app-card [title]="'Datenbanksuche'" cardClass="card-datatable" [options]="false">
        <p>Bitte entsprechende Filter setzen und dann über "Eingabe" suchen</p>
        <form (submit)="search()" method="post">
          <div class="row seacrh-header">
            <div class="col-sm-6">
              <span class="m-b-20">Benutze die Suche & Filter zur Ergebniseingrenzung</span><br>
            </div>
          </div>
          <div class="row searcrh-header">
            <div class="col-lg-12 col-sm-12 col-xs-12">
              <div class="input-group input-group-button input-group-primary">
                <div class="col-sm-3 m-b-5">
                  <input type="text" class="form-control" placeholder="Verbindungsname"
                         [formControl]="suchwort" id="verbindungsname" name="suchwort">
                </div>
                <div class="col-sm-3 m-b-5">
                  <input type="text" class="form-control" placeholder="Farben"
                         [formControl]="farben" id="farben" name="farben">
                </div>
                <div class="col-sm-3 m-b-5">
                  <input type="text" class="form-control" placeholder="Ort"
                         [formControl]="ort" id="ort" name="ort">
                </div>
                <div class="col-sm-3 m-b-5">
                  <input type="text" class="form-control" placeholder="Gründungsjahr"
                         [formControl]="gdjahr" id="gdjahr" name="gdjahr">
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-xs-12">
              <div class="input-group input-group-button input-group-primary">
                <div class="col-sm-3 m-b-5">
                  <input type="text" class="form-control" placeholder="SCC-ID im Format (00000)"
                         [formControl]="sccid" id="sccIdSuche" name="sccid">
                </div>
                <div class="col-sm-3 m-b-5">
                  <input type="text" class="form-control" placeholder="Wahlspruch (ggf. in Teilen)"
                         [formControl]="wahlspruch" id="wahlspruch" name="wahlspruch">
                </div>
                <div class="col-sm-3 m-b-5">

                    <input type="checkbox" [formControl]="nuraktiv" id="nuraktiv" name="nuraktiv">
                    nur aktive Bünde suchen
                </div>
                <div class="col-lg-9 col-sm-9">
                  <button class="btn btn-primary input-group-addon" id="basic-addon1">Suchen</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </app-card>
      <app-card [title]="'Suchergebnis'" cardClass="card-datatable" [options]="false">

        <table datatable class="table table-striped table-bordered nowrap table-hover" [dtOptions]="dtOptions">
          <thead>
          <tr>
            <th style="width: 80px;">DatenbankID</th>
            <th>Name d. Verbindung</th>
            <th>Ort</th>
            <th>SCC-ID</th>
            <th>Gründungsjahr</th>
            <th>Couleur/Farben</th>
            <th>Wahlspruch</th>
            <th>Aktionen <i *ngIf="userIsAdmin()" class="feather icon-plus-square" style="margin-left:10px;"></i></th>
          </tr>
          </thead>
          <tbody *ngIf="alleBuende.length !=0">
          <tr *ngFor="let bund of alleBuende">
            <td [ngStyle]="bund.aktiv?{'background-color': 'greenyellow'}:{'background-color': 'red'}">
              <a class="nav-link edit" [routerLink]="['/bunddetail', bund.id]">{{ bund.id }}</a></td>
            <td>
              <a class="nav-link edit" [routerLink]="['/bunddetail', bund.id]">
                <span>{{ bund.verbindungsname }}</span>
              </a>
            </td>
            <td>{{ bund.ort }}</td>
            <td>{{ bund.sccId }}</td>
            <td>{{ bund.gruendungsDatum }}</td>
            <td><span [innerHTML]="getRenderedColors(bund.farben)"></span></td>
            <td>{{ bund.wahlspruch }}</td>
            <td>
              <button [routerLink]="['/bunddetail', bund.id]" class="btn btn-info">Detailansicht</button>
            </td>
          </tr>
          </tbody>
        </table>
        <!--        <ngx-datatable-->
        <!--          class="data-table table-responsive"-->
        <!--          [loadingIndicator]="loadingIndicator"-->
        <!--          [headerHeight]="50"-->
        <!--          [footerHeight]="50"-->
        <!--          [rowHeight]="50"-->
        <!--          [rows]="alleBuende"-->
        <!--          [columns]="columns"-->
        <!--          [externalPaging]="true"-->
        <!--          [footerHeight]="100"-->
        <!--          [columnMode]="'force'"-->
        <!--          [count]="page.totalElements"-->
        <!--          [offset]="page.pageNumber"-->
        <!--          [limit]="page.size"-->
        <!--          [virtualization]="false"-->
        <!--          (page)='findBundByFilter(suchwort.value , farben.value, ort.value, sccid.value, gdjahr.value, wahlspruch.value, $event)'-->
        <!--          (activate)="onActivate($event)"-->
        <!--        >-->
        <!--          &lt;!&ndash;<ngx-datatable-column prop="sccId" name=""huhu></ngx-datatable-column>&ndash;&gt;-->
        <!--          &lt;!&ndash;<ngx-datatable-footer>&ndash;&gt;-->
        <!--          &lt;!&ndash;<datatable-pager&ndash;&gt;-->
        <!--          &lt;!&ndash;[pagerLeftArrowIcon]="'datatable-icon-left'"&ndash;&gt;-->
        <!--          &lt;!&ndash;[pagerRightArrowIcon]="'datatable-icon-right'"&ndash;&gt;-->
        <!--          &lt;!&ndash;[pagerPreviousIcon]="'datatable-icon-prev'"&ndash;&gt;-->
        <!--          &lt;!&ndash;[pagerNextIcon]="'datatable-icon-skip'"&ndash;&gt;-->
        <!--          &lt;!&ndash;[page]="curPage"&ndash;&gt;-->
        <!--          &lt;!&ndash;[size]="pageSize"&ndash;&gt;-->
        <!--          &lt;!&ndash;[count]="rowCount"&ndash;&gt;-->
        <!--          &lt;!&ndash;[hidden]="!((rowCount / pageSize) > 1)"&ndash;&gt;-->
        <!--          &lt;!&ndash;(change)="myTable.onFooterPage($event)">&ndash;&gt;-->
        <!--          &lt;!&ndash;</datatable-pager>&ndash;&gt;-->

        <!--          &lt;!&ndash;</ngx-datatable-footer>&ndash;&gt;-->

        <!--          &lt;!&ndash;          <ngx-datatable-column *ngFor="let col of columns" [name]="col.name">&ndash;&gt;-->
        <!--          &lt;!&ndash;          </ngx-datatable-column>&ndash;&gt;-->
        <!--          &lt;!&ndash;          <ngx-datatable-column name="Actions" sortable="false" prop="$key">&ndash;&gt;-->
        <!--          &lt;!&ndash;            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>&ndash;&gt;-->
        <!--          &lt;!&ndash;              <a ng-href="#" (click)="onDemo(row)">boom {{row.verbindungId}}</a>&ndash;&gt;-->
        <!--          &lt;!&ndash;              <button md-icon-button >&ndash;&gt;-->
        <!--          &lt;!&ndash;                <i class="fa fa-ban">Del / Update</i>&ndash;&gt;-->
        <!--          &lt;!&ndash;              </button>&ndash;&gt;-->
        <!--          &lt;!&ndash;            </ng-template>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </ngx-datatable-column>&ndash;&gt;-->
        <!--          &lt;!&ndash;          <ngx-datatable-column *ngFor="let col of columns" [name]="col.name">&ndash;&gt;-->
        <!--          &lt;!&ndash;          </ngx-datatable-column>&ndash;&gt;-->
        <!--          &lt;!&ndash;          <ng-template #farbenT let-row="row" let-value="value" let-i="index">&ndash;&gt;-->
        <!--          &lt;!&ndash;            <span [innerHTML]="getRenderedColors(value)"></span>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </ng-template>&ndash;&gt;-->
        <!--          <ngx-datatable-column name="DatenbankID" prop="id">-->
        <!--            <ng-template let-row="row" let-column="column" let-value="value" let-i="index"-->
        <!--                         ngx-datatable-cell-template>-->
        <!--              <span *ngIf="row.aktiv == true" style="background-color: greenyellow">{{value}}</span>-->
        <!--              <span *ngIf="row.aktiv == false" style="background-color: indianred">{{value}}</span>-->
        <!--            </ng-template>-->
        <!--          </ngx-datatable-column>-->
        <!--          <ngx-datatable-column name="Name d. Verbindung" prop="verbindungsname">-->
        <!--            <ng-template #verbindungsnameT let-row="row" let-value="value" let-i="index"-->
        <!--                         ngx-datatable-cell-template>-->
        <!--              <a class="nav-link edit" [routerLink]="['/bunddetail', row.id]">-->
        <!--                <span>{{value}}</span>-->
        <!--              </a>-->
        <!--            </ng-template>-->
        <!--          </ngx-datatable-column>-->
        <!--          <ngx-datatable-column cellClass="" name="Ort" prop="ort">-->
        <!--          </ngx-datatable-column>-->
        <!--          <ngx-datatable-column name="SCC-ID" prop="sccId">-->
        <!--          </ngx-datatable-column>-->
        <!--          <ngx-datatable-column name="Gründungsjahr" prop="gruendungsDatum">-->
        <!--          </ngx-datatable-column>-->
        <!--          <ngx-datatable-column #farbenT name="Couleur/Farben" prop="farben">-->
        <!--            <ng-template let-column="column" let-value="value" let-i="index"-->
        <!--                         ngx-datatable-cell-template>-->
        <!--              <span [innerHTML]="getRenderedColors(value)"></span>-->
        <!--            </ng-template>-->
        <!--          </ngx-datatable-column>-->
        <!--          <ngx-datatable-column name="Wahlspruch" prop="wahlspruch">-->
        <!--          </ngx-datatable-column>-->
        <!--          <ngx-datatable-column name="Aktionen" [width]="100" sortable="false" prop="$key">-->
        <!--            <ng-template let-id="value" let-row="row" let-value="value" ngx-datatable-cell-template>-->
        <!--&lt;!&ndash;              <i class="icofont icofont-edit"></i>&ndash;&gt;-->
        <!--&lt;!&ndash;              <i class="icofont icofont-garbage"></i>&ndash;&gt;-->
        <!--&lt;!&ndash;              <i class="icofont icofont-send-mail"></i>&ndash;&gt;-->
        <!--            </ng-template>-->
        <!--          </ngx-datatable-column>-->
        <!--        </ngx-datatable>-->


      </app-card>
    </div>
  </div>
</div>
