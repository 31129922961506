import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SccHttpInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private actRoute: ActivatedRoute) {
    // console.log(router);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // cleanup call parameters to remove "undefined" / null ones
    let params = req.params;
    for (const key of req.params.keys()) {
      if (params.get(key) === undefined) {
        params = params.delete(key, undefined);
      }
    }
    req = req.clone({ params });
    // console.log('+++ intercept +++ ', req);
    // Only add an access token to whitelisted origins
    const allowedOrigins = [environment.settings.backend];
    if (allowedOrigins.some(url => req.urlWithParams.includes(url))) {

      if (localStorage.getItem('username') && localStorage.getItem('token')) {
        req = req.clone({
          setHeaders: {
            Authorization: localStorage.getItem('token')
          }
        });
      } else {
        // console.log(this.actRoute);
        // missing sessioninformation - assuming not authenticated
        this.router.navigate(['/login']);

      }
    }
    return next.handle(req).pipe(tap(() => {
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          localStorage.removeItem('username');
          localStorage.removeItem('token');
          this.router.navigate(['login']);
        }
      }));
  }
}
