import {Component, OnDestroy, OnInit, Pipe, PipeTransform} from '@angular/core';
import {SccService} from '../../scc.service';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserDTO} from '../../model/userDTO.model';
import {FileUploadValidators} from '@iplab/ngx-file-upload';
import {AuthenticationService} from '../../sccshared/authentication/authentication.service';
import {ProfileinfoService} from '../../sccshared/profileinfo/profileinfo.service';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss']
})
export class UserprofileComponent implements OnInit {
  form: FormGroup;
  userData: UserDTO;
  formEmail: string;
  formPassword: string;
  formName: string;
  formBund: string;
  formProfileImage: File;
  userProfileImageSet: boolean;

  private fileControl = new FormControl(null, FileUploadValidators.filesLimit(1));

  constructor(private sccService: SccService, private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
              private router: Router, private profileInfoService: ProfileinfoService) {
    this.formEmail = 'email';
    this.formPassword = 'password';
    this.formName = 'realname';
    this.formBund = 'bund';

    this.form = this.formBuilder.group(
      {
        email: ['', [Validators.required]],
        password: ['', []],
        realname: ['', []],
        bund: ['', []],
        profile: [null, [FileUploadValidators.filesLimit(1)]]
      }
    );
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.sccService.getUserData().subscribe(data => {
      console.log('data: ', data);
      this.form.controls[this.formEmail].setValue(data.email);
      this.form.controls[this.formPassword].setValue(data.password);
      this.form.controls[this.formName].setValue(data.realname);
      this.form.controls[this.formBund].setValue(data.bund);
      this.hasUserProfileImage();
    });
  }

  save() {
    console.log('saved');
    let newUserData: UserDTO = {
      username: '',
      email: this.form.get(this.formEmail).value,
      password: this.form.get(this.formPassword).value,
      realname: this.form.get(this.formName).value,
      bund: this.form.get(this.formBund).value
    };
    this.sccService.updateUserData(newUserData).subscribe((data) => {
      if (this.form.get('profile').value !== null) {
        this.sccService.uploadUserProfileImage(this.form.get('profile').value).subscribe((resp) => {
          this.userProfileImageSet = true;
          this.profileInfoService.messageEmitter.emit('Profile picture updated');
        });
      }
      this.form.controls[this.formPassword].setValue('');
    });
  }

  get email() {
    return this.form.get(this.formEmail);
  }

  get password() {
    return this.form.get(this.formPassword);
  }

  get bund() {
    return this.form.get(this.formBund);
  }

  hasUserProfileImage() {
    this.userProfileImageSet = false;
    this.sccService.hasUserProfileImage(this.authenticationService.getLoggedInUserId()).subscribe((data) => {
      if (data !== false) {
        this.userProfileImageSet = true;
      }
    });

  }

  getUserProfileURI() {
    return this.sccService.getAbsoluteUserMediaURI(this.authenticationService.getLoggedInUserId().toString());
  }

  deleteUserPic() {
    this.sccService.deleteUserProfileImage().subscribe((data) => {
      this.userProfileImageSet = false;
      this.profileInfoService.messageEmitter.emit('Profile picture deleted');
      return true;
    });
  }
}
