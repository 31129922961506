import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {LoginComponent} from './theme/login/login.component';
import {SccAuthGuard} from "./shared/guard/scc-auth-guard.service";

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [SccAuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'startseite',
        pathMatch: 'full'
      },
      {
        path: 'startseite',
        loadChildren: './theme/startseite/startseite.module#StartseiteModule'
      },
      {
        path: 'search-page',
        loadChildren: './theme/search-page/search-page.module#SearchPageModule'
      },
      {
        path: 'bunddetail/:id',
        loadChildren: './theme/bunddetail/bunddetail.module#BundDetailModule'
      },
      {
        path: 'orphan-bundmetadata',
        loadChildren: './theme/orphan-bundmetadata/orphan-bundmetadata.module#OrphanBundmetadataModule'
      },
      {
        path: 'orphan-wappen',
        loadChildren: './theme/orphan-wappen/orphan-wappen.module#OrphanWappenModule'
      },
      {
        path: 'orphan-bundcontent',
        loadChildren: './theme/orphan-bundcontent/orphan-bundcontent.module#OrphanBundcontentModule'
      },
      {
        path: 'user/profile',
        loadChildren: './theme/userprofile/userprofile.module#UserprofileModule'
      },
      {
        path: 'admin',
        loadChildren: './theme/appadmin/appadmin.module#AppadminModule'
      },
      {
        path: 'zirkelliste',
        loadChildren: './theme/zirkelliste/zirkelliste.module#ZirkellisteModule'
      },
      {
        path: 'singlezirkellist/:id',
        loadChildren: './theme/singlezirkellist/singlezirkellist.module#SinglezirkellistModule'
      },
      {
        path: 'wappenliste',
        loadChildren: './theme/wappenliste/wappenliste.module#WappenlisteModule'
      },
      {
        path: 'verbandsliste',
        loadChildren: './theme/verbandsliste/verbandsliste.module#VerbandslisteModule'
      },
      {
        path: 'docs',
        loadChildren: './docs/overview/docs-overview.module#DocsOverviewModule'
      }

      // {
      //   path: 'login',
      //   component: LoginComponent
      // }

    ]
  },
  {
    path: '',
    component: LoginComponent,
    children: [
      {
        path: 'login',
        loadChildren: './theme/login/login.module#LoginModule'
      },

    ]
  },
  {
    path: 'passwordreset',
    loadChildren: './theme/passwordreset/passwordreset.module#PasswordresetModule'
  },
  {
    path: 'requestpasswordreset',
    loadChildren: './theme/requestpasswordreset/requestpasswordreset.module#RequestpasswordresetModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
