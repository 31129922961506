import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SccService} from '../../scc.service';
import {FormControl} from '@angular/forms';
import {Page} from '../../model/page';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {BundWrapper} from '../../model/bundwrapper.model';
import {Bund, Wappen} from '../../model/implementation';
import {WappenDTO} from '../../model/wappenDTO.model';
import {DataTableDirective} from 'angular-datatables';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-orphan-wappen-page',
  templateUrl: './orphan-wappen.component.html',
  styleUrls: ['./orphan-wappen.component.scss']
})
export class OrphanWappenComponent implements OnInit {
  @ViewChild('farbenT') public farbenT: TemplateRef<any>;
  @ViewChild('idT') public idT: TemplateRef<any>;
  @ViewChild('verbindungsnameT') public verbindungsnameT: TemplateRef<any>;

  @ViewChild('bundmetadatamodal') metaModal;

  // Datatable
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};


  env = environment;
  loadingIndicator = true;
  browseComponent = new FormControl('');
  wappenliste: Wappen[];

  relevantBundListe = new Array<BundWrapper>();

  selWappen: Wappen;

  page = new Page();

  columns = [];

  submitted = false;
  suchwort = new FormControl('');

  farben = new FormControl('');
  ort = new FormControl('');
  sccid = new FormControl('');
  totalModalDatasets = 0;
  public config: any;

  // toggleParameter
  filterBundEmpty  = false;


  onSubmit() {
    this.submitted = true;
  }

  constructor(private sccService: SccService, private router: Router, private activatedRoute: ActivatedRoute) {

    this.page.size = 20;
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      console.log('queryPArams: {}', queryParams);
      if (queryParams.get('page') !== null) {
        this.page.pageNumber = Number(queryParams.get('page'));
      } else {
        this.page.pageNumber = 0;
      }
    });
    // this.getList({offset: 0});

    // datatable
    const that = this;
    this.dtOptions = {
      language: {
        url: '/assets/i18n/datatable_de.json'
      },
      pagingType: 'full_numbers',
      lengthMenu: [20],
      lengthChange: false,
      pageLength: this.page.size,
      displayStart: (this.page.pageNumber * this.page.size),
      serverSide: true,
      processing: true,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        console.log('called ajax {}', dataTablesParameters.start);
        that.getList({
          page: (dataTablesParameters.start / dataTablesParameters.length),
          size: dataTablesParameters.length
        }).subscribe(data => {
          this.router.navigate([],
            {
              relativeTo: this.activatedRoute,
              queryParams: {page: (dataTablesParameters.start / dataTablesParameters.length)},
              queryParamsHandling: 'merge'
            });
          callback({
            recordsTotal: data.totalElements,
            recordsFiltered: data.totalElements,
            data: []
          });
          this.wappenliste = [...data.content];
        });
      },
      columns: [{data: 'id'}, {data: 'nameImport'}, {data: 'sccId'}, {data: 'farben'}, {data: 'linksOben'}],
      columnDefs: [
        {targets: [0, 1, 2, 3, 4], width: '80px'}
      ]
    };
  }


  getRenderedColors(colorInput: string): string {
    return this.sccService.getRenderedColors(colorInput);
  }

  search() {
    //   this.router.navigate(['/search-page'], {
    //     queryParams: {
    //       bundsearch: this.suchwort.value,
    //       farben: this.farben.value,
    //       ort: this.ort.value,
    //       sccid: this.sccid.value
    //     }
    //   });
    //
    //   this.findBundByFilter(this.suchwort.value, this.farben.value, this.ort.value, this.sccid.value, {offset: 0});
  }


  getList(pageNo): Observable<any> {
    console.log('getList(pageNo): {} filterbund: {}', pageNo, this.filterBundEmpty);
    return this.sccService.getWappenList(true, this.filterBundEmpty, pageNo);
  }

  getSelectedBundList(sccid: string): Array<Bund> {
    this.sccService.getBundBySCCId(sccid).subscribe((data) => {
    });
    return null;
  }

  updateWappenLink(linkedBund: Bund): void {
    this.selWappen.bund = linkedBund;
    console.log('selAWappen {} ', this.selWappen);
    this.selWappen.hasNoMatch = false;
    this.selWappen.bundEmpty = false;
    const req = this.sccService.updateWappen(this.selWappen, linkedBund);
    req.subscribe((res => {
      // this.page.pageNumber = 0;
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
        this.metaModal.hide();
      });
    }));
  }

  checkBundAttachedToMetaDataBund(bundid: number): number {
    this.sccService.getMetaDataBundCount(bundid).subscribe((data) => {
      console.log('data: {}', data);
      return data;
    });
    return null;
  }

  openModal(inpWappen: Wappen): void {
    this.selWappen = inpWappen;
    this.metaModal.show();
    this.sccService.getBundBySCCId(this.sccService.sanitizedSccId(inpWappen.sccId.trim()),  'verbindungsname').subscribe((data) => {
      this.relevantBundListe = new Array<BundWrapper>();
      if (data !== null && data.totalElements !== null) {
        this.totalModalDatasets = data.totalElements;
      }
      for (const bundAktuell of data.content) {
        this.sccService.getWappenlisteForBund(bundAktuell.id).subscribe(data2 => {
          const bw = new BundWrapper();
          bw.bund = bundAktuell;

          if (Number(data2) > 0) {
            bw.count = Number(data2);
          } else {
            bw.count = null;
          }
          this.relevantBundListe.push(bw);
        });
      }
    });
  }

  public isEmpty(myVar): boolean {
    return (myVar && (Object.keys(myVar).length === 0));
  }

  markNotFound(inpWappen: Wappen): void {
    const wap: WappenDTO = {
      isVerband: inpWappen.verband,
      bundListEmpty: inpWappen.bundEmpty,
      isNoMatch: true
    };

    this.sccService.setWappenBundNotFound(inpWappen, wap).subscribe((data) => {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
        this.metaModal.hide();
      });
    });
    return null;
  }

  markBundListEmpty(inpWappen: Wappen): void {
    const wap: WappenDTO = {
      isVerband: inpWappen.verband,
      bundListEmpty: true,
      isNoMatch: inpWappen.hasNoMatch
    };

    this.sccService.setWappenBundNotFound(inpWappen, wap).subscribe((data) => {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
        this.metaModal.hide();
      });
    });
    return null;
  }

  markVerband(inpWappen: Wappen): void {
    const wap: WappenDTO = {
      isNoMatch: inpWappen.hasNoMatch,
      bundListEmpty: inpWappen.bundEmpty,
      isVerband: true
    };

    this.sccService.setWappenIsVerband(inpWappen, wap).subscribe((data) => {
      console.log('updated wappen - success? {}', data);
      // this.getList(
      //   {
      //     page: this.page.pageNumber,
      //     size: this.page.size
      //   });
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(null, false);
      //   console.log('Page: {}', this.page);
      //   dtInstance.draw();
      });
    });
    return null;
  }

  toggleBundEmpty() {
    this.filterBundEmpty = !this.filterBundEmpty;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(null, false);
      // this.getList(
      //   {
      //     page: this.page.pageNumber,
      //     size: this.page.size
      //   }
      // );
    });

  }

  sanitizedSccId(sccId: string) {
    return this.sccService.sanitizedSccId(sccId);
  }
}
