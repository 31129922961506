import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppadminComponent} from './appadmin.component';
import {SharedModule} from '../../shared/shared.module';
import {AppadminRoutingModule} from './appadmin-routing.module';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [AppadminComponent],
    imports: [
        CommonModule,
        SharedModule,
        AppadminRoutingModule,
        ReactiveFormsModule
    ]
})
export class AppadminModule {
}
